// =====================================
// PC
// =====================================

@media screen and (min-width: 769px) {
  .menu-reserve-confirmation,
  .reserve-confirmation {
    .reserve-form-title {
      background-color: #ff5800;
      text-align: center;
      span {
        display: inline-block;
        font-size: 16px;
        margin: 0 auto;
        padding-left: 43px;
        background-repeat: no-repeat;
        font-weight: $font-normal;
        line-height: 54px;
        color: #fff;
        &.reserve-form-title-menu {
          background-image: url(/assets/img/menu_icon_03.svg);
          background-size: 25px auto;
          background-position: left center;
        }
        &.reserve-form-title-seat {
          background-image: url(/assets/img/edit_icon_02.svg);
          background-size: 16px auto;
          background-position: 15px center;
        }
      }
    }
    main {
      position: relative;
      width: 942px;
      margin: 50px auto 0;
      box-sizing: border-box;
    }
    .reserve-form-screen-title {
      margin-top: 15px;
      font-size: 18px;
      font-weight: $font-medium;
      color: #331a1a;
    }
    .reserve-confirmation-container {
      @include float();
      margin-top: 65px;
      margin-bottom: 83px;
    }
    .reserve-confirmation-detail-container {
      float: left;
      width: 540px;
      padding-top: 20px;
      padding-bottom: 15px;
      padding-left: 20px;
      padding-right: 20px;
      background-color: #f4f4f4;
      border-radius: 16px;
      box-sizing: border-box;
    }
    .reserve-confirmation-detail-title {
      font-size: 16px;
      color: #9d9d9d;
      font-weight: $font-medium;
    }
    .reserve-confirmation-matter {
      padding-top: 30px;
      li {
        padding-left: 35px;
        background-repeat: no-repeat;
        background-position: left center;
        border-bottom: 1px dotted #5c5c5c;
        line-height: 30px;
        color: #5c5c5c;
        font-size: 14px;
        font-weight: $font-normal;
        &.reserve-confirmation-date {
          background-image: url(/assets/img/calendar_icon_03.svg);
          background-size: 16px auto;
        }
        &.reserve-confirmation-time {
          background-image: url(/assets/img/time_icon_04.svg);
          background-size: 16px auto;
        }
        &.reserve-confirmation-alternativedate {
          background-image: url(/assets/img/alternative_date_icon.svg);
          background-size: 16px auto;
        }
        &.reserve-confirmation-adult-people {
          background-image: url(/assets/img/people_adult_icon_03.svg);
          background-size: 19px auto;
        }
        &.reserve-confirmation-child-people {
          background-image: url(/assets/img/people_child_icon_03.svg);
          background-size: 19px auto;
        }
        &.reserve-confirmation-baby-people {
          background-image: url(/assets/img/people_baby_icon_03.svg);
          background-size: 19px auto;
        }
        &.reserve-confirmation-store {
          background-image: url(/assets/img/store_icon_01.svg);
          background-size: 18px auto;
        }
        & + li {
          margin-top: 15px;
        }
      }
    }
    .reserve-confirmation-right-container {
      float: right;
      width: 346px;
    }
    .reserve-submit-box {
      margin-top: 30px;
      padding: 22px;
      border-radius: 8px;
      background-color: #f4f4f4;
      color: #5c5c5c;
      font-weight: $font-medium;
    }
    .reserve-message-head {
      font-size: 16px;
    }
    .reserve-message-txt {
      margin-top: 15px;
      font-size: 12px;
      line-height: 20px;
    }
  }
}

// =====================================
// SP
// =====================================

@media screen and (max-width: 768px) {
  .reserve-confirmation,
  .menu-reserve-confirmation {
    overflow: hidden;
    background-color: #fff;
    header {
      padding-top: 40px;
      padding-bottom: 10px;
      background-color: #fff;
    }
    .reserve-form-title {
      font-weight: $font-medium;
      font-size: 18px;
      font-size: 1.8rem;
      color: #5c5c5c;
      text-align: center;
    }
    main {
      padding-bottom: 170px;
      background-color: #f4f4f4;
    }
    .reserve-confirmation-detail-container {
      background-color: #f4f4f4;
      border-radius: 16px 16px 0 0;
    }
    .reserve-confirmation-detail-title {
      padding-top: 20px;
      padding-left: 10px;
      padding-right: 10px;
      font-weight: $font-medium;
      font-size: 16px;
      font-size: 1.6rem;
      color: #9d9d9d;
    }
    .reserve-confirmation-matter {
      padding-top: 30px;
      li {
        padding-left: 35px;
        background-repeat: no-repeat;
        background-position: left center;
        border-bottom: 1px dotted #5c5c5c;
        line-height: 3rem;
        color: #5c5c5c;
        font-size: 16px;
        font-size: 1.6rem;
        font-weight: $font-normal;
        &.reserve-confirmation-date {
          background-image: url(/assets/img/calendar_icon_01.svg);
          background-size: 16px auto;
        }
        &.reserve-confirmation-time {
          background-image: url(/assets/img/time_icon_01.svg);
          background-size: 16px auto;
        }
        &.reserve-confirmation-alternativedate {
          background-image: url(/assets/img/alternative_date_icon_01.svg);
          background-size: 16px auto;
        }
        &.reserve-confirmation-adult-people {
          background-image: url(/assets/img/people_adult_icon_01.svg);
          background-size: 19px auto;
        }
        &.reserve-confirmation-child-people {
          background-image: url(/assets/img/people_child_icon_01.svg);
          background-size: 19px auto;
        }
        &.reserve-confirmation-baby-people {
          background-image: url(/assets/img/people_baby_icon_01.svg);
          background-size: 19px auto;
        }
        &.reserve-confirmation-store {
          background-image: url(/assets/img/store_icon_01.svg);
          background-size: 18px auto;
        }
        & + li {
          margin-top: 15px;
        }
      }
    }
    .reserve-message {
      margin-top: 35px;
      padding-left: 13px;
      padding-right: 13px;
      box-sizing: border-box;
      color: #5c5c5c;
    }
    .reserve-message-head {
      font-weight: $font-medium;
      font-size: 16px;
      font-size: 1.6rem;
    }
    .reserve-message-txt {
      margin-top: 13px;
      margin-bottom: 40px;
      font-size: 12px;
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }

  // 席予約
  .reserve-confirmation {
    .form-footer-ask {
      display: none;
    }
    .reservation-fee-box {
      padding-top: 23px;
      padding-bottom: 15px;
      background-color: #f4f4f4;
      border-radius: 0 0 16px 16px;
    }
  }

  // メニュー予約
  .menu-reserve-confirmation {
    .reserve-form-contents {
      margin-top: 32px;
      margin-left: 6px;
      margin-right: 6px;
    }
    .reserve-message {
      margin-top: 35px;
    }
    .reservation-fee-container {
      padding-top: 1px;
      padding-bottom: 0;
      border-radius: 0 0 16px 16px;
      background-color: #f4f4f4;
    }
  }
}
