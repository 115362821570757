.reserve-form {
  // =====================================
  // PC
  // =====================================
  @media screen and (min-width: 769px) {
    .reserve-form-title {
      background-color: #ff5800;
      text-align: center;
      span {
        display: inline-block;
        font-size: 16px;
        margin: 0 auto;
        padding-left: 43px;
        background-image: url(/assets/img/edit_icon_02.svg);
        background-size: 16px auto;
        background-position: 15px center;
        background-repeat: no-repeat;
        font-weight: $font-normal;
        line-height: 54px;
        color: #fff;
      }
    }
    .reserve-form-top-area {
      background-color: #ffffff;
      .reserve-form-top-area-inner {
        width: 942px;
        margin: 0 auto;
        padding-top: 43px;
        padding-bottom: 31px;
      }
    }
    .reserve-form-store-seat-name {
      margin-top: 20px;
      width: 540px;
      border-bottom: 1px solid #707070;
      font-size: 24px;
      font-weight: $font-normal;
      color: #5c5c5c;
      line-height: 30px;
      span {
        display: inline-block;
        margin-right: 15px;
        font-size: 14px;
      }
    }
    .reserve-form-main-container {
      display: flex;
      justify-content: space-between;
      width: 942px;
      margin: 0 auto;
      padding-bottom: 66px;
    }
    .reserve-form-left-container {
      width: 540px;
    }
    .reserve-form-right-container {
      width: 346px;
    }
    .reserve-form-rest-info {
      margin-top: 10px;
      padding-top: 11px;
      padding-bottom: 25px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12);
      input,
      select {
        font-size: 12px;
        &::placeholder {
          color: #9d9d9d;
        }
        &:focus {
          outline: 0;
          border-bottom: #ff5800 2px solid;
        }
      }
      & + .reserve-form-container {
        margin-top: 18px;
      }
      .block-title {
        padding-left: 20px;
        border-left: #ff5800 2px solid;
        font-weight: $font-medium;
        line-height: 19px;
        color: #5c5c5c;
      }
      .reserve-form-input-area {
        margin-top: 14px;
        margin-left: 22px;
        margin-right: 22px;
        li {
          &:nth-child(2) {
            margin-top: 12px;
          }
          &:nth-child(n + 3) {
            margin-top: 18px;
          }
        }
      }
      .reserve-form-txt {
        margin-bottom: 5px;
        color: #9d9d9d;
        font-size: 9px;
      }
      .reserve-form-txt-first {
        color: #ff5800;
      }
      .reserve-input-txt {
        width: 100%;
        border: none;
        border-bottom: #ccc 1px solid;
      }
      .reserve-input-required {
        background-image: url(/assets/img/req_icon_01.svg);
        background-size: 24px auto;
        background-repeat: no-repeat;
        background-position: left center;
        padding-left: 28px;
        box-sizing: border-box;
      }
      .reserve-form-input-two-block {
        display: flex;
        justify-content: space-between;
      }
      .reserve-form-input-country {
        width: 24%;
        background-color: #fff;
        border-radius: 0;
        color: #9d9d9d;
      }
      .reserve-form-input-phone {
        width: 73%;
      }
      .reserve-form-rest-info {
        margin-top: 13px;
      }
    }
    .reservation-fee-01 {
      margin-top: 20px;
      margin-bottom: 58px;
      .reservation-fee-line-box {
        &::before,
        &::after {
          content: none;
        }
      }
    }
    .conditions-chice li {
      border: 1px solid #e6e6e6;
    }
  }

  // =====================================
  // SP
  // =====================================

  @media screen and (max-width: 768px) {
    overflow: hidden;
    main {
      padding-bottom: 120px;
    }
    .reserve-form-seach-txt-box {
      background-color: #ff5800;
      padding-top: 50px;
      padding-bottom: 13px;
      padding-left: 20px;
      border-radius: 0 0 16px 16px;
    }
    .reserve-form-seach-txt {
      padding-left: 25px;
      background-image: url(/assets/img/saerch_text_icon.svg);
      background-size: 13px auto;
      background-repeat: no-repeat;
      background-position: left top;
      color: #fff;
      font-weight: $font-normal;
    }
    .prev-arrow {
      display: block;
      width: 25px;
      height: 25px;
      margin-top: 10px;
      margin-left: 10px;
    }
    .reserve-form-title {
      margin-top: 36px;
      margin-bottom: 18px;
      font-weight: $font-medium;
      font-size: 18px;
      font-size: 1.8rem;
      color: #ff5800;
      text-align: center;
    }
    .callout-icon {
      display: block;
      width: 49px;
      height: 49px;
    }
    .notes-area {
      margin-top: 55px;
      margin-bottom: 24px;
    }

    .reserve-error {
      margin-top: 20px;
      background-color: #ff7474;
      color: #fff;
    }
    .reserve-error-head {
      text-align: center;
      border-bottom: 1px solid #fff;
    }
    .reserve-error-title {
      padding-top: 20px;
      font-size: 20px;
      font-size: 2rem;
      font-weight: $font-semi-bold;
    }
    .reserve-error-txt {
      font-size: 14px;
      font-size: 1.4rem;
      font-weight: $font-medium;
      line-height: 3rem;
    }
    .reserve-error-list {
      margin-top: 20px;
      li {
        margin-left: 12px;
        margin-right: 12px;
        line-height: 4.2rem;
        font-size: 14px;
        font-size: 1.4rem;
        font-weight: $font-normal;
        & + li {
          border-top: 1px dotted #fff;
        }
        span {
          font-weight: $font-semi-bold;
        }
      }
    }
    .payment-type {
      padding-left: 20px;
      padding-right: 20px;
      box-sizing: border-box;
    }
    .reserve-form-container {
      padding-bottom: 35px;
      padding-top: 11px;
      margin-top: 11px;
      margin-right: 11px;
      margin-left: 11px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12);
      input,
      select {
        font-size: 1.2rem;
        &::placeholder {
          color: #9d9d9d;
        }
        &:focus {
          outline: 0;
          border-bottom: #ff5800 2px solid;
        }
      }
      & + .reserve-form-container {
        margin-top: 18px;
      }
      .block-title {
        padding-left: 20px;
        border-left: #ff5800 2px solid;
        font-weight: $font-medium;
        line-height: 1.9rem;
        color: #5c5c5c;
      }
      .reserve-form-input-area {
        margin-top: 14px;
        margin-left: 22px;
        margin-right: 22px;
        li {
          &:nth-child(2) {
            margin-top: 12px;
          }
          &:nth-child(n + 3) {
            margin-top: 18px;
          }
        }
      }
      .reserve-form-txt {
        margin-bottom: 5px;
        color: #71d57d;
        font-size: 0.9rem;
      }
      .reserve-form-txt-first {
        color: #ff5800;
      }
      .reserve-input-txt {
        width: 100%;
        border: none;
        border-bottom: #ccc 1px solid;
      }
      .reserve-input-required {
        background-image: url(/assets/img/req_icon_01.svg);
        background-size: 24px auto;
        background-repeat: no-repeat;
        background-position: left center;
        padding-left: 28px;
        box-sizing: border-box;
      }
      .reserve-form-input-two-block {
        display: flex;
        justify-content: space-between;
      }
      .reserve-form-input-country {
        width: 24%;
        background-color: #fff;
        border-radius: 0;
        color: #9d9d9d;
      }
      .reserve-form-input-phone {
        width: 73%;
      }
      .reserve-form-rest-info {
        margin-top: 13px;
      }
    }
  }
}
