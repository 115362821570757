.payment-selection {
  // =====================================
  // PC
  // =====================================
  @media screen and (min-width: 769px) {
    header {
      border-bottom: 1px solid #e6e6e6;
      padding: 20px 0;
      width: 942px;
      margin: 0 auto;
    }
    .payment-selection-title {
      width: 48px;
      margin: 0 auto;
      img {
        width: 100%;
        height: auto;
      }
    }
    .thanks-txt {
      text-align: center;
      font-weight: $font-medium;
      font-size: 18px;
      padding: 30px 0 10px 0;
      color: #5c5c5c;
    }
    .payment-txt1 {
      font-size: 14px;
      padding: 30px 0 10px 0;
      color: #999;
    }
    .payment-txt2 {
      font-weight: $font-medium;
      font-size: 18px;
      margin: 30px 0 0 0;
      padding: 0 0 10px 30px;
      background: url(../img/store_icon_01.svg) 0 0 no-repeat;
      color: #5c5c5c;
      border-bottom: 1px dotted #999;
    }
    .reserve-form-main-container {
      width: 942px;
      margin: 0 auto;
      padding-bottom: 66px;
    }
    .reservation-fee-01 {
      margin-top: 20px;
      margin-bottom: 58px;
      .reservation-fee-line-box {
        &::before,
        &::after {
          content: none;
        }
      }
    }
    .reservation-fee-01 .reservation-fee-type {
      background-size: 307px auto;
      background-position: center bottom;
    }
    .payment-notification-txt {
      font-size: 14px;
      color: #5c5c5c;
      line-height: 1.6;
    }
    .payment-selection-cancelpolicy-txt {
      font-size: 14px;
      color: #5c5c5c;
      line-height: 1.6;
    }
    .paymethod-submit-btn span {
      max-width: 320px;
      margin: 0 auto;
      display: block;
    }
    .paymethod-submit-btn input {
      background: url(../img/yenmark.png) 20px center no-repeat;
      display: block;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      background-color: #71d57d;
      border: none;
      border-radius: 100px;
      -webkit-box-shadow: 0px 3px 10px rgba(113, 213, 125, 0.48);
      box-shadow: 0px 3px 10px rgba(113, 213, 125, 0.48);
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      display: block;
    }
    .reservation-fee-price {
      max-width: 200px;
    }
    .paymethod-txt {
      border-bottom: 1px solid #ccc;
      padding: 0 0 5px 0;
      margin: 0 0 20px 0;
    }
    .paymethod-radio > div {
      margin: 0 0 20px 0;
    }
    .paymethod-radio > div > div {
      margin: 10px 0 0 0;
    }
    input[type='radio'] {
      display: none;
    }
    .radio {
      box-sizing: border-box;
      -webkit-transition: background-color 0.2s linear;
      transition: background-color 0.2s linear;
      position: relative;
      padding: 0 0 0 30px;
      vertical-align: middle;
      cursor: pointer;
    }
    .radio:after {
      -webkit-transition: border-color 0.2s linear;
      transition: border-color 0.2s linear;
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      margin-top: -10px;
      width: 18px;
      height: 18px;
      border: 1px solid #bbb;
      border-radius: 18px;
      content: '';
    }
    .radio:before {
      -webkit-transition: opacity 0.2s linear;
      transition: opacity 0.2s linear;
      position: absolute;
      top: 50%;
      left: 5px;
      display: block;
      margin-top: -5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #ff5800;
      content: '';
      opacity: 0;
    }
    input[type='radio']:checked + .radio:before {
      opacity: 1;
    }
    footer {
      padding: 20px;
    }
    footer .f-in {
      width: 942px;
      margin: 0 auto;
    }
    footer .f-txt {
      color: #fff;
      font-size: 14px;
      text-align: center;
    }
    footer .f-txt2 {
      color: #777;
      font-size: 12px;
      padding: 20px 0 0 0;
      text-align: center;
    }
    footer .f-txt2 a {
      color: #fff;
    }
  }

  // =====================================
  // SP
  // =====================================

  @media screen and (max-width: 768px) {
    overflow: hidden;
    .reserve-form-title {
      margin-top: 36px;
      margin-bottom: 18px;
      font-weight: $font-medium;
      font-size: 18px;
      font-size: 1.8rem;
      color: #ff5800;
      text-align: center;
    }
    .payment-type {
      padding-left: 20px;
      padding-right: 20px;
      box-sizing: border-box;
    }
    .reserve-form-container {
      padding-bottom: 35px;
      padding-top: 11px;
      margin-top: 11px;
      margin-right: 11px;
      margin-left: 11px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12);
      input,
      select {
        font-size: 1.2rem;
        &::placeholder {
          color: #9d9d9d;
        }
        &:focus {
          outline: 0;
          border-bottom: #ff5800 2px solid;
        }
      }
      & + .reserve-form-container {
        margin-top: 18px;
      }
    }

    header {
      border-bottom: 1px solid #e6e6e6;
      padding: 20px 0;
      background: #fff;
    }
    .payment-selection-title {
      width: 48px;
      margin: 0 auto;
      img {
        width: 100%;
        height: auto;
      }
    }
    .thanks-txt {
      background: #fff;
      text-align: center;
      font-weight: $font-medium;
      font-size: 18px;
      padding: 30px 0 30px 0;
      color: #5c5c5c;
    }
    .payment-txt1 {
      font-size: 14px;
      padding: 20px 0 10px 0;
      margin: 0 10px;
      color: #999;
    }
    .payment-txt2 {
      font-weight: $font-medium;
      font-size: 18px;
      margin: 30px 10px 0 10px;
      padding: 0 0 10px 30px;
      background: url(../img/store_icon_01.svg) 0 0 no-repeat;
      color: #5c5c5c;
      border-bottom: 1px dotted #999;
    }
    .reservation-fee-01 {
      margin-top: 20px;
      margin-bottom: 58px;
      .reservation-fee-line-box {
        &::before,
        &::after {
          content: none;
        }
      }
    }
    .reservation-fee-01 .reservation-fee-type {
      background-size: 307px auto;
      background-position: center bottom;
    }
    .payment-notification-txt {
      font-size: 14px;
      color: #5c5c5c;
      line-height: 1.6;
    }
    .payment-selection-cancelpolicy-txt {
      font-size: 14px;
      color: #5c5c5c;
      line-height: 1.6;
    }
    .paymethod-submit-btn span {
      max-width: 320px;
      margin: 0 auto;
      display: block;
    }
    .paymethod-submit-btn input {
      background: url(../img/yenmark.png) 20px center no-repeat;
      display: block;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      background-color: #71d57d;
      border: none;
      border-radius: 100px;
      -webkit-box-shadow: 0px 3px 10px rgba(113, 213, 125, 0.48);
      box-shadow: 0px 3px 10px rgba(113, 213, 125, 0.48);
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      display: block;
    }
    .reservation-fee-price {
      max-width: 200px;
    }
    .paymethod-txt {
      border-bottom: 1px solid #ccc;
      padding: 0 0 5px 0;
      margin: 0 10px 20px 10px;
    }
    .paymethod-radio {
      margin: 0 10px;
    }
    .paymethod-radio > div {
      margin: 0 0 20px 0;
    }
    .paymethod-radio > div > div {
      margin: 10px 0 0 0;
    }
    input[type='radio'] {
      display: none;
    }
    .radio {
      box-sizing: border-box;
      -webkit-transition: background-color 0.2s linear;
      transition: background-color 0.2s linear;
      position: relative;
      padding: 0 0 0 30px;
      vertical-align: middle;
      cursor: pointer;
    }
    .radio:after {
      -webkit-transition: border-color 0.2s linear;
      transition: border-color 0.2s linear;
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      margin-top: -10px;
      width: 18px;
      height: 18px;
      border: 1px solid #bbb;
      border-radius: 18px;
      content: '';
    }
    .radio:before {
      -webkit-transition: opacity 0.2s linear;
      transition: opacity 0.2s linear;
      position: absolute;
      top: 50%;
      left: 5px;
      display: block;
      margin-top: -5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #ff5800;
      content: '';
      opacity: 0;
    }
    input[type='radio']:checked + .radio:before {
      opacity: 1;
    }
    footer {
      background: #353535;
      padding: 20px;
    }
    footer .f-in {
      margin: 0 auto;
    }
    footer .f-txt {
      color: #fff;
      font-size: 14px;
      text-align: center;
    }
    footer .f-txt2 {
      color: #777;
      font-size: 12px;
      padding: 20px 0 0 0;
      text-align: center;
    }
    footer .f-txt2 a {
      color: #fff;
    }
    .creditcard_img {
      width: 309px;
    }
    .unionpay_img {
      width: 44px;
    }
    .alipay_img {
      width: 55px;
    }
    .paymethod-box {
      background: #fff;
      padding: 20px 0 50px 0;
    }
  }
}
