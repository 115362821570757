// =====================================
// PC
// =====================================
@media screen and (min-width: 769px) {
  .restaurant {
    overflow: hidden;
    width: 1025px;
    margin: 0 auto;
    .slick-prev {
      z-index: 1;
      position: absolute;
      left: 40px;
    }

    .slick-next {
      z-index: 1;
      position: absolute;
      right: 80px;
    }

    .slick-prev:before {
      content: '\2770';
      background-color: rgba(51, 51, 51, 0.6);
      font-size: 30px;
      opacity: 1;
      position: absolute;
      top: -9px;
      right: 10px;
      height: 36px;
      padding: 12px;
    }

    .slick-prev:hover:before {
      background-color: rgba(11, 11, 11, 1);
    }

    .slick-next:before {
      content: '\2771';
      background-color: rgba(51, 51, 51, 0.6);
      font-size: 30px;
      opacity: 1;
      position: absolute;
      top: -9px;
      left: 52px;
      height: 36px;
      padding: 12px;
    }

    .slick-next:hover:before {
      background-color: rgba(11, 11, 11, 1);
    }

    .restaurant__slide1 {
      .slick-slide img {
        display: block;
        width: 1025px;
        height: 450px;
        object-fit: cover;
      }
    }

    .slick-dots {
      position: relative;
      background: #fff;
      top: -50px;
      background-color: transparent;
    }

    .slick-dots li button:before {
      color: #fff;
      opacity: 0.6;
    }

    .slick-dots li.slick-active button:before {
      color: #fff;
      font-size: 16px;
      opacity: 1;
    }

    header {
      width: 1025px;
      margin: 0 auto;
    }
    .flexbox {
      display: -webkit-flex;
      display: flex;
    }
    .flex-main {
      margin: 0 60px 0 0;
      width: 590px;
    }
    .flex-side {
      margin-top: 20px;
      width: 375px;
    }

    .menu-btn-icon {
      position: fixed;
      top: 0;
      right: 0;
      z-index: 10;
      img {
        width: 78px;
        height: 78px;
      }
    }
    .help-btn-icon {
      position: fixed;
      top: 60px;
      right: 0;
      z-index: 10;
      cursor: pointer;
      img {
        width: 78px;
        height: 78px;
      }
    }
    .menu-icon {
      display: block;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .main-inner {
      position: relative;
      width: 1025px;
      margin: 0 auto 100px;
    }
    .section-title {
      color: #5c5c5c;
      font-weight: $font-medium;
    }
    .section-title::before {
      content: ' ';
      white-space: pre;
      background: #ff5800;
      width: 100px;
      border-radius: 4px;
      margin-right: 5px;
    }
    .kv {
      width: 1025px;
      margin: 0 auto 30px auto;
    }
    .kv_restaurant_menu {
      width: 1025px;
      margin: 0 auto 30px auto;
      text-align: center;
      position: relative;
      img {
        width: 100%;
        height: 372px;
        object-fit: cover;
        cursor: pointer;
      }
      .play-video-icon {
        width: 65px;
        height: 66px;
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
    .restaurant-head {
      width: 590px;
      background-color: #fff;
    }
    .restaurant-description {
      width: 590px;
      background-color: #fff;
    }
    .restaurant-box-icon {
      position: absolute;
      top: 160px;
      right: 14px;
      width: 28px;
      height: auto;
    }
    // .evaluation-detail {
    //   padding-bottom: 15px;
    //   padding-left: 0;
    //   padding-right: 0;
    // }
    .restaurant-name-container {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      img {
        height: 48px;
        width: 48px;
        border-radius: 48px;
        object-fit: cover;
      }
    }
    .restaurant-name-en {
      font-size: 32px;
      color: #5c5c5c;
      font-weight: $font-medium;
    }
    .restaurant-name-ja {
      margin-top: 8px;
      font-size: 16px;
      color: #5c5c5c;
      font-weight: $font-medium;
    }
    .restaurant-line {
      border-style: dotted;
      border-color: #5c5c5c;
      margin: 0;
    }
    .restaurant-txt {
      margin-top: 10px;
      font-size: 14px;
      line-height: 24px;
      color: #5c5c5c;
      font-weight: $font-normal;
    }
    .restaurant-chefname {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 24px;
      color: #5c5c5c;
      font-weight: $font-normal;
      text-align-last: right;
    }
    .restaurant-info {
      padding: 14px 14px 40px;
      box-sizing: border-box;
      background-color: #f4f4f4;
    }
    .restaurant-map {
      margin-top: 15px;
      margin-bottom: 20px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      iframe {
        width: 100%;
        height: 150px;
      }
    }
    .restaurant-address {
      position: relative;
      margin-top: 15px;
      padding-left: 40px;
      padding-right: 30px;
      font-size: 14px;
      line-height: 23px;
      font-weight: $font-normal;
      color: #5c5c5c;
      background-image: url(/assets/img/pin_icon_03.svg);
      background-size: 13px auto;
      background-repeat: no-repeat;
      background-position: left center;
    }
    .restaurant-note {
      margin-top: 15px;
      margin-bottom: 7px;
      padding-left: 40px;
      background-image: url(/assets/img/note_icon.svg);
      background-size: 19px;
      background-repeat: no-repeat;
      background-position: left;
      font-size: 14px;
      line-height: 23px;
      font-weight: $font-normal;
      color: #5c5c5c;
    }
    .restaurant-holiday {
      margin-top: 15px;
      margin-bottom: 7px;
      padding-left: 40px;
      background-image: url(/assets/img/calendar_closed_icon.svg);
      background-size: 19px;
      background-repeat: no-repeat;
      background-position: left;
      font-size: 14px;
      line-height: 23px;
      font-weight: $font-normal;
      color: #5c5c5c;
    }
    .restaurant-time {
      margin-top: 15px;
      margin-bottom: 7px;
      padding-left: 40px;
      background-image: url(/assets/img/time_icon_03.svg);
      background-size: 19px;
      background-repeat: no-repeat;
      background-position: left;
      font-size: 14px;
      line-height: 23px;
      font-weight: $font-normal;
      color: #5c5c5c;
    }
    .restaurant-tel {
      margin-top: 15px;
      margin-bottom: 7px;
      padding-left: 40px;
      background-image: url(/assets/img/smartphone_icon.svg);
      background-size: 19px;
      background-repeat: no-repeat;
      background-position: left;
      font-size: 14px;
      line-height: 23px;
      font-weight: $font-normal;
      color: #5c5c5c;
    }
    .restaurant-seats {
      margin-top: 13px;
      margin-bottom: 6px;
      padding-left: 40px;
      background-image: url(/assets/img/seat_icon_01.svg);
      background-size: 22px;
      background-repeat: no-repeat;
      background-position: left -4px;
      font-size: 16px;
      color: #5c5c5c;
      font-weight: $font-normal;
      line-height: 20px;
    }
    .restaurant-review-summary {
      width: 590px;
      padding-top: 20px;
      padding-bottom: 50px;
      background-color: #fff;
    }
    .restaurant-review-summary-box {
      margin-top: 40px;
      display: flex;
    }
    .restaurant-review-summary-num {
      font-size: 48px;
      font-weight: $font-normal;
    }
    .restaurant-review-summary-evaluation {
      margin-left: 20px;
    }
    .restaurant-review-summary-star {
      width: auto;
      height: 16px;
    }
    .restaurant-review-summary-reviews {
      margin-top: 10px;
      font-size: 12px;
      font-weight: $font-normal;
    }
    .restaurant-review-summary-txt-container {
      margin-top: 25px;
      li {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: $font-normal;
        & + li {
          margin-top: 20px;
        }
      }
    }
    .restaurant-review-summary-txt-img {
      width: 38px;
      height: 38px;
      margin-right: 10px;
    }
    .restaurant-review-summary-more {
      display: inline-block;
      margin-top: 30px;
      text-decoration: none;
      font-size: 12px;
    }
    .restaurant-menu-area {
      width: 590px;
      padding-top: 36px;
      background-color: #fff;
      border-top: 1px dotted #5c5c5c;
    }
    .restaurant-menu-tab {
      display: flex;
      li {
        padding-left: 14px;
        padding-right: 14px;
        font-size: 14px;
        color: #5c5c5c;
        line-height: 26px;
        cursor: pointer;
        & + li {
          margin-left: 5px;
        }
        &.restaurant-menu-tab-active {
          border-radius: 16px;
          background-color: #5c5c5c;
          color: #ffffff;
        }
      }
    }
    .restaurant-menu-list {
      margin-top: 40px;
      & > li {
        padding-bottom: 30px;
        & + li {
          border-top: 1px solid #e6e6e6;
          padding-top: 30px;
        }
      }
    }
    .restaurant-menu-title {
      color: #3b3b3b;
      font-size: 15px;
      font-weight: $font-medium;
      img {
        width: 15px;
        padding-right: 5px;
        vertical-align: middle;
      }
    }
    .restaurant-menu-course {
      margin-top: 20px;
      font-size: 14px;
      color: #5c5c5c;
      font-weight: $font-normal;
    }
    .restaurant-menu-notes {
      margin-top: 5px;
      font-size: 10px;
      color: #9d9d9d;
      line-height: 16px;
    }
    .restaurant-menu-price {
      margin-top: 15px;
      font-size: 12px;
      color: #9d9d9d;
      span {
        color: #5c5c5c;
        &:before {
          content: '¥';
        }
      }
    }
    .restaurant-cancelpolicy {
      margin-top: 20px;
      color: #ff5800;
      font-weight: $font-normal;
    }
    .restaurant-cancelpolicy-head {
      padding-left: 30px;
      background-image: url(/assets/img/bell_icon_03.svg);
      background-size: 22px;
      background-repeat: no-repeat;
      background-position: left center;
      font-size: 16px;
      line-height: 20px;
    }
    .restaurant-cancelpolicy-txt {
      margin-top: 15px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.2px;
    }
    .restaurant-photo-area {
      padding-top: 20px;
      padding-bottom: 19px;
      background-color: #fff;
    }
    .restaurant-photos {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        width: 327px;
        margin-top: 20px;
        img {
          width: 100%;
        }
      }
    }
    .restaurant-seat-reservation-btn {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 375px;
      background-image: url(/assets/img/edit_icon_02.svg);
      background-size: 22px;
      background-repeat: no-repeat;
      background-position: 87px center;
      background-color: #ff5800;
      border-radius: 8px;
      line-height: 54px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      text-decoration: none;
      font-weight: $font-normal;
      cursor: 'pointer';
    }
    .restaurant-menu-reservation-btn {
      position: absolute;
      top: 21;
      right: 0;
      display: block;
      width: 375px;
      background-image: url(/assets/img/menu_icon_03.svg);
      background-size: 22px;
      background-repeat: no-repeat;
      background-position: 87px center;
      background-color: #ff5800;
      border-radius: 8px;
      line-height: 54px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      text-decoration: none;
      font-weight: $font-normal;
      cursor: 'pointer';
    }
    .restaurant-you-might-also-like {
      padding-top: 45px;
      background-color: #f4f4f4;
    }
    .restaurant-you-might-also-like-inner {
      width: 1025px;
      margin: 0 auto;
    }
    .restaurant-you-might-also-like-head {
      font-size: 16px;
      font-weight: $font-semi-bold;
      color: #5c5c5c;
    }
    .restaurant-you-might-also-like-photo {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 24px;
      margin-left: -13px;
      li {
        position: relative;
        margin-bottom: 20px;
        margin-left: 13px;
        position: relative;
        overflow: hidden;
        display: block;
        width: 165px;
        height: 165px;
        border-radius: 8px;
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          display: block;
          padding-top: 40%;
          background: linear-gradient(rgba(0, 0, 0, 0) 0%, #000 100%);
        }
        img {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: auto;
          height: 100%;
        }
        p {
          position: absolute;
          bottom: 8px;
          left: 0;
          right: 0;
          margin: auto;
          z-index: 1;
          text-align: center;
          color: #fff;
          font-size: 14px;
          font-weight: $font-normal;
        }
      }
    }
    .restaurant-you-might-also-like-seat {
      &::before {
        content: 'SEAT';
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 1;
        width: 33px;
        background-color: rgba(244, 244, 244, 0.72);
        border-radius: 4px;
        color: #000000;
        font-size: 10px;
        text-align: center;
        line-height: 1.3rem;
      }
    }
    .restaurant-you-might-also-like-menu {
      &::before {
        content: 'MENU';
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 1;
        width: 33px;
        background-color: #ff5800;
        border-radius: 4px;
        color: #fff;
        font-size: 10px;
        text-align: center;
        line-height: 1.3rem;
      }
    }
  }
}
// =====================================
// SP
// =====================================
@media screen and (max-width: 768px) {
  overflow: hidden;

  .slick-prev {
    z-index: 1;
    position: absolute;
    left: 40px;
  }

  .slick-next {
    z-index: 1;
    position: absolute;
    right: 80px;
  }

  .slick-prev:before {
    color: #ff5800;
    font-size: 35px;
    opacity: 1;
    position: absolute;
    top: -30px;
    right: 10px;
  }

  .slick-next:before {
    color: #ff5800;
    font-size: 35px;
    opacity: 1;
    position: absolute;
    top: -30px;
    left: 52px;
  }

  .restaurant__slide1 {
    background-color: #fff;
    .slick-slide img {
      display: block;
      width: 100%;
      height: 260px;
      object-fit: cover;
    }
  }

  .slick-dots {
    position: relative;
    background: #fff;
    top: -50px;
    background-color: transparent;
  }

  .slick-dots li {
    margin: 0;
  }

  .slick-dots li button:before {
    color: #fff;
    opacity: 0.6;
  }

  .slick-dots li.slick-active button:before {
    color: #fff;
    font-size: 16px;
    opacity: 1;
  }

  .restaurant-chefImage {
    position: absolute;
    top: 390px;
    left: 0px;
    right: 0;
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
    margin: auto;
    margin-top: 48px;
  }

  .restaurant-chefImage2 {
    position: absolute;
    top: 190px;
    left: 0px;
    right: 0;
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
    margin: auto;
    margin-top: 48px;
  }

  .section-title {
    color: #5c5c5c;
    margin-bottom: 15px;
    border-bottom: 1px solid #5c5c5c;
    line-height: 2.6rem;
    font-size: 1.6rem;
    font-weight: $font-medium;
  }
  .kv {
    margin: 0 auto 30px auto;
  }
  .kv_restaurant_menu {
    margin: 0 auto 0px auto;
    padding: 130px 0 15px 0;
    background-color: #fff;
    img {
      display: none;
    }
  }
  .restaurant-image-box {
    width: 100%;
    height: 66px;
  }
  .restaurant-image-box-inner {
    position: relative;
    height: 66px;
    .play-video-icon {
      width: 64px;
      height: 64px;
      margin: auto;
      position: absolute;
      top: 55px;
      left: 0;
      right: 0;
    }
  }
  .restaurant-image {
    position: absolute;
    top: -180%;
    left: 50%;
    transform: translateX(-50%);
    width: 140%;
    height: 294px;
    border-radius: 100%;
    overflow: hidden;
    img {
      position: relative;
      top: 7%;
      display: block;
      margin: 0 auto;
      object-fit: cover;
    }
  }
  .restaurant-img-icon {
    position: absolute;
    top: 166px;
    left: 0;
    right: 0;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin: auto;
  }
  .restaurant-head {
    padding-left: 14px;
    padding-right: 14px;
    background-color: #fff;
  }
  .restaurant-description {
    padding-bottom: 25px;
    padding-left: 14px;
    padding-right: 14px;
    background-color: #fff;
  }
  .restaurant-box-icon {
    position: absolute;
    top: 196px;
    right: 14px;
    width: 28px;
    height: auto;
  }
  // .evaluation-detail {
  //   padding-bottom: 15px;
  //   padding-left: 0;
  //   padding-right: 0;
  // }

  .restaurant-name-container {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    .restaurant-menu-reservation-btn {
      height: 50px;
      width: 150px;
      margin: 0;
      line-height: 50px;
    }
    .restaurant-seat-reservation-btn {
      height: 50px;
      width: 150px;
      margin: 0;
      line-height: 50px;
    }
  }
  .restaurant-name-en {
    font-size: 20px;
    color: #5c5c5c;
    font-weight: $font-medium;
  }
  .restaurant-name-ja {
    margin-top: 8px;
    font-size: 14px;
    font-size: 1.4rem;
    color: #5c5c5c;
    font-weight: $font-medium;
  }
  .restaurant-line {
    border-style: dotted;
    margin: 0;
  }
  .restaurant-txt {
    margin-top: 10px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #5c5c5c;
    font-weight: $font-normal;
  }
  .restaurant-chefname {
    margin-top: 10px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #5c5c5c;
    font-weight: $font-normal;
    text-align-last: right;
  }
  .restaurant-info {
    padding-top: 30px;
    padding-left: 14px;
    padding-right: 14px;
  }
  .restaurant-map {
    margin-top: 15px;
    margin-bottom: 20px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    iframe {
      width: 100%;
      height: 150px;
    }
  }
  .restaurant-address {
    position: relative;
    margin-top: 15px;
    padding-left: 40px;
    padding-right: 30px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2.3rem;
    font-weight: $font-normal;
    color: #5c5c5c;
    background-image: url(/assets/img/pin_icon_03.svg);
    background-size: 13px auto;
    background-repeat: no-repeat;
    background-position: left center;
  }
  .restaurant-note {
    margin-top: 15px;
    margin-bottom: 7px;
    padding-left: 40px;
    background-image: url(/assets/img/note_icon.svg);
    background-size: 19px;
    background-repeat: no-repeat;
    background-position: left;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2.3rem;
    font-weight: $font-normal;
    color: #5c5c5c;
  }
  .restaurant-holiday {
    margin-top: 15px;
    margin-bottom: 7px;
    padding-left: 40px;
    background-image: url(/assets/img/calendar_closed_icon.svg);
    background-size: 19px;
    background-repeat: no-repeat;
    background-position: left;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2.3rem;
    font-weight: $font-normal;
    color: #5c5c5c;
  }
  .restaurant-time {
    margin-top: 15px;
    margin-bottom: 7px;
    padding-left: 40px;
    background-image: url(/assets/img/time_icon_03.svg);
    background-size: 19px;
    background-repeat: no-repeat;
    background-position: left;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2.3rem;
    font-weight: $font-normal;
    color: #5c5c5c;
  }
  .restaurant-tel {
    margin-top: 15px;
    margin-bottom: 7px;
    padding-left: 40px;
    background-image: url(/assets/img/smartphone_icon.svg);
    background-size: 19px;
    background-repeat: no-repeat;
    background-position: left;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2.3rem;
    font-weight: $font-normal;
    color: #5c5c5c;
  }
  .restaurant-seats {
    margin-top: 13px;
    margin-bottom: 6px;
    padding-left: 40px;
    background-image: url(/assets/img/seat_icon_01.svg);
    background-size: 22px;
    background-repeat: no-repeat;
    background-position: left -4px;
    font-size: 16px;
    font-size: 1.6rem;
    color: #5c5c5c;
    font-weight: $font-normal;
    line-height: 2rem;
  }
  .restaurant-review-summary {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 14px;
    padding-right: 14px;
    background-color: #fff;
  }
  .restaurant-review-summary-box {
    margin-top: 40px;
    display: flex;
  }
  .restaurant-review-summary-num {
    font-size: 48px;
    font-size: 4.8rem;
    font-weight: $font-normal;
  }
  .restaurant-review-summary-evaluation {
    margin-left: 20px;
  }
  .restaurant-review-summary-star {
    width: auto;
    height: 16px;
  }
  .restaurant-review-summary-reviews {
    margin-top: 10px;
    font-size: 12px;
    font-size: 1.2rem;
    font-weight: $font-normal;
  }
  .restaurant-review-summary-txt-container {
    margin-top: 25px;
    li {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-size: 1.2rem;
      font-weight: $font-normal;
      & + li {
        margin-top: 20px;
      }
    }
  }
  .restaurant-review-summary-txt-img {
    width: 38px;
    height: 38px;
    margin-right: 10px;
  }
  .restaurant-review-summary-more {
    display: inline-block;
    margin-top: 30px;
    text-decoration: none;
    font-size: 12px;
    font-size: 1.2rem;
  }
  .restaurant-menu-area {
    padding: 54px 14px 14px;
    background-color: #fff;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
  }
  .restaurant-photo-area {
    padding-top: 40px;
    padding-bottom: 19px;
    padding-left: 14px;
    padding-right: 14px;
    background-color: #fff;
  }
  .restaurant-menu-tab {
    display: flex;
    li {
      padding-left: 14px;
      padding-right: 14px;
      font-size: 14px;
      font-size: 1.4rem;
      color: #5c5c5c;
      line-height: 2.6rem;
      cursor: pointer;
      & + li {
        margin-left: 5px;
      }
      &.restaurant-menu-tab-active {
        border-radius: 16px;
        background-color: #5c5c5c;
        color: #ffffff;
      }
    }
  }
  .restaurant-menu-list {
    margin-top: 40px;
    & > li {
      padding-bottom: 30px;
      & + li {
        border-top: 1px solid #e6e6e6;
        padding-top: 30px;
      }
    }
  }
  .restaurant-menu-title {
    color: #3b3b3b;
    font-size: 15px;
    font-size: 1.5rem;
    font-weight: $font-medium;
    img {
      width: 15px;
      padding-right: 5px;
      vertical-align: middle;
    }
  }
  .restaurant-menu-course {
    margin-top: 20px;
    font-size: 14px;
    font-size: 1.4rem;
    color: #5c5c5c;
    font-weight: $font-normal;
  }
  .restaurant-menu-notes {
    margin-top: 5px;
    font-size: 10px;
    font-size: 1rem;
    line-height: 16px;
    color: #9d9d9d;
  }
  .restaurant-menu-price {
    font-size: 12px;
    font-size: 1.2rem;
    color: #9d9d9d;
    span {
      color: #5c5c5c;
      &:before {
        content: '¥';
      }
    }
  }
  .restaurant-cancelpolicy {
    margin-top: 20px;
    color: #ff5800;
    font-weight: $font-normal;
  }
  .restaurant-cancelpolicy-head {
    padding-left: 30px;
    background-image: url(/assets/img/bell_icon_03.svg);
    background-size: 22px;
    background-repeat: no-repeat;
    background-position: left center;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .restaurant-cancelpolicy-txt {
    margin-top: 15px;
    margin-bottom: 20px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: -0.2px;
  }
  .restaurant-photos {
    li + li {
      margin-top: 20px;
    }
  }
  .restaurant-seat-reservation-btn {
    display: block;
    background-color: #ff5800;
    border-radius: 8px;
    line-height: 9.5rem;
    text-align: center;
    color: #fff;
    font-size: 20px;
    font-size: 2rem;
    text-decoration: none;
    font-weight: $font-normal;
  }
  .restaurant-menu-reservation-btn {
    display: block;
    background-color: #ff5800;
    border-radius: 8px;
    line-height: 9.5rem;
    text-align: center;
    color: #fff;
    font-size: 20px;
    font-size: 2rem;
    text-decoration: none;
    font-weight: $font-normal;
  }
  .restaurant-you-might-also-like {
    padding-top: 56px;
    padding-bottom: 60px;
    padding-left: 14px;
    padding-right: 14px;
  }
  .restaurant-you-might-also-like-head {
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: $font-normal;
    color: #5c5c5c;
  }
  .restaurant-you-might-also-like-photo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-top: 24px;
    li {
      position: relative;
      margin-bottom: 20px;
      position: relative;
      overflow: hidden;
      display: block;
      width: 47.5%;
      height: auto;
      padding-top: 47.5%;
      border-radius: 8px;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: block;
        padding-top: 40%;
        background: linear-gradient(rgba(0, 0, 0, 0) 0%, #000 100%);
      }
      img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
        height: 100%;
      }
      p {
        position: absolute;
        bottom: 8px;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 1;
        text-align: center;
        color: #fff;
        font-size: 14px;
        font-size: 1.4rem;
        font-weight: $font-normal;
      }
    }
  }
  .restaurant-you-might-also-like-seat {
    &::before {
      content: 'SEAT';
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 1;
      width: 33px;
      background-color: rgba(244, 244, 244, 0.72);
      border-radius: 4px;
      color: #000000;
      font-size: 1px;
      font-size: 1rem;
      text-align: center;
      line-height: 1.3rem;
    }
  }
  .restaurant-you-might-also-like-menu {
    &::before {
      content: 'MENU';
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 1;
      width: 33px;
      background-color: #ff5800;
      border-radius: 4px;
      color: #fff;
      font-size: 1px;
      font-size: 1rem;
      text-align: center;
      line-height: 1.3rem;
    }
  }
}

.richtext {
  > * + * {
    margin-top: 0.75rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.25rem;
  }

  h5 {
    font-size: 1rem;
  }

  h6 {
    font-size: 0.875rem;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  p {
    margin: 10px 0;
  }

  a {
    color: #337ab7;
    text-decoration: none;

    &:hover {
      color: #23527c;
      text-decoration: underline;
    }
  }

  ul {
    list-style-type: disc;
    padding-left: 1rem;

    li {
      list-style-type: disc;
    }
  }

  ol {
    list-style-type: decimal;
    padding-left: 1rem;

    li {
      list-style-type: decimal;
    }
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
    color: #777;
    font-style: italic;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}
