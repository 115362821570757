.notice {
  // =====================================
  // PC
  // =====================================
  @media screen and (min-width: 769px) {
    position: relative;
    padding-top: 20px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #f4f4f4;
    color: #707070;
    .header-inner {
      position: relative;
      width: 1025px;
      margin: 0 auto;
    }
    .notification-title {
      margin-top: 60px;
      padding-left: 33px;
      font-size: 16px;
      line-height: 23px;
      font-weight: $font-medium;
      background-image: url(/assets/img/bell_icon_03.svg);
      background-size: 22px;
      background-repeat: no-repeat;
      background-position: left center;
      font-family: 'Sawarabi Mincho', sans-serif;
    }
    .notification-txt {
      margin-top: 15px;
      font-size: 14px;
      line-height: 18px;
      font-weight: $font-normal;
      font-family: 'Sawarabi Mincho', sans-serif;
    }
    .notification-close {
      position: absolute;
      top: 40px;
      right: 0;
      width: 10px;
      height: 10px;
    }
  }

  // =====================================
  // SP
  // =====================================
  @media screen and (max-width: 768px) {
    position: relative;
    padding-top: 75px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #f4f4f4;
    color: #707070;
    .notification-title {
      padding-left: 33px;
      font-size: 16px;
      font-size: 1.6rem;
      line-height: 2.3rem;
      font-weight: $font-medium;
      background-image: url(/assets/img/bell_icon_03.svg);
      background-size: 22px;
      background-repeat: no-repeat;
      background-position: left center;
    }
    .notification-txt {
      margin-top: 15px;
      font-size: 14px;
      font-size: 1.4rem;
      line-height: 1.8rem;
      font-weight: $font-normal;
      font-family: 'Sawarabi Mincho', sans-serif;
    }
    .notification-close {
      position: absolute;
      top: 75px;
      right: 16px;
      width: 10px;
      height: 10px;
    }
  }
}

.notice-goto {
  // =====================================
  // PC
  // =====================================
  @media screen and (min-width: 769px) {
    position: relative;
    padding-top: 20px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    .notification-title {
      padding-left: 33px;
      font-size: 16px;
      line-height: 23px;
      font-weight: $font-medium;
      background-image: url(/assets/img/bell_icon_03.svg);
      background-size: 22px;
      background-repeat: no-repeat;
      background-position: left center;
    }
    .notification-txt {
      margin-top: 15px;
      font-size: 14px;
      line-height: 1.4;
      font-weight: $font-normal;
    }

    .notification-maff {
      display: block;
      line-height: 1.8;
      font-size: 14px;
      margin: 10px 10px 10px 0;
    }
  }

  // =====================================
  // SP
  // =====================================
  @media screen and (max-width: 768px) {
    position: relative;
    padding-top: 25px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    .notification-title {
      padding-left: 33px;
      font-size: 16px;
      font-size: 1.6rem;
      line-height: 2.3rem;
      font-weight: $font-medium;
      background-image: url(/assets/img/bell_icon_03.svg);
      background-size: 22px;
      background-repeat: no-repeat;
      background-position: left center;
    }
    .notification-txt {
      margin-top: 15px;
      font-size: 14px;
      font-size: 1.4rem;
      line-height: 1.8rem;
      font-weight: $font-normal;
    }

    .notification-maff {
      display: block;
      word-break: break-all;
      line-height: 1.2;
      font-size: 14px;
      margin: 10px 10px 10px 0;
    }
  }
}
