.reserve-complete {
  header {
    width: 1025px;
    margin: 0 auto;
  }
  main {
    margin-bottom: 90px;
  }
  .main-inner {
    max-width: 1025px;
    width: 100%;
    margin: 0 auto 100px;
  }
  .menu-icon {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .reserve-complete-title {
    background-color: #71d57d;
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-size: 2rem;
    font-weight: $font-medium;
    line-height: 10rem;
  }
  .reserve-complete-txt {
    font-size: 20px;
    font-size: 2rem;
    color: #16d156;
    text-align: center;
    line-height: 3.5rem;
  }
  .reserve-complete-txt2 {
    color: #5c5c5c;
    text-align: center;
    line-height: 2rem;
    margin: 20px 39px 0;
  }
  .reserve-complete-sent {
    display: block;
    width: 87px;
    height: 87px;
    margin: 20px auto 0;
  }
  .reserve-complete-return-btn {
    display: block;
    width: 280px;
    margin: 65px auto 0;
    line-height: 5rem;
    border: 1px solid #95989a;
    border-radius: 10px;
    text-align: center;
    text-decoration: none;
    color: #5c5c5c;
    font-size: 15px;
    font-size: 1.5rem;
  }
  #svgContainer {
    padding: 50px 0 0 0;
    text-align: center;
  }
  #svgContainer svg {
    width: 40% !important;
  }

  // 完了画面の縦幅の少ない画面で、フッターが最下部に表示されるようにする
  @media screen and (min-width: 1180px) {
    .main-inner {
      margin: 0 auto 300px;
    }
  }
}
