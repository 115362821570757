// =====================================
// PC
// =====================================
.chefle-kitchen {
  @at-root {
    #{&}__top {
      margin-top: 40px;
      width: 400px !important;
      line-height: 1.2;
    }

    #{&}__top-header {
      font-size: 18px;
      margin-left: 10px;
      color: #5c5c5c;
      font-family: 'Sawarabi Mincho', sans-serif;
    }

    #{&}__header {
      text-align: center;
      font-family: 'Sawarabi Mincho', sans-serif;
      color: #5c5c5c;
      padding: 20px 0 20px;
      font-size: 30px;
    }

    #{&}__new-label {
      position: absolute;
      top: -15px;
      left: 385px;
      color: #ff5800;
    }

    #{&}__foods {
      display: flex;
      flex-wrap: wrap;
    }

    #{&}__nousui {
      line-height: 25px;
      padding-right: 14px;
      padding-left: 14px;
      margin-top: 40px;
    }

    #{&}__food {
      position: relative;
      margin: 10px 10px;
      width: 31%;
      height: auto;
      border-radius: 8px;
      margin: 10px 10px 130px 10px;
    }

    #{&}__food-description {
      line-height: 1.2;
      height: 60px;
    }

    #{&}__food-chefe {
      margin-top: 10px;
    }

    #{&}__food-intermediate {
      margin-top: 5px;
    }

    #{&}__image {
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      top: 0;
      left: 0;
      border-radius: 8px 8px 0 0;
    }

    #{&}__bg-dark {
      background-color: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      border-radius: 8px 8px 0 0;
    }

    #{&}__image-content {
      position: relative;
      display: block;
      width: 100%;
      height: 160px;
      text-decoration: none;
      color: #5c5c5c;
      font-size: 14px;
      &:hover {
        opacity: 0.8;
      }
      &:before {
        content: '';
        display: block;
        padding-top: 50%;
      }
    }

    #{&}__image-icon {
      width: 62px;
      height: auto;
      position: absolute;
      top: 5px;
      left: 5px;
      z-index: 1;
    }

    #{&}__image-text {
      position: absolute;
      text-align: center;
      width: 200px;
      top: 50%;
      left: 50%;
      font-size: 36px;
      font-weight: bold;
      font-family: serif;
      color: #fff;
      display: block;
      z-index: 2;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }

    #{&}__image-button {
      position: absolute;
      top: 65%;
      left: 50%;
      font-size: 22px;
      color: #fff;
      border: 1px solid #ff5800;
      background: #ff5800;
      border-radius: 12px;
      padding: 3px 52px;
      display: block;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }

    #{&}__image-button-karasumi {
      position: absolute;
      top: 80%;
      left: 50%;
      font-size: 22px;
      color: #fff;
      border: 1px solid #ff5800;
      background: #ff5800;
      border-radius: 12px;
      padding: 3px 52px;
      display: block;
      z-index: 2;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }

    #{&}__description {
      position: relative;
      padding: 20px 10px;
      font-size: 20px;
      border: 5px solid #ccc;
      margin: 50px 20px;

      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: -30px;
        left: 11px;
        background: #f4f4f4;
        font-size: 12px;
        color: #325a8c;
        padding: 0 10px;
        width: 132px;
        height: 70px;
      }
    }

    #{&}__logo {
      position: absolute;
      display: block;
      width: auto;
      height: 63px;
      top: -43px;
      z-index: 1;
    }

    #{&}__description-header {
      position: absolute;
      top: 13px;
      left: 164px;
      font-size: 24px;
    }

    #{&}__description-text {
      margin-top: 28px;
      font-size: 16px;
      line-height: 1.4;
    }

    #{&}__banner-header {
      position: absolute;
      color: #fff;
      top: 30px;
      z-index: 1;
      font-size: 16px;
      left: 107px;
      opacity: 0.8;
      font-family: 'Sawarabi Mincho', sans-serif;
    }

    #{&}__banner-text {
      position: absolute;
      color: #fff;
      top: 60px;
      z-index: 1;
      font-size: 11px;
      left: 70px;
      opacity: 0.8;
      font-family: 'Sawarabi Mincho', sans-serif;
    }

    #{&}__banner-header-side {
      display: flex;
      position: absolute;
      width: 100%;
      height: 100%;
      color: #fff;
      font-size: 16px;
      opacity: 0.7;
      margin: 0;
      justify-content: center;
      align-items: center;
      font-family: 'Sawarabi Mincho', sans-serif;
    }

    #{&}__banner-text-side {
      display: flex;
      position: absolute;
      color: #ff5800;
      width: 100%;
      height: 100%;
      font-size: 14px;
      opacity: 0.8;
      justify-content: center;
      align-items: center;
      margin-top: 18px;
    }

    #{&}__video {
      display: block;
      position: absolute;
      top: 10px;
      left: 290px;
    }

    #{&}__video-content {
      width: 86%;
      height: 4;
      object-fit: cover;
    }

    #{&}__crab {
      background-color: orange;
      width: 74%;
      border: orange;
      text-align: center;
      padding: 30px 10px;
      position: absolute;
      top: 40%;
      left: 11%;
      font-size: 37px;
      color: #fff;
      display: block;
    }

    #{&}__crab-name {
      font-size: 36px;
    }

    #{&}__crab-text {
      line-height: 1.2;
      margin-top: 20px;
      font-size: 20px;
    }

    #{&}__chefe-contents {
      display: flex;
      flex-direction: row;
      padding: 10px 20px;
    }

    #{&}__chefe-content {
      display: flex;
      flex-direction: column;
      padding: 10px;
    }

    #{&}__chefe-image {
      width: 250px;
      height: auto;
      margin-right: 20px;
      object-fit: cover;
    }

    #{&}__chefe-video {
      font-size: 20px;
      color: #333;
      margin-top: 10px;
    }

    #{&}__chefe-link {
      display: flex;
      flex-direction: column;
    }

    #{&}__shop-name {
      text-align: left;
      font-size: 22px;
      margin-top: 30px;
      font-weight: bold;
    }

    #{&}__shop-description {
      line-height: 1.2;
      margin-top: 30px;
      font-size: 18px;
    }

    #{&}__card {
      width: 95%;
      color: black;
    }

    #{&}__card-media {
      height: 113px;
      object-fit: cover;
    }

    #{&}__slider {
      padding: 5px;
      margin: 0 40px;
    }

    #{&}__card-content {
      display: flex;
      align-items: center;
      height: 20px;
      padding: 10px !important;
    }

    #{&}__card-text {
      font-size: 14px !important;
    }

    #{&}__movie-content {
      padding: 10px;
    }

    #{&}__movie-header {
      font-size: 26px;
      font-weight: bold;
      margin: 20px 0;
    }

    #{&}__movie-description {
      font-size: 16px;
      line-height: 1.4;
    }

    #{&}__movie-button {
      text-decoration: none;
      border: 1px solid #ff5800;
      background: #ff5800;
      color: #fff;
      font-size: 24px;
      border-radius: 7px;
      padding: 10px 57px;
      text-align: center;
      display: block;
      margin: 25px 100px;
      font-weight: bold;
      &:hover {
        opacity: 0.7;
      }
    }

    #{&}__food-content {
      background: #fff;
      padding: 10px;
      border-radius: 0 0 8px 8px;
      height: 100px;
      box-shadow: 0 3px 15px rgba(0, 0, 0, 0.16);
      font-size: 13.5px;
    }

    #{&}__special {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin: 10px;
    }

    #{&}__special-link {
      display: block;
      margin: 10px;
      text-decoration: none;
      width: 220px;
      border: solid 2px #ff5800;
      padding: 10px;
      border-radius: 8px;
      text-align: center;
      color: #ff5800;
      &:hover {
        color: #fff;
        background-color: #ff5800;
      }
    }

    #{&}__ranking-content {
      display: flex;
      justify-content: center;
    }

    #{&}__ranking-card {
      margin: 10px;
    }

    #{&}__ranking-order {
      display: block;
      text-align: center;
      font-weight: bold;
      font-size: 22px;
    }

    #{&}__ranking-card-text {
      font-size: 13px;
      margin-top: 5px;
    }

    #{&}__ranking-card-content {
      height: 70px;
      padding: 8px !important;
    }

    #{&}__ranking-link {
      text-decoration: none;
      color: #333;
      width: 100%;
    }

    #{&}__ranking-peraichi {
      text-align: right;
      display: block;
      color: #333;
      font-weight: bold;
      margin: 10px 10px 20px 10px;
    }

    #{&}__ranking-1 {
      border: 3px solid #c7ad14 !important;
    }

    #{&}__ranking-2 {
      border: 3px solid #92a6ad !important;
    }

    #{&}__ranking-3 {
      border: 3px solid #dc9771 !important;
    }

    #{&}__ranking-text-1 {
      color: #c7ad14;
    }

    #{&}__ranking-text-2 {
      color: #92a6ad;
    }

    #{&}__ranking-text-3 {
      color: #dc9771;
    }
  }
}

.chefle-kitchen__slider {
  .slick-prev:before {
    color: #ff5800;
    font-size: 40px;
    opacity: 1;
    position: absolute;
    top: -9px;
    left: -20px;
  }
  .slick-next:before {
    color: #ff5800;
    font-size: 40px;
    opacity: 1;
    position: absolute;
    top: -9px;
    right: -20px;
  }
}

// =====================================
// SP
// =====================================
@media screen and (max-width: 480px) {
  .chefle-kitchen {
    @at-root {
      #{&}__top {
        width: 370px !important;
      }

      #{&}__top-header {
        font-size: 17px;
      }

      #{&}__header {
        font-size: 23px;
      }

      #{&}__description-header {
        font-size: 15px;
        font-weight: bold;
      }

      #{&}__nousui {
        margin-top: 10px;
      }

      #{&}__food {
        width: 100%;
        margin: 10px 10px 15px 10px;
      }

      #{&}__food-content {
        height: 70px;
        font-size: 13px;
      }

      #{&}__image-text {
        font-size: 36px;
      }

      #{&}__image-button {
        top: 70%;
        font-size: 18px;
      }

      #{&}__image-button-karasumi {
        top: 75%;
        font-size: 18px;
      }

      #{&}__video-content {
        overflow: auto;
        width: 80%;
        height: 160px;
        object-fit: cover;
        z-index: 10;
      }

      #{&}__video {
        display: block;
        position: unset;
        text-align: center;
        width: 100%;
        margin-bottom: 40px;
      }

      #{&}__image-icon {
        width: 70px;
      }

      #{&}__crab {
        background-color: orange;
        width: 65%;
        border: orange;
        text-align: center;
        padding: 10px;
        position: absolute;
        top: 38%;
        left: 16%;
        font-size: 37px;
        color: #fff;
        display: block;
      }

      #{&}__crab-name {
        font-size: 28px;
      }

      #{&}__crab-text {
        margin-top: 12px;
        font-size: 15px;
      }

      #{&}__chefe-contents {
        display: flex;
        flex-direction: column-reverse;
        padding: 10px 20px;
      }

      #{&}__chefe-content {
        display: flex;
        flex-direction: row;
        padding: 10px 0;
      }

      #{&}__chefe-image {
        width: 200px;
        height: 133px;
        margin-right: 10px;
        object-fit: cover;
      }

      #{&}__chefe-video {
        font-size: 16px;
        color: #333;
        margin-top: 10px;
      }

      #{&}__chefe-link {
        display: flex;
        // flex-direction: column;
      }

      #{&}__shop-name {
        text-align: left;
        font-size: 20px;
        font-weight: bold;
        margin-top: 10px;
      }

      #{&}__shop-description {
        line-height: 1.2;
        margin-top: 10px;
        font-size: 16px;
      }

      #{&}__food-text {
        display: flex;
        align-items: flex-end;
        font-size: 13px;
      }

      #{&}__food-chefe {
        margin-right: 20px;
      }

      #{&}__foods-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
      }

      #{&}__food-text-sp {
        font-size: 22px;
        width: 150px;
      }

      #{&}__food-button {
        border: 1px solid #ff5800;
        padding: 5px 40px;
        text-decoration: none;
        color: #fff;
        background: #ff5800;
        border-radius: 5px;
        font-size: 18px;
        &:hover {
          opacity: 0.8;
        }
      }

      #{&}__food-title-sp {
        display: block;
        font-size: 14px;
        margin-left: 36px;
        margin-bottom: 20px;
        color: #333;
      }

      #{&}__foods-title-sp {
        display: block;
        font-size: 16px;
        margin-left: 36px;
        margin-bottom: 30px;
        color: #333;
      }

      #{&}__image-content {
        position: relative;
        display: block;
        width: 100%;
        height: 200px;
        margin-bottom: 80px;
        color: #5c5c5c;
        &:before {
          content: '';
          display: block;
          padding-top: 50%;
          height: -webkit-fill-available;
        }
      }

      #{&}__card {
        width: 95%;
        color: black;
      }

      #{&}__card-content {
        height: 40px;
        padding: 10px !important;
      }

      #{&}__card-text {
        font-size: 14px !important;
      }

      #{&}__card-media {
        height: 94px;
        object-fit: cover;
      }

      #{&}__slider {
        margin: 0 10px;
        padding: 10px 0;
      }

      #{&}__slider-dots {
        padding-bottom: 20px;
      }

      #{&}__movie-header {
        font-size: 20px;
      }

      #{&}__movie-description {
        font-size: 16px;
      }

      #{&}__movie-button {
        font-size: 20px;
        padding: 10px 20px;
        margin: 10px 0;
        &:hover {
          opacity: 0.7;
        }
      }

      #{&}__beginner {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      #{&}__beginner-image {
        display: block;
        margin: 9px auto;
        width: 177px;
      }

      #{&}__beginner-description {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
        text-align: center;
        color: #333;
      }

      #{&}__beginner-link {
        color: #333;
        font-size: 16px;
        font-weight: bold;
        margin-top: 20px;
        text-align: center;
      }

      #{&}__beginner-description-new {
        font-size: 14px;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 5px;
      }

      #{&}__beginner-text {
        line-height: 1.4;
      }

      #{&}__food-description {
        height: 50px;
      }

      #{&}__special-link {
        width: 132px;
      }

      #{&}__ranking-card-text {
        font-size: 12px;
      }

      #{&}__ranking-card-content {
        height: 85px;
        padding: 8px !important;
      }

      #{&}__ranking-card {
        margin: 5px;
      }
    }
  }
}
