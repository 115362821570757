// =====================================
// PC
// =====================================

.chefle-creditcard {
  input {
    -webkit-appearance: auto;
    appearance: auto;
  }

  input[type='checkbox'] {
    transform: scale(2);
  }

  input[type='radio'] {
    display: inline-block;
    transform: scale(2);
  }

  font-family: Poppins;
  @at-root {
    #{&}__wrapper {
      margin-top: 18px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12);
    }
    #{&}__warning-head {
      color: #912d2b;
      font-weight: bold;
      font-size: 16px;
      line-height: 1.4;
    }

    #{&}__warning-text {
      color: #912d2b;
      opacity: 0.8;
      margin: 10px 0;
      font-size: 15px;
      line-height: 1.4;
    }

    #{&}__head {
      font-weight: bold;
      font-size: 20px;
      color: #333;
    }

    #{&}__images {
      width: 250px;
      margin-top: 20px;
    }

    #{&}__image {
      width: 50px;
      margin-left: 20px;
    }

    #{&}__warnings {
      border: 1px solid lightgray;
      border-radius: 5px;
      padding: 15px;
      margin: 10px 0 20px 0;
      background-color: #fffaf3;
      color: #573a08;
    }

    #{&}__warning-text2 {
      margin: 10px 0;
      font-size: 15px;
      line-height: 1.4;
    }

    #{&}__forms {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
    }

    #{&}__form {
      width: 50%;
    }

    #{&}__input {
      width: 90%;
      height: 30px;
      margin: 10px 0;
    }

    #{&}__registerButton {
      margin-top: 20px;
      background: #912d2b;
      border: none;
      border-radius: 3px;
      color: #fff;
      padding: 10px 30px;
      font-size: 16px;
      cursor: pointer;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      &:hover {
        opacity: 0.8;
      }
    }

    #{&}__modalCancelButton {
      margin-top: 20px;
      background: darkgray;
      border: none;
      border-radius: 3px;
      color: #fff;
      padding: 10px 30px;
      font-size: 16px;
      cursor: pointer;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      &:hover {
        opacity: 0.8;
      }
    }

    #{&}__modalDeleteButton {
      margin-top: 20px;
      background: #912d2b;
      border: none;
      border-radius: 3px;
      color: #fff;
      padding: 10px 30px;
      font-size: 16px;
      cursor: pointer;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      &:hover {
        opacity: 0.8;
      }
    }

    #{&}__warning-text3 {
      color: #912d2b;
      margin-bottom: 20px;
      line-height: 1.4;
    }

    #{&}__infos {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid lightgray;
      padding: 20px;
      margin: 10px;
      border-radius: 5px;
    }

    #{&}__info {
      display: flex;
      align-items: center;
    }

    #{&}__deleteButton {
      border: 1px solid lightgray;
      background: #fff;
      border-radius: 3px;
      padding: 5px 10px;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
    }

    #{&}__number {
      font-size: 22px;
      margin-bottom: 10px;
    }

    #{&}__expiration {
      font-size: 15px;
      opacity: 0.6;
    }

    #{&}__warning {
      color: #912d2b;
      font-weight: bold;
      font-size: 16px;
      margin-top: 10px;
    }

    #{&}__modalButtons {
      display: flex;
      justify-content: space-around;
    }

    #{&}__modalText {
      text-align: center;
      line-height: 1.4;
    }

    #{&}__submitButton {
      max-width: 320px;
      margin: 40px auto 0;
      background: url(../img/yenmark.png) 20px center no-repeat;
      display: block;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      background-color: #71d57d;
      border: none;
      border-radius: 100px;
      -webkit-box-shadow: 0px 3px 10px rgba(113, 213, 125, 0.48);
      box-shadow: 0px 3px 10px rgba(113, 213, 125, 0.48);
      color: #fff;
      font-weight: 500;
      font-size: 20px;
      display: block;
      cursor: pointer;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      &:hover {
        opacity: 0.8;
      }
    }

    #{&}__checkbox1 {
      display: flex;
      align-items: baseline;
      margin-left: 10px;
    }

    #{&}__label {
      margin: 20px 0 10px 10px;
      font-size: 18px;
    }

    #{&}__radio {
      display: flex;
      align-items: baseline;
    }
  }
}

.sc-dkPtRN {
  font-size: 17px !important;
  border: 1px solid red;
  border-radius: 5px;
  padding: 15px;
  margin-top: 10px !important;
  background-color: #fff4f6;
  color: #573a08;
}

// =====================================
// SP
// =====================================
@media screen and (max-width: 480px) {
  .chefle-creditcard {
    @at-root {
      #{&}__past {
        margin: 30px 10px !important;
      }

      #{&}__infos {
        display: block;
        padding: 20px 20px 50px 20px;
      }

      #{&}__number {
        font-size: 18px;
      }

      #{&}__deleteButton {
        float: right;
      }

      #{&}__modalCancelButton {
        font-size: 13px;
      }

      #{&}__modalDeleteButton {
        font-size: 13px;
      }

      #{&}__warning-text {
        font-size: 13px;
      }

      #{&}__warning-text2 {
        font-size: 13px;
      }

      #{&}__radio {
        margin: 0 20px;
      }

      #{&}__label {
        font-size: 16px;
      }

      #{&}__submitButton {
        margin: 10px auto 10px;
      }
    }
  }
}
