.goto {
  /* TODO コーディング規約を他と合わせる */
  /* TODO2 使用していないcssとclassの削除 */

  @media screen and (min-width: 769px) {
    header {
      background-color: red;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      top: 0;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.93);
      border-bottom: solid 1px #e6e6e6;
      z-index: 100;
    }
    .slide-container {
      width: 70%;
      margin: auto;
    }
    .slide-title {
      line-height: 65px;
      font-size: 55px;
      font-family: 'Sawarabi Mincho', sans-serif;
      letter-spacing: 3px;
      text-align: center;
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      z-index: 1;
    }
    .slide-title p {
      font-size: 20px;
    }
    .slide-sevices {
      width: 100%;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.3);
      position: absolute;
      bottom: 0;
      font-family: 'Sawarabi Mincho', sans-serif;
      z-index: 2;
    }
    .slide-sevices .go-to-eat-label {
      position: absolute;
      z-index: 1;
      background: #ff5800;
      padding: 5px;
      text-decoration: none;
      border-radius: 100px;
      color: white;
      top: 0;
      left: 5px;
      font-size: 13px;
    }
    .slide-sevices .go-to-eat-not-label {
      position: absolute;
      z-index: 1;
      background: #f4f4f4;
      padding: 5px;
      text-decoration: none;
      border-radius: 100px;
      color: #464646;
      top: 0;
      left: 5px;
      font-size: 13px;
    }
    .slide-sevices .slide-sevices-item {
      width: 320px;
      padding: 10px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
    }
    .slide-sevices .slide-sevices-item img {
      width: 320px;
    }
    .slide-sevices .slide-sevices-item span {
      position: absolute;
      color: black;
      bottom: 20px;
      text-decoration: none;
      border-radius: 100px;
      padding: 10px;
      font-size: 12px;
      text-align: center;
      width: 180px;
    }
    .slide-sevices .slide-sevices-item span img {
      width: 10px;
      height: 10px;
      position: absolute;
      right: 10px;
      top: 11px;
    }
    .slide-video::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
    .each-slide > div {
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
      height: 600px;
      width: 100%;
      background-position: center;
    }
    .each-slide span {
      padding: 20px;
      font-size: 20px;
      background: #efefef;
      text-align: center;
    }
    .each-fade {
      display: flex;
    }
    .each-fade .image-container {
      width: 75%;
      overflow: hidden;
    }
    .each-fade .image-container img {
      width: 100%;
    }
    .each-fade h2 {
      width: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      background: #adceed;
    }
    .top-page-logo img {
      width: 100px;
      padding: 10px 25px;
    }
    .header-inner {
      position: relative;
      width: 1025px;
      margin: 0 auto;
    }
    .top-page-notification-title {
      padding-left: 33px;
      font-size: 16px;
      line-height: 23px;
      font-weight: 500;
      background-image: url(/assets/img/bell_icon_03.svg);
      background-size: 22px;
      background-repeat: no-repeat;
      background-position: left center;
    }
    .top-page-notification-txt {
      margin-top: 15px;
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
    }
    .top-page-notification-close {
      position: absolute;
      top: 40px;
      right: 0;
      width: 10px;
      height: 10px;
    }
    .top-page-top {
      position: relative;
      background-color: #f4f4f4;
    }
    .top-page-top-inner {
      position: relative;
      width: 1025px;
      height: 336px;
      margin: 0 auto;
    }
    .menu-icon {
      position: absolute;
      top: 20px;
    }
    .top-logo {
      position: absolute;
      top: 70px;
      left: 190px;
      display: block;
      width: 125px;
      height: auto;
    }
    .top-page-top-txt {
      position: absolute;
      top: 80px;
      left: 410px;
      font-size: 20px;
      line-height: 36px;
      font-weight: 500;
      color: #5c5c5c;
    }
    .top-sarch-area {
      position: absolute;
      top: 182px;
      left: 413px;
      width: 355px;
      margin: 15px auto 0;
    }
    .top-sarch-area .top-sarch-container {
      width: 100%;
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.16);
      border-radius: 30px;
      background-color: #ffffff;
      display: block;
    }
    .top-sarch-area .top-sarch-container input {
      width: 100%;
      height: 63px;
      box-sizing: border-box;
      padding-left: 60px;
      background-image: url(/assets/img/edit_icon_03.svg);
      background-size: 13px auto;
      background-repeat: no-repeat;
      background-position: 30px center;
      border: none;
      outline: none;
      border-radius: 30px;
      font-size: 15px;
      font-weight: 400;
      font-size: 16px;
      transform: scale(0.834);
    }
    .top-sarch-area .top-sarch-container input::input-placeholder {
      color: #9d9d9d;
    }
    .top-sarch-area .top-sarch-container ul.suggest-list li {
      width: 310px;
      line-height: 45px;
      border: none;
      outline: none;
      font-size: 15px;
      font-weight: 400;
    }
    .top-sarch-area .top-sarch-container ul.suggest-list li a {
      width: 305px;
      padding-left: 30px;
      padding-right: 20px;
      line-height: 45px;
      display: inline-block;
      color: #9d9d9d;
      cursor: pointer;
    }
    .top-sarch-area .top-sarch-container ul.suggest-list li a span.area {
      background-image: url(/assets/img/pin_icon_02.svg);
      background-size: 12px auto;
      background-repeat: no-repeat;
      background-position: left center;
      padding-left: 17px;
      opacity: 0.5;
      float: right;
      color: #5c5c5c;
      font-size: 12px;
    }
    .top-sarch-area .top-sarch-container ul.suggest-list li a:hover {
      background-color: #f8f8f8;
    }
    .top-sarch-area .top-sarch-container ul.suggest-list li a:focus {
      background-color: #f8f8f8;
    }
    .top-sarch-area .top-sarch-container .search-bottom {
      width: 355px;
      height: 45px;
      box-sizing: border-box;
      border-radius: 0 0 30px 30px;
      display: inline-block;
      cursor: pointer;
    }
    .top-sarch-area .top-sarch-container .search-bottom a {
      width: 295px;
      padding-left: 60px;
      background-image: url(/assets/img/edit_icon_05.svg);
      background-size: 13px auto;
      background-repeat: no-repeat;
      background-position: 30px center;
      line-height: 45px;
      display: inline-block;
      border-radius: 0 0 30px 30px;
      color: #ff5800;
    }
    .top-sarch-area .top-sarch-container .search-bottom a:hover {
      background-color: #f8f8f8;
    }
    .top-sarch-area .top-sarch-container .search-bottom a:focus {
      background-color: #f8f8f8;
    }
    .video {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 40px;
    }
    .goto-about {
      background: linear-gradient(70deg, #ff5800, #fbb430);
      padding-bottom: 118px;
    }
    .top-page-selection {
      width: 1025px;
      margin: 0 auto;
      font-family: YuGothic, sans-serif;
    }
    .top-page-selection p {
      font-family: YuGothic, sans-serif;
    }
    .top-page-selection-head {
      display: flex;
      justify-content: space-between;
      margin-bottom: 64px;
    }
    .top-page-selection-title-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 95px 0 0;
    }
    .top-page-selection-title-box h4 {
      font-size: 28px;
      font-weight: bold;
      margin-left: 10px;
      color: #ff5800;
      font-family: 'Poppins';
    }
    .top-page-selection-title-box p {
      margin-top: 10px;
      color: #ff5800;
    }
    .top-page-selection-title {
      width: initial;
      height: initial;
      display: initial;
    }
    .top-page-selection-title img {
      width: 90px;
    }
    .top-page-selection-box {
      width: 290px;
      margin: 20px 10px 10px;
    }
    .top-page-selection-box .top-page-selection-box-img {
      height: 220px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .top-page-selection-box .top-page-selection-box-img img {
      width: 100%;
    }
    .top-page-selection-box h3 {
      text-align: left;
      font-family: YuGothic, sans-serif;
    }
    .top-page-selection-box h3 span {
      color: #ff5800;
    }
    .top-page-selection-box p {
      padding: 15px 0;
      line-height: 23px;
      font-family: YuGothic, sans-serif;
    }
    .top-page-selection-box-img-arrow {
      margin-top: 118px;
    }
    .goto-howto {
      background-color: #f4f4f4;
      padding-bottom: 84px;
    }
    .top-page-restaurant-title-box {
      color: #fff;
      padding: 70px 0 0;
      position: relative;
      display: block;
      text-align: center;
      margin: 0 auto;
      width: 640px;
    }
    .top-page-restaurant-title-box:before {
      width: 77px;
      height: 63px;
      background: url(/assets/img/gotoeat/goto-icon.png) no-repeat 0 0;
      background-size: 100%;
      position: absolute;
      left: 0;
      top: 46px;
      content: '';
    }
    .top-page-restaurant-title-box h3 {
      font-family: 'Sawarabi Mincho', sans-serif;
      font-weight: bold;
      font-size: 30px;
    }
    .top-page-restaurant-title-box p {
      font-family: 'Sawarabi Mincho', sans-serif;
      margin-top: 10px;
    }
    .thoughts-about-the-chefle {
      line-height: 32px;
      font-size: 14px;
      text-align: center;
      padding: 60px 0 70px;
      color: #fff;
      font-weight: bold;
    }
    .thoughts-about-the-chefle p {
      font-size: 12px;
    }
    .top-page-goto-box {
      width: 1025px;
      margin: 0 auto;
      background-color: #f4f4f4;
      padding: 40px 0;
      border-radius: 8px;
      box-shadow: 0px 9px 32px #ff5800;
    }
    .top-page-goto-box-title {
      text-align: center;
      margin-bottom: 37px;
      color: #ff5800;
    }
    .top-page-goto-box-title-top {
      font-size: 14px;
      margin-bottom: 12px;
    }
    .top-page-goto-box-title-middle {
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 36px;
      font-family: 'Poppins' !important;
    }
    .top-page-goto-box-title-bottom {
      display: inline-block;
      padding: 8px 36px;
      background-color: #ff5800;
      color: #fff;
      letter-spacing: 3px;
      font-weight: bold;
      font-size: 18px;
    }
    .top-page-goto-box-text {
      width: 700px;
      margin: 0 auto 37px;
      font-size: 17px;
      font-weight: bold;
      line-height: 40px;
    }

    .top-page-goto-box-campaign {
      width: 700px;
      margin: 0 auto 37px;
      font-size: 17px;
      font-weight: bold;
      line-height: 40px;
      text-decoration: line-through double #ff5800;
    }

    .top-page-goto-campaign-date {
      position: relative;
      text-decoration: line-through double #ff5800;
    }

    .top-page-goto-campaign-date-end {
      position: absolute;
      width: 100px;
      top: 13px;
      left: 0px;
      color: #ff5800;
    }

    .top-page-goto-text-point {
      font-size: 14px;
      line-height: 1.4;
      margin-left: 135px;
    }

    .top-page-goto-text-description {
      color: #333 !important;
      font-weight: normal !important;
    }

    .top-page-goto-box-box {
      display: flex;
      justify-content: space-between;
      width: 700px;
      margin: 0 auto 47px;
    }
    .top-page-goto-box-box-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 42%;
      padding: 16px;
      border: solid #ff5800;
      background-color: #fff;
      border-radius: 3px;
    }
    .top-page-goto-box-box-container-check {
      margin-left: 5px;
    }
    .top-page-goto-box-box-container-text {
      width: 84%;
      font-weight: bold;
    }
    .top-page-goto-box-box-container-text-top {
      margin: 10px 0 20px;
    }
    .top-page-goto-box-box-container-text-bottom {
      padding: 10px;
      background: #f4f4f4;
      text-align: center;
    }
    .top-page-goto-box-box-container-text-bottom span {
      font-size: 25px;
      color: #ff5800;
    }
    .top-page-goto-box-note {
      font-size: 13px;
      color: #ff5800;
      text-align: center;
      line-height: 24px;
    }
    .br-sp {
      display: none;
    }
    .top-page-goto-box-howto {
      font-size: 14px;
      background-color: #fff;
      padding: 36px;
      margin-bottom: 95px;
      border-radius: 3px;
    }
    .top-page-goto-box-howto-title {
      font-weight: bold;
      margin-bottom: 33px;
    }
    .top-page-goto-box-howto-note {
      line-height: 24px;
    }
    .top-page-goto-box-howto-note span {
      color: #ff5800;
      font-weight: bold;
    }

    .top-page-goto-box-buttons {
      display: flex;
      flex-direction: column;
    }

    .top-page-goto-box-button-agri {
      display: block;
      margin: 0 auto;
      border: solid 2px;
      padding: 24px;
      width: 330px;
      text-decoration: none;
      color: #000;
      font-weight: bold;
      font-family: YuGothic, sans-serif;
    }

    .top-page-goto-box-button-top {
      display: block;
      margin: 0 auto;
      border: solid 2px orange;
      background-color: orange;
      color: #fff;
      padding: 24px;
      width: 330px;
      text-decoration: none;
      font-weight: bold;
      font-family: YuGothic, sans-serif;
      margin-bottom: 20px;
      text-align: center;
    }

    .top-page-selection-txt {
      margin-top: 13px;
      font-size: 12px;
      color: #5c5c5c;
      line-height: 20px;
      font-weight: 400;
    }
    .top-page-selection-head-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 706px;
      border-bottom: 1px solid #cacaca;
    }
    .top-page-selection-head-eat-link {
      display: flex;
      justify-content: space-between;
    }
    .top-page-selection-head-eat-link p {
      position: relative;
      padding-left: 30px;
      padding-right: 20px;
      font-size: 16px;
      line-height: 20px;
      color: #5c5c5c;
      font-size: 14px;
    }
    .top-page-selection-head-eat-link p::after {
      content: '>';
      position: absolute;
      right: 0;
      display: inline-block;
      margin: auto;
      transform: rotate(90deg);
    }
    .goto
      .top-page-selection-head-eat-link
      p.top-page-selection-head-where-eat {
      background-image: url(/assets/img/pin_icon_04.svg);
      background-size: 15px auto;
      background-repeat: no-repeat;
      background-position: left center;
    }
    .top-page-selection-head-eat-link p.top-page-selection-head-what-eat {
      margin-left: 20px;
      background-image: url(/assets/img/eat_icon_02.svg);
      background-size: 18px auto;
      background-repeat: no-repeat;
      background-position: left center;
    }
    .top-page-selection-head-eat-link a {
      text-decoration: none;
      color: #5c5c5c;
    }
    .top-page-stores-title {
      padding: 25px 0px 0px 0px;
      color: #5c5c5c;
      font-size: 24px;
    }
    .top-page-stores-description {
      margin-top: 13px;
      color: #5c5c5c;
    }
    .top-page-store-tab-container {
      color: #5c5c5c;
    }
    .top-page-store-tab {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
    }
    .top-page-store-tab li {
      padding-top: 6px;
      padding-bottom: 6px;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 30px;
      box-sizing: border-box;
      font-size: 14px;
      cursor: pointer;
    }
    .top-page-store-tab li + li {
      margin-left: 15px;
    }
    .top-page-store-active {
      background-color: #ff5800;
      color: #fff;
    }
    .top-page-restaurant {
      background-color: #f4f4f4;
    }
    .top-page-store-area {
      padding-top: 20px;
      padding-bottom: 55px;
    }
    .top-page-sort-restaurant-btn {
      color: #5c5c5c;
      display: flex;
      cursor: pointer;
    }
    .top-page-sort-restaurant-btn p {
      margin: 0 10px;
    }
    .top-page-sort-restaurant-btn-active {
      color: #ff5800;
    }
    .top-page-store-card-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: -40px;
    }
    .top-page-store-card-box {
      width: 320px;
      position: relative;
      margin-top: 40px;
      padding-bottom: 10px;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      overflow: hidden;
    }
    .top-page-store-card-box::after {
      position: absolute;
      top: 5px;
      right: 5px;
      padding-top: 3px;
      padding-bottom: 3px;
      padding-right: 3px;
      padding-left: 3px;
      border-radius: 4px;
      color: #ffffff;
    }
    .top-page-store-card-box .top-page-store-card-img {
      width: 100%;
    }
    .top-page-store-card-title-box {
      position: relative;
      margin-top: 10px;
      margin-left: 10px;
      margin-right: 10px;
      padding-left: 50px;
    }
    .top-page-store-card-person {
      position: absolute;
      top: 0;
      left: 0;
      width: 32px;
      height: 32px;
    }
    .top-page-store-card-title-en {
      font-size: 14px;
      font-weight: 500;
    }
    .top-page-store-card-title-ja {
      margin-top: 5px;
      font-size: 13px;
      font-weight: 400;
      color: #9d9d9d;
    }
    .top-page-store-card-line {
      margin-top: 0;
      margin-bottom: 0;
      border-style: dotted;
    }
    .top-page-store-card-place {
      margin-top: 10px;
      padding-left: 20px;
      background-image: url(/assets/img/pin_icon_01.svg);
      background-size: 13px auto;
      background-repeat: no-repeat;
      background-position: left center;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
    .top-page-store-card-description {
      margin-top: 5px;
      font-size: 12px;
      font-weight: 400;
      color: #5c5c5c;
      line-height: 18px;
    }
    .top-page-store-pager {
      display: flex;
      width: 270px;
      margin: 45px auto 0;
    }
    .top-page-store-pager a {
      width: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 12px;
      display: inline-block;
      text-decoration: none;
      color: #9d9d9d;
    }
    .top-page-store-pager a.top-page-store-pager-active {
      color: #000000;
      background-color: #e6e6e6;
      border-radius: 50%;
      pointer-events: none;
    }
    .top-footer {
      position: fixed;
      bottom: 0;
      z-index: 11;
      display: block;
      width: 100%;
      height: 54px;
      background-image: url(/assets/img/display_bottom_fixed.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    .top-footer .top-footer-inner {
      position: relative;
    }
    .top-footer .top-footer-ask {
      position: absolute;
      top: -33px;
      left: 0;
      right: 0;
      width: 67px;
      height: 67px;
      margin: auto;
    }
    .top-footer .top-footer-area {
      position: absolute;
      top: 10px;
      left: 20%;
      width: 25px;
      height: 39px;
    }
    .top-footer .top-footer-couse-01 {
      position: absolute;
      top: 10px;
      right: 20%;
      width: 37px;
      height: auto;
    }
    .goto.campaign .top-page-top {
      background: url('https://s3-ap-northeast-1.amazonaws.com/files.jpneazy.com/campaign/background_pc.jpg');
    }
  }

  @media screen and (max-width: 768px) {
    header {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .top-page-header-menu {
      display: flex;
      align-items: center;
      position: fixed;
      right: 0;
      z-index: 100;
    }
    .top-page-header-menu img {
      width: 30px;
      height: 30px;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.664);
    }
    .top-page-header-menu .menu-top-block img {
      width: 100%;
      height: auto;
      padding: 0;
    }
    .slide-container {
      width: 70%;
      margin: auto;
    }
    .slide-title {
      width: 100%;
      line-height: 50px;
      font-size: 30px;
      font-family: 'Sawarabi Mincho', sans-serif;
      text-align: center;
      color: white;
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      z-index: 1;
    }
    .slide-title p {
      font-size: 12px;
    }
    .slide-sevices {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      bottom: 0;
      font-family: 'Sawarabi Mincho', sans-serif;
      z-index: 2;
    }
    .slide-sevices .go-to-eat-label {
      position: absolute;
      z-index: 1;
      background: #ff5800;
      padding: 5px;
      text-decoration: none;
      border-radius: 100px;
      color: white;
      top: 0;
      left: 5px;
      font-size: 13px;
    }
    .slide-sevices .go-to-eat-not-label {
      position: absolute;
      z-index: 1;
      background: white;
      padding: 5px;
      text-decoration: none;
      border-radius: 100px;
      color: #464646;
      top: 0;
      left: 5px;
      font-size: 13px;
    }
    .slide-sevices .slide-sevices-item {
      width: 320px;
      padding: 10px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
    }
    .slide-sevices .slide-sevices-item img {
      width: 320px;
    }
    .slide-sevices .slide-sevices-item span {
      position: absolute;
      color: black;
      bottom: 20px;
      text-decoration: none;
      border-radius: 100px;
      padding: 10px;
      font-size: 12px;
      text-align: center;
      width: 180px;
    }
    .slide-sevices .slide-sevices-item span img {
      width: 10px;
      height: 10px;
      position: absolute;
      right: 10px;
      top: 11px;
    }
    .slide-sevices .about-chefle {
      margin-top: 50px;
      width: 320px;
    }
    .slide-video::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
    .each-slide {
      padding-top: 50px;
    }
    .each-slide > div {
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
      // height: 50vh;
      width: 100%;
      background-position: center;
    }
    .each-slide span {
      padding: 20px;
      font-size: 20px;
      background: #efefef;
      text-align: center;
    }
    .each-fade {
      display: flex;
    }
    .each-fade .image-container {
      width: 75%;
      overflow: hidden;
    }
    .each-fade .image-container img {
      width: 100%;
    }
    .each-fade h2 {
      width: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      background: #adceed;
    }
    .top-page-notification-title {
      padding-left: 33px;
      font-size: 16px;
      font-size: 1.6rem;
      line-height: 2.3rem;
      font-weight: 500;
      background-image: url(/assets/img/bell_icon_03.svg);
      background-size: 22px;
      background-repeat: no-repeat;
      background-position: left center;
    }
    .top-page-notification-txt {
      margin-top: 15px;
      font-size: 14px;
      font-size: 1.4rem;
      line-height: 1.8rem;
      font-weight: 400;
    }
    .top-page-notification-close {
      position: absolute;
      top: 75px;
      right: 16px;
      width: 10px;
      height: 10px;
    }
    .top-page-top {
      position: relative;
      padding-top: 0;
    }
    .top-page-logo {
      padding: 2px;
      position: absolute;
      top: 0;
    }
    .top-page-logo img {
      width: 100px;
    }
    .user-info {
      color: white;
      position: fixed;
      top: 0;
      z-index: 2;
      right: 102px;
      width: 140px;
      padding: 7px;
      text-align: center;
      background-color: #ff5800;
    }
    .user-info li {
      padding: 2px;
    }
    .video {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 20px;
    }
    .goto-about {
      background: linear-gradient(70deg, #ff5800, #fbb430);
      padding: 0 20px 60;
    }
    .top-page-selection {
      font-family: YuGothic, sans-serif;
      background: initial;
    }
    .top-page-selection p {
      font-family: YuGothic, sans-serif;
    }
    .top-page-selection-box {
      margin-top: 10px;
      padding: 0 14px;
      width: 86%;
      display: block;
    }
    .top-page-selection-box h3 {
      padding: 20px 0 0;
      text-align: left;
      font-family: YuGothic, sans-serif;
    }
    .top-page-selection-box h3 span {
      color: #ff5800;
    }
    .top-page-selection-box p {
      padding: 10px 0;
      line-height: 20px;
      font-family: YuGothic, sans-serif;
      width: 100%;
    }
    .top-page-selection-box-img img {
      height: initial;
      width: 100%;
    }
    .top-page-selection-box-img-arrow {
      margin: 40px auto;
    }
    .top-page-selection-box-img-arrow img {
      height: initial;
    }
    .goto-howto {
      background-color: #f4f4f4;
      padding: 0 20px 84px;
    }
    .top-page-top-txt {
      margin-top: 23px;
      text-align: center;
      font-size: 14px;
      font-size: 1.4rem;
      line-height: 2.4rem;
      font-weight: 400;
      color: #5c5c5c;
    }
    .top-sarch-area {
      width: 100%;
      margin: 15px auto 0;
      position: absolute;
      z-index: 10;
    }
    .top-sarch-area .top-sarch-container {
      width: 290px;
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.16);
      border-radius: 30px;
      background-color: #ffffff;
      margin: 0 auto;
      display: block;
    }
    .top-sarch-area .top-sarch-container input {
      width: 100%;
      height: 49px;
      box-sizing: border-box;
      padding-left: 60px;
      background-image: url(/assets/img/edit_icon_03.svg);
      background-size: 13px auto;
      background-repeat: no-repeat;
      background-position: 30px center;
      border: none;
      outline: none;
      border-radius: 30px;
      font-weight: 400;
    }
    .top-sarch-area .top-sarch-container input::input-placeholder {
      color: #9d9d9d;
    }
    .top-sarch-area .top-sarch-container ul.suggest-list li {
      width: 290px;
      line-height: 40px;
      border: none;
      outline: none;
      font-size: 15px;
      font-weight: 400;
    }
    .top-sarch-area .top-sarch-container ul.suggest-list li a {
      width: 240px;
      padding-left: 30px;
      padding-right: 20px;
      line-height: 40px;
      display: inline-block;
      color: #9d9d9d;
      cursor: pointer;
    }
    .top-sarch-area .top-sarch-container ul.suggest-list li a span.area {
      background-image: url(/assets/img/pin_icon_02.svg);
      background-size: 12px auto;
      background-repeat: no-repeat;
      background-position: left center;
      padding-left: 17px;
      opacity: 0.5;
      float: right;
      color: #5c5c5c;
      font-size: 12px;
    }
    .top-sarch-area .top-sarch-container ul.suggest-list li a:hover {
      background-color: #f8f8f8;
    }
    .top-sarch-area .top-sarch-container ul.suggest-list li a:focus {
      background-color: #f8f8f8;
    }
    .top-sarch-area .top-sarch-container .search-bottom {
      width: 290px;
      height: 40px;
      box-sizing: border-box;
      border-radius: 0 0 30px 30px;
      display: inline-block;
      cursor: pointer;
    }
    .top-sarch-area .top-sarch-container .search-bottom a {
      width: 230px;
      padding-left: 60px;
      background-image: url(/assets/img/edit_icon_05.svg);
      background-size: 13px auto;
      background-repeat: no-repeat;
      background-position: 30px center;
      line-height: 40px;
      display: inline-block;
      border-radius: 0 0 30px 30px;
      color: #ff5800;
    }
    .top-sarch-area .top-sarch-container .search-bottom a:hover {
      background-color: #f8f8f8;
    }
    .top-sarch-area .top-sarch-container .search-bottom a:focus {
      background-color: #f8f8f8;
    }
    .top-page-or-line {
      position: relative;
      display: block;
      width: 30px;
      margin: 20px auto 0;
      color: #9d9d9d;
      text-align: center;
      font-size: 14px;
      font-size: 1.4rem;
      font-weight: 400;
      margin-top: 84px;
    }
    .top-page-or-line::before,
    .top-page-or-line::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      display: inline-block;
      width: 128px;
      height: 1px;
      margin: auto;
      border-top: 1px solid #e6e6e6;
    }
    .top-page-or-line::before {
      left: -130px;
    }
    .top-page-or-line::after {
      right: -130px;
    }
    .top-page-restaurant {
      background-color: #f4f4f4;
    }
    .top-page-selection-title-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 60px 0 0;
    }
    .top-page-selection-title-box h4 {
      font-size: 4vw;
      font-weight: bold;
      margin-left: 10px;
      color: #ff5800;
      font-family: 'Poppins';
    }
    .top-page-selection-title-box p {
      margin-top: 10px;
      color: #ff5800;
    }
    .top-page-selection-title {
      width: initial;
      height: initial;
      display: initial;
    }
    .top-page-selection-title img {
      width: 90px;
    }
    .top-page-restaurant-title-box {
      color: #fff;
      padding: 50px 0 0;
      position: relative;
      display: block;
      text-align: center;
      margin: 0 auto;
      width: 100%;
    }
    .top-page-restaurant-title-box:before {
      width: 10vw;
      height: 100%;
      background: url(/assets/img/gotoeat/goto-icon.png) no-repeat 0 0;
      background-size: 100%;
      position: absolute;
      left: 5%;
      top: 36px;
      content: '';
    }
    .top-page-restaurant-title-box h3 {
      font-family: 'Poppins';
      font-weight: bold;
      font-size: 4vw;
    }
    .top-page-restaurant-title-box p {
      margin-top: 10px;
    }
    .thoughts-about-the-chefle {
      line-height: 24px;
      font-size: 14px;
      text-align: left;
      padding: 40px 5px 50px;
      color: #fff;
      font-weight: bold;
    }
    .thoughts-about-the-chefle p {
      font-size: 10px;
      margin-top: 20px;
      font-family: 'Sawarabi Mincho', sans-serif;
    }
    .top-page-goto-box {
      width: 100%;
      margin: 0 auto;
      background-color: #f4f4f4;
      padding: 30px 0;
      border-radius: 8px;
      box-shadow: 0px 9px 32px #ff5800;
    }
    .top-page-goto-box-title {
      text-align: center;
      margin-bottom: 27px;
      color: #ff5800;
    }
    .top-page-goto-box-title-top {
      font-size: 14px;
      margin-bottom: 12px;
    }
    .top-page-goto-box-title-middle {
      font-size: 4vw;
      font-weight: bold;
      margin-bottom: 26px;
      font-family: 'Poppins' !important;
    }
    .top-page-goto-box-title-bottom {
      display: inline-block;
      padding: 8px 36px;
      background-color: #ff5800;
      color: #fff;
      letter-spacing: 3px;
      font-weight: bold;
      font-size: 4vw;
    }
    .top-page-goto-box-text {
      margin: 0 45px 27px;
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
    }

    .top-page-goto-box-campaign {
      width: 700px;
      margin: 0 auto 37px;
      font-size: 15px;
      font-weight: bold;
      text-decoration: line-through double #ff5800;
    }

    .top-page-goto-campaign-date {
      position: relative;
      text-decoration: line-through double #ff5800;
    }

    .top-page-goto-campaign-date-end {
      position: absolute;
      width: 100px;
      top: 13px;
      left: 0px;
      color: #ff5800;
    }

    .top-page-goto-text-point {
      font-size: 12px;
      line-height: 1.4;
      margin-left: 10px;
    }

    .top-page-goto-text-description {
      color: #333 !important;
      font-weight: normal !important;
    }

    .top-page-goto-box-box {
      display: block;
      width: 94%;
      margin: 0 auto 37px;
    }
    .top-page-goto-box-box-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 70%;
      padding: 16px;
      border: solid #ff5800;
      background-color: #fff;
      border-radius: 3px;
      margin: 0 auto 20px;
    }
    .top-page-goto-box-box-container-check {
      margin-left: 5px;
    }
    .top-page-goto-box-box-container-check img {
      width: 100%;
      height: initial;
    }
    .top-page-goto-box-box-container-text {
      width: 84%;
      font-weight: bold;
    }
    .top-page-goto-box-box-container-text-top {
      margin: 10px 0 20px;
      font-size: 12px;
    }
    .top-page-goto-box-box-container-text-bottom {
      padding: 10px;
      background: #f4f4f4;
      font-size: 12px;
      text-align: center;
    }
    .top-page-goto-box-box-container-text-bottom span {
      font-size: 5vw;
      color: #ff5800;
    }
    .top-page-goto-box-note {
      font-size: 10px;
      color: #ff5800;
      text-align: left;
      line-height: 20px;
      width: 70%;
      margin: 0 auto;
    }
    .br-sp {
      display: block;
    }
    .top-page-goto-box-howto {
      font-size: 14px;
      background-color: #fff;
      padding: 20px;
      margin-bottom: 65px;
      border-radius: 3px;
    }
    .top-page-goto-box-howto-title {
      font-weight: bold;
      margin-bottom: 33px;
      text-align: center;
    }
    .top-page-goto-box-howto-note {
      line-height: 24px;
    }
    .top-page-goto-box-howto-note span {
      color: #ff5800;
      font-weight: bold;
    }
    .top-page-goto-box-button-agri {
      display: block;
      margin: 0 auto;
      border: solid 2px;
      padding: 24px 0;
      width: 100%;
      text-decoration: none;
      color: #000;
      font-weight: bold;
      font-family: YuGothic, sans-serif;
      text-align: center;
    }

    .top-page-goto-box-button-top {
      display: block;
      margin: 0 auto;
      border: solid 2px orange;
      background-color: orange;
      color: #fff;
      padding: 24px 0;
      width: 100%;
      text-decoration: none;
      font-weight: bold;
      font-family: YuGothic, sans-serif;
      margin-bottom: 20px;
      text-align: center;
    }

    .top-page-goto-box-buttons {
      display: flex;
      flex-direction: column;
    }

    .top-page-selection-txt {
      margin-top: 20px;
      font-size: 12px;
      font-size: 1.2rem;
      color: #5c5c5c;
      text-align: center;
      line-height: 2rem;
      font-weight: 400;
    }
    .top-page-selection-head {
      margin-bottom: 44px;
    }
    .top-page-selection-head-eat-link {
      display: flex;
      justify-content: center;
      padding: 30px 0;
    }
    .top-page-selection-head-eat-link p {
      position: relative;
      padding-left: 30px;
      padding-right: 20px;
      font-size: 16px;
      line-height: 20px;
      color: #5c5c5c;
      font-size: 14px;
    }
    .top-page-selection-head-eat-link p::after {
      content: '>';
      position: absolute;
      right: 0;
      display: inline-block;
      margin: auto;
      transform: rotate(90deg);
    }
    .goto
      .top-page-selection-head-eat-link
      p.top-page-selection-head-where-eat {
      background-image: url(/assets/img/pin_icon_04.svg);
      background-size: 15px auto;
      background-repeat: no-repeat;
      background-position: left center;
    }
    .top-page-selection-head-eat-link p.top-page-selection-head-what-eat {
      margin-left: 20px;
      background-image: url(/assets/img/eat_icon_02.svg);
      background-size: 18px auto;
      background-repeat: no-repeat;
      background-position: left center;
    }
    .top-page-selection-head-eat-link a {
      text-decoration: none;
      color: #5c5c5c;
    }
    .top-page-store-area {
      margin-top: 10px;
      padding-bottom: 27px;
    }
    .top-page-sort-restaurant-btn {
      color: #5c5c5c;
      display: flex;
      justify-content: center;
    }
    .top-page-sort-restaurant-btn p {
      margin: 15px 20px;
    }
    .top-page-sort-restaurant-btn-active {
      color: #ff5800;
    }
    .top-page-stores-title {
      padding: 30px 14px 0px 14px;
      color: #5c5c5c;
      font-size: 22px;
    }
    .top-page-stores-description {
      padding: 20px 14px 0px;
      color: #5c5c5c;
      line-height: 2rem;
    }
    .top-page-store-tab-container {
      background-color: #e6e6ee;
      color: #5c5c5c;
    }
    .top-page-store-tab {
      display: flex;
      justify-content: center;
    }
    .top-page-store-tab li {
      margin-top: 14px;
      margin-bottom: 14px;
      padding: 8px 15px;
      border-radius: 30px;
      box-sizing: border-box;
      cursor: pointer;
    }
    .top-page-store-tab li + li {
      margin-left: 15px;
    }
    .top-page-store-active {
      background-color: #ff5800;
      color: #fff;
    }
    .top-page-store-card-container {
      margin-top: 47px;
      margin-left: 14px;
      margin-right: 14px;
      box-sizing: border-box;
    }
    .top-page-store-card-box {
      position: relative;
      padding-bottom: 10px;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
    }
    .top-page-store-card-box::after {
      position: absolute;
      top: 5px;
      right: 5px;
      padding: 3px;
      border-radius: 4px;
      color: #ffffff;
    }
    .top-page-store-card-box + li {
      margin-top: 37px;
    }
    .top-page-store-card-menu::after {
      content: 'MENU';
      position: absolute;
      top: 5px;
      right: 5px;
      padding: 3px;
      background-color: #ff5800;
      border-radius: 4px;
      color: #ffffff;
    }
    .top-page-store-card-seat::after {
      content: 'SEAT';
      background-color: rgba(244, 244, 244, 0.72);
      color: #000;
    }
    .top-page-store-card-title-box {
      position: relative;
      margin-top: 10px;
      margin-left: 10px;
      margin-right: 10px;
      padding-left: 50px;
    }
    .top-page-store-card-person {
      position: absolute;
      top: 0;
      left: 0;
      width: 32px;
      height: 32px;
    }
    .top-page-store-card-title-en {
      font-size: 14px;
      font-size: 1.4rem;
      font-weight: 500;
    }
    .top-page-store-card-title-ja {
      margin-top: 5px;
      font-size: 13px;
      font-size: 1.3rem;
      font-weight: 400;
      color: #9d9d9d;
    }
    .top-page-store-card-line {
      margin-top: 0;
      border-style: dotted;
    }
    .top-page-store-card-place {
      padding-left: 20px;
      background-image: url(/assets/img/pin_icon_01.svg);
      background-size: 13px auto;
      background-repeat: no-repeat;
      background-position: left center;
      font-size: 12px;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 2rem;
    }
    .top-page-store-card-description {
      margin-top: 5px;
      font-size: 12px;
      font-size: 1.2rem;
      font-weight: 400;
      color: #5c5c5c;
      line-height: 1.8rem;
    }
    .top-page-store-pager {
      display: flex;
      width: 290px;
      margin: 45px auto 0;
    }
    .top-page-store-pager a {
      width: 30px;
      line-height: 3rem;
      text-align: center;
      font-size: 12px;
      font-size: 1.2rem;
      display: inline-block;
      text-decoration: none;
      color: #9d9d9d;
    }
    .top-page-store-pager a.top-page-store-pager-active {
      color: #000000;
      background-color: #e6e6e6;
      border-radius: 50%;
    }
    .goto.campaign main {
      background: url('https://s3-ap-northeast-1.amazonaws.com/files.jpneazy.com/campaign/background_sp.jpg');
    }
  }
}
