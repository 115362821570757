h1,
h2,
h3,
p,
a,
input,
select,
ul,
li,
dl,
dt,
dd,
label {
  font-family: 'Poppins';
}
li {
  list-style-type: none;
}

// 変数
$font-light: 300;
$font-normal: 400;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;

// float解除
@mixin float() {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

//  iOS CSSリセット

input[type='submit'],
input[type='button'] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  &::-webkit-search-decoration {
    display: none;
  }
  &::focus {
    outline-offset: -2px;
  }
}
select,
input {
  // appearance: none;
}

//PC,SP出し分け用
.pc {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}
.sp {
  @media screen and (min-width: 769px) {
    display: none !important;
  }
}
// =====================================
// PC
// =====================================

@media screen and (min-width: 769px) {
  body {
    min-width: 1280px;
  }
  .sign-in-btn {
    margin: 10px auto;
    display: block;
    width: 375px;
    background-size: 22px;
    background-repeat: no-repeat;
    background-position: 87px center;
    border-radius: 8px;
    line-height: 54px;
    text-align: center;
    font-size: 16px;
    text-decoration: none;
    font-weight: 400;
  }
  .sign-in-btn-o {
    background-color: #ff5800;
    color: #fff;
  }
  .sign-in-btn-w {
    background-color: #fff;

    color: #ff5800;
    border: solid 2px #ff5800;
    box-sizing: border-box;
  }

  // パンくず
  .breadcrumb {
    position: relative;
    justify-content: space-between;
    padding: 13px 0;
    margin-top: 30px;
    li {
      display: inline-block;
      font-size: 14px;
      color: #9d9d9d;
      &::after {
        content: '>';
        margin: 0 3px;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
      & a {
        color: #9d9d9d;
      }
    }
  }

  // レストラン評価
  .evaluation-detail {
    margin-top: 20px;
    padding-left: 14px;
    padding-right: 14px;

    .evaluation-detail-container {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      font-weight: $font-normal;
      padding-bottom: 3px;
    }
    .evaluation-detail-txt {
      color: #9d9d9d;
      line-height: 20px;
    }
    // 円
    .evaluation-detail-yen {
      display: inline-block;
      margin-right: 5px;
      li {
        display: inline-block;
      }
      &.evaluation-detail-yen-01 {
        li:nth-child(n + 2) {
          color: #e6e6e6;
        }
      }
      &.evaluation-detail-yen-02 {
        li:last-child {
          color: #e6e6e6;
        }
      }
      &.evaluation-detail-yen-03 {
        li {
          color: #9d9d9d;
        }
      }
    }
    // 星
    .evaluation-detail-star {
      li {
        display: inline-block;
        color: #ff5800;
      }
      &.evaluation-detail-star-01 {
        li:nth-child(n + 2) {
          color: #e6e6e6;
        }
      }
      &.evaluation-detail-star-02 {
        li:nth-child(n + 3) {
          color: #e6e6e6;
        }
      }
      &.evaluation-detail-star-03 {
        li:nth-child(n + 4) {
          color: #e6e6e6;
        }
      }
      &.evaluation-detail-star-04 {
        li:last-child {
          color: #e6e6e6;
        }
      }
      &.evaluation-detail-star-05 {
        li:last-child {
          color: #ff5800;
        }
      }
    }
  }

  // フッター
  .common-footer {
    background-color: #464646;
    height: 440px;
    color: white;
    .footer-inner {
      display: flex;
      width: 1025px;
      margin: 0 auto;
      .footer-inner-box {
        width: 320px;
        margin: 50px 10px 10px;
        h4 {
          padding: 10px 0;
          border-bottom: solid 1px;
        }
        ul {
          padding-top: 30px;
        }
        li {
          display: flex;
          align-items: center;
          margin-top: 10px;
          font-size: 13px;
          cursor: pointer;
          a {
            text-decoration: none;
            color: white;
          }
        }
      }
    }
    .footer-logo {
      width: 1025px;
      height: 130px;
      margin: 30px auto 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      img {
        width: 180px;
      }
      p {
        padding: 10px 0;
        font-size: 12px;
      }
    }

    .footer-company-tel {
      font-size: 12px;
    }

    .footer-bottom {
      width: 1025px;
      display: flex;
      justify-content: flex-end;
      margin: 0 auto;
      font-size: 12px;
      p {
        margin-left: 10px;
        cursor: pointer;
      }
    }

    .footer-eat {
      display: flex;
      justify-content: space-between;
    }
    .footer-where-eat {
      width: 50%;
      padding-right: 55px;
      border-right: 1px solid #707070;
    }
    .footer-what-eat {
      width: 50%;
      padding-left: 55px;
    }
    .footer-where-eat-head {
      padding-left: 44px;
      background-image: url(/assets/img/map_icon_01.svg);
      background-size: 38px auto;
      background-repeat: no-repeat;
      background-position: left center;
      line-height: 38px;
      color: #ff5800;
      font-size: 14px;
      font-weight: $font-normal;
    }
    .footer-where-eat-link {
      display: flex;
      flex-wrap: wrap;
      margin-top: 15px;
      margin-left: -55px;
      margin-bottom: -15px;
      li {
        margin-bottom: 15px;
        margin-left: 55px;
        font-size: 12px;
        font-weight: $font-normal;
      }
      a {
        text-decoration: none;
        color: #707070;
      }
    }
    .footer-what-eat-head {
      padding-left: 33px;
      background-image: url(/assets/img/eat_icon_03.svg);
      background-size: 29px auto;
      background-repeat: no-repeat;
      background-position: left center;
      line-height: 38px;
      color: #ff5800;
      font-size: 14px;
      font-weight: $font-normal;
    }
    .footer-what-eat-link {
      display: flex;
      flex-wrap: wrap;
      margin-top: 15px;
      margin-left: -55px;
      margin-bottom: -15px;
      li {
        margin-bottom: 15px;
        margin-left: 55px;
        font-size: 12px;
        font-weight: $font-normal;
      }
      a {
        text-decoration: none;
        color: #707070;
      }
    }
    .footer-logo-text {
      margin-right: 45px;
    }
    .footer-link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      li {
        font-weight: $font-normal;
        font-size: 14px;
        & + li {
          margin-left: 30px;
        }
        a {
          color: #ff5800;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
    .footer-company {
      color: #9d9d9d;
      font-size: 13px;
      margin-top: 14px;
      li {
        margin-top: 5px;
      }
    }
    .footer-copy-right {
      margin-top: 14px;
      color: #9d9d9d;
      font-size: 13px;
      font-weight: $font-normal;
    }
    .footer-bottom-link {
      display: flex;
      margin-top: 5px;
      li {
        &:first-child {
          &::after {
            content: '|';
            margin-left: 3px;
            margin-right: 3px;
            color: #4895e8;
            font-size: 12px;
          }
        }
        a {
          font-size: 13px;
          text-decoration: none;
          color: #4895e8;
        }
      }
    }
  }
  // メニュー
  .html-fix {
    overflow: hidden;
    width: 100%;
  }
  .menu-container {
    overflow: scroll;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: #000;
    #menu-close {
      position: absolute;
      top: 25px;
      left: 36px;
      display: block;
      width: 14px;
      height: auto;
    }
    .menu-container-inner {
      position: relative;
      width: 1025px;
      margin: 0 auto;
      padding-top: 96px;
      padding-bottom: 50px;
      padding-left: 43px;
      padding-right: 28px;
      .menu-top-block {
        li {
          background-repeat: no-repeat;
          background-position: left center;
          border: solid 1px white;
          img {
            width: 100%;
          }

          &.menu-home {
            background-image: url(/assets/img/home_icon_02.svg);
            background-size: 19px;
          }
          &.menu-concierge {
            background-image: url(/assets/img/balloon_icon_01.svg);
            background-size: 18px;
          }
          &.menu-seat {
            background-image: url(/assets/img/edit_icon_04.svg);
            background-size: 14px;
          }
          &.menu-where-eat {
            background-image: url(/assets/img/pin_icon_05.svg);
            background-size: 13px;
          }
          &.menu-what-eat {
            background-image: url(/assets/img/eat_icon_04.svg);
            background-size: 20px;
          }
          &.menu-eat {
            &::after {
              content: '>';
              display: inline-block;
              margin-left: 10px;
              transform: rotate(90deg);
              color: #ffffff;
            }
          }
          &.menu-faq {
            background-image: url(/assets/img/qa_icon_01.svg);
            background-size: 19px;
          }
          &.menu-langage {
            background-image: url(/assets/img/language_icon_01.svg);
            background-size: 16px;
          }
          &.menu-howto {
            background-image: url(/assets/img/howto_icon_01.svg);
            background-size: 22px;
          }
          &.menu-about {
            padding-left: 0;
          }
          a {
            display: inline-block;
            color: #fff;
            font-size: 16px;
            font-weight: $font-normal;
            line-height: 23px;
            text-decoration: none;
          }
        }
      }
      .menu-under-block {
        display: flex;
        width: 100%;
        color: #ff5800;

        .menu-under-block-btn {
          width: 50%;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 80px;
          border: solid 1px white;
        }
      }
    }
  }
  .menu-move-open {
    display: block;
    right: 0;
    animation: move-open 0.3s ease;
  }
  @keyframes move-open {
    0% {
      right: -100%;
    }
    100% {
      right: 0;
    }
  }

  .menu-move-close {
    right: -100%;
    animation: move-close 0.3s ease;
  }
  @keyframes move-close {
    0% {
      right: 0;
    }
    100% {
      right: -100%;
      display: none;
    }
  }
  // モーダルコンテナ
  .slide-modal {
    position: fixed;
    top: 115vh;
    // top: 0;
    z-index: 100;
    width: 100%;
    height: 100vh;
    .slide-modal-inner {
      position: relative;
      z-index: 10;
      width: 900px;
      margin: 0 auto;
    }
    .slide-modal-close {
      position: absolute;
      top: 20px;
      right: 0;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
  .modal-back {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .slide-modal-contents {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    max-width: 900px;
    box-sizing: border-box;
    max-height: 90vh;
    overflow: scroll;
    margin: auto;
    border-radius: 18px;
    padding-top: 25px;
    padding-bottom: 50px;
    padding-left: 14px;
    padding-right: 14px;
    background-color: #ffffff;
  }
  .how-to-use-title {
    padding-bottom: 10px;
    padding-left: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #ccc;
    background-image: url(/assets/img/how_to_qa_icon_01.svg);
    background-repeat: no-repeat;
    background-size: 22px auto;
    background-position: 295px 0;
    text-align: center;
    color: #ff5800;
    line-height: 26px;
    font-size: 16px;
  }
  //How To Use
  .how-to-step {
    margin-top: 54px;
    li {
      text-align: center;
      color: #5c5c5c;
      font-size: 14px;
      + li {
        padding-top: 65px;
        background-image: url(/assets/img/how_to_item_01.svg);
        background-repeat: no-repeat;
        background-size: auto 38px;
        background-position: center 20px;
      }
      p {
        margin-top: 10px;
      }
    }
    .how-to-step1 {
      img {
        width: 32px;
        height: auto;
      }
    }
    .how-to-step2 {
      img {
        width: 171px;
        height: auto;
      }
    }
    .how-to-step3 {
      img {
        width: 125px;
        height: auto;
      }
    }
  }
  .how-to-notes {
    width: 420px;
    margin: 20px auto 0;
    padding-left: 25px;
    box-sizing: border-box;
    background-image: url(/assets/img/bell_icon_03.svg);
    background-repeat: no-repeat;
    background-size: 21px;
    background-position: left 0;
    color: #ff5800;
    font-size: 12px;
  }
  .slide-in-modal {
    display: block;
    top: 0vh;
    animation: move-slide-in 0.3s ease;
  }
  @keyframes move-slide-in {
    0% {
      top: 115vh;
    }
    100% {
      top: 0vh;
    }
  }
  .slide-out-modal {
    top: 115vh;
    animation: move-slide-out 0.3s ease;
  }
  @keyframes move-slide-out {
    0% {
      top: 0vh;
    }
    100% {
      top: 115vh;
      display: none;
    }
  }
  // to eat モーダル
  .to-eat-modal-head {
    margin-bottom: 8px;
    padding-left: 45px;
    border-bottom: 1px solid #ccc;
    font-size: 16px;
    line-height: 40px;
    color: #ff5800;
    &.where-to-eat-head {
      background-image: url(/assets/img/where_to_modal_item_01.svg);
      background-repeat: no-repeat;
      background-size: 38px;
      background-position: left 0;
    }
    &.what-to-eat-head {
      background-image: url(/assets/img/what_to_modal_item_01.svg);
      background-repeat: no-repeat;
      background-size: 29px;
      background-position: left 0;
    }
  }
  .to-eat-modal-list {
    label {
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    input[type='radio'] {
      appearance: radio;
      width: 15px;
      height: 15px;
    }
    li {
      padding-right: 15px;
      font-size: 16px;
      color: #707070;
      & + li {
        border-top: 1px solid #e6e6e6;
      }
    }
    input[type='radio'] {
      display: none;
    }
    .radio {
      box-sizing: border-box;
      -webkit-transition: background-color 0.2s linear;
      transition: background-color 0.2s linear;
      position: relative;
      padding: 20px 0 20px 0;
      vertical-align: middle;
      cursor: pointer;
    }
    .radio:after {
      -webkit-transition: border-color 0.2s linear;
      transition: border-color 0.2s linear;
      position: absolute;
      top: 50%;
      right: 0;
      display: block;
      margin-top: -10px;
      width: 18px;
      height: 18px;
      border: 1px solid #bbb;
      border-radius: 18px;
      content: '';
    }
    .radio:before {
      -webkit-transition: opacity 0.2s linear;
      transition: opacity 0.2s linear;
      position: absolute;
      top: 50%;
      right: 5px;
      display: block;
      margin-top: -5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #ff5800;
      content: '';
      opacity: 0;
    }
    input[type='radio']:checked + .radio:before {
      opacity: 1;
    }
  }

  .chat-modal {
    position: relative;
  }

  .chat-modal-close-button {
    padding: 10px;
    border: none;
    position: absolute;
    top: -25px;
    right: 0;
    background: lightgray;
  }

  .chat-modal-close-button:hover {
    cursor: pointer;
  }

  .chat-modal-top {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-top: 20px;
  }

  .chat-modal-top-text {
    color: lightgray;
    font-size: 30px;
    margin-left: 10px;
  }

  .chat-modal-icon {
    width: 110px;
  }

  .chat-modal-head-text {
    font-size: 20px;
    font-family: 'Sawarabi Mincho';
    text-align: center;
    font-weight: bold;
    margin: 20px 0;
    line-height: 1.4;
  }

  .chat-modal-content {
    text-align: center;
    margin: 30px 100px;
    line-height: 1.8;
  }

  .chat-modal-qr {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .chat-modal-qr-image {
    width: 150px;
  }

  .chat-modal-qr-text {
    text-align: center;
    line-height: 1.4;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
  }
}

.font {
  font-family: 'Sawarabi Mincho' !important;
}

// =====================================
// SP
// =====================================

@media screen and (max-width: 768px) {
  img {
    width: 100%;
    height: 100%;
  }
  html {
    font-size: 62.5%;
  }
  body {
    font-size: 1.4rem;
    background-color: #f4f4f4;
  }

  // メニュー
  .html-fix {
    overflow: hidden;
    width: 100%;
  }
  .sign-in-btn {
    margin: 10px auto;
    display: block;
    background-size: 22px;
    background-repeat: no-repeat;
    background-position: 87px center;
    border-radius: 8px;
    line-height: 54px;
    text-align: center;
    font-size: 16px;
    text-decoration: none;
    font-weight: 400;
  }
  .sign-in-btn-o {
    background-color: #ff5800;
    color: #fff;
  }
  .sign-in-btn-w {
    background-color: #fff;

    color: #ff5800;
    border: solid 2px #ff5800;
    box-sizing: border-box;
  }

  .menu-icon {
    margin-left: 1px;
  }
  .menu-container {
    z-index: 100;
    overflow: scroll;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: #000;
    .menu-close-btn {
      position: absolute;
      top: 0;
      right: 35px;
      width: 14px;
      height: auto;
    }
    .menu-container-inner {
      padding-top: 60px;
      z-index: 100;
      // padding-bottom: 50px;
      // padding-left: 43px;
      // padding-right: 28px;
      .menu-top-block {
        li {
          border: solid 1px white;
          background-repeat: no-repeat;
          background-position: left center;
          img {
            width: 100%;
          }
          &.menu-home {
            background-image: url(/assets/img/home_icon_02.svg);
            background-size: 19px;
          }
          &.menu-concierge {
            background-image: url(/assets/img/balloon_icon_01.svg);
            background-size: 18px;
          }
          &.menu-seat {
            background-image: url(/assets/img/edit_icon_04.svg);
            background-size: 14px;
          }
          &.menu-where-eat {
            background-image: url(/assets/img/pin_icon_05.svg);
            background-size: 13px;
          }
          &.menu-what-eat {
            background-image: url(/assets/img/eat_icon_04.svg);
            background-size: 20px;
          }
          &.menu-eat {
            &::after {
              content: '>';
              display: inline-block;
              margin-left: 10px;
              transform: rotate(90deg);
              color: #ffffff;
            }
          }
          &.menu-faq {
            background-image: url(/assets/img/qa_icon_01.svg);
            background-size: 19px;
          }
          &.menu-langage {
            background-image: url(/assets/img/language_icon_01.svg);
            background-size: 16px;
          }
          &.menu-howto {
            background-image: url(/assets/img/howto_icon_01.svg);
            background-size: 22px;
          }
          &.menu-about {
            padding-left: 0;
          }
          a {
            display: inline-block;
            color: #fff;
            font-size: 14px;
            font-size: 1.4rem;
            font-weight: $font-normal;
            line-height: 2.3rem;
            text-decoration: none;
          }
        }
      }
      .menu-under-block {
        li {
          background-repeat: no-repeat;
          background-position: left center;
          + li {
            margin-top: 35px;
          }
          a {
            display: inline-block;
            color: #fff;
            font-size: 12px;
            font-size: 1.2rem;
            font-weight: $font-normal;
            line-height: 2.3rem;
            text-decoration: none;
          }
        }
      }
      .menu-line {
        margin: 0;
        margin-top: 35px;
        margin-bottom: 35px;
      }
    }
  }
  .menu-move-open {
    display: block;
    right: 0;
    animation: move-open 0.3s ease;
  }
  @keyframes move-open {
    0% {
      right: -100%;
    }
    100% {
      right: 0;
    }
  }

  .menu-move-close {
    right: -100%;
    animation: move-close 0.3s ease;
  }
  @keyframes move-close {
    0% {
      right: 0;
    }
    100% {
      right: -100%;
      display: none;
    }
  }

  // 在宅レストランサービスとは
  .about-chefle-container {
    overflow: scroll;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: #f4f4f4;
    .menu-close-btn {
      img {
        position: absolute;
        top: 0;
        width: 30px;
        height: 30px;
        padding: 10px;
        height: auto;
        background-color: rgba(0, 0, 0, 0.664);
      }
    }
    .menu-container-inner {
      padding-top: 60px;
      .menu-top-block {
        li {
          border: solid 1px white;
          background-repeat: no-repeat;
          background-position: left center;
          img {
            width: 100%;
          }
          &.menu-home {
            background-image: url(/assets/img/home_icon_02.svg);
            background-size: 19px;
          }
          &.menu-concierge {
            background-image: url(/assets/img/balloon_icon_01.svg);
            background-size: 18px;
          }
          &.menu-seat {
            background-image: url(/assets/img/edit_icon_04.svg);
            background-size: 14px;
          }
          &.menu-where-eat {
            background-image: url(/assets/img/pin_icon_05.svg);
            background-size: 13px;
          }
          &.menu-what-eat {
            background-image: url(/assets/img/eat_icon_04.svg);
            background-size: 20px;
          }
          &.menu-eat {
            &::after {
              content: '>';
              display: inline-block;
              margin-left: 10px;
              transform: rotate(90deg);
              color: #ffffff;
            }
          }
          &.menu-faq {
            background-image: url(/assets/img/qa_icon_01.svg);
            background-size: 19px;
          }
          &.menu-langage {
            background-image: url(/assets/img/language_icon_01.svg);
            background-size: 16px;
          }
          &.menu-howto {
            background-image: url(/assets/img/howto_icon_01.svg);
            background-size: 22px;
          }
          &.menu-about {
            padding-left: 0;
          }
          a {
            display: inline-block;
            color: #fff;
            font-size: 14px;
            font-size: 1.4rem;
            font-weight: $font-normal;
            line-height: 2.3rem;
            text-decoration: none;
          }
        }
      }
      .menu-under-block {
        li {
          background-repeat: no-repeat;
          background-position: left center;
          + li {
            margin-top: 35px;
          }
          a {
            display: inline-block;
            color: #fff;
            font-size: 12px;
            font-size: 1.2rem;
            font-weight: $font-normal;
            line-height: 2.3rem;
            text-decoration: none;
          }
        }
      }
      .menu-line {
        margin: 0;
        margin-top: 35px;
        margin-bottom: 35px;
      }
    }
  }
  .menu-move-open {
    display: block;
    right: 0;
    animation: move-open 0.3s ease;
  }
  @keyframes move-open {
    0% {
      right: -100%;
    }
    100% {
      right: 0;
    }
  }

  .menu-move-close {
    right: -100%;
    animation: move-close 0.3s ease;
  }
  @keyframes move-close {
    0% {
      right: 0;
    }
    100% {
      right: -100%;
      display: none;
    }
  }

  //パンくず
  .breadcrumb {
    position: relative;
    justify-content: space-between;
    padding: 13px 0;
    padding-top: 30px;
    li {
      display: inline-block;
      font-size: 14px;
      color: #9d9d9d;
      &::after {
        content: '>';
        margin: 0 3px;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
      & a {
        color: #9d9d9d;
      }
    }
  }

  // フッター
  .common-footer {
    background-color: #464545;
    color: white;
    min-height: 850px;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 80px;
    .footer-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      // margin: 0 auto;
      .footer-inner-box {
        width: 100%;
        margin: 20px 0;
        h4 {
          padding: 10px 0;
          border-bottom: solid 1px;
        }
        ul {
          padding-top: 15px;
        }
        li {
          display: flex;
          align-items: center;
          margin-top: 10px;
          font-size: 13px;
          a {
            text-decoration: none;
            color: white;
          }
        }
      }
    }
    .footer-logo {
      margin: 50px auto 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 180px;
        margin-top: 10px;
      }
      p {
        padding: 10px 0;
        font-size: 12px;
      }
    }

    .footer-company-tel {
      font-size: 12px;
    }

    .footer-bottom {
      display: flex;
      flex-direction: column;
      margin: 10px auto;
      font-size: 12px;
      p {
        padding: 10px;
        // margin-left: 10px;
      }
    }

    .footer-eat {
      display: flex;
      justify-content: space-between;
    }
    .footer-where-eat {
      width: 50%;
      padding-right: 55px;
      border-right: 1px solid #707070;
    }
    .footer-what-eat {
      width: 50%;
      padding-left: 55px;
    }
    .footer-where-eat-head {
      padding-left: 44px;
      background-image: url(/assets/img/map_icon_01.svg);
      background-size: 38px auto;
      background-repeat: no-repeat;
      background-position: left center;
      line-height: 38px;
      color: #ff5800;
      font-size: 14px;
      font-weight: $font-normal;
    }
    .footer-where-eat-link {
      display: flex;
      flex-wrap: wrap;
      margin-top: 15px;
      margin-left: -55px;
      margin-bottom: -15px;
      li {
        margin-bottom: 15px;
        margin-left: 55px;
        font-size: 12px;
        font-weight: $font-normal;
      }
      a {
        text-decoration: none;
        color: #707070;
      }
    }
    .footer-what-eat-head {
      padding-left: 33px;
      background-image: url(/assets/img/eat_icon_03.svg);
      background-size: 29px auto;
      background-repeat: no-repeat;
      background-position: left center;
      line-height: 38px;
      color: #ff5800;
      font-size: 14px;
      font-weight: $font-normal;
    }
    .footer-what-eat-link {
      display: flex;
      flex-wrap: wrap;
      margin-top: 15px;
      margin-left: -55px;
      margin-bottom: -15px;
      li {
        margin-bottom: 15px;
        margin-left: 55px;
        font-size: 12px;
        font-weight: $font-normal;
      }
      a {
        text-decoration: none;
        color: #707070;
      }
    }
    .footer-logo-text {
      margin-right: 45px;
    }
    .footer-link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      li {
        font-weight: $font-normal;
        font-size: 14px;
        & + li {
          margin-left: 30px;
        }
        a {
          color: #ff5800;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
    .footer-company {
      color: #9d9d9d;
      font-size: 13px;
      margin-top: 14px;
      li {
        margin-top: 5px;
      }
    }
    .footer-copy-right {
      margin-top: 14px;
      color: #9d9d9d;
      font-size: 13px;
      font-weight: $font-normal;
    }
    .footer-bottom-link {
      display: flex;
      margin-top: 5px;
      li {
        &:first-child {
          &::after {
            content: '|';
            margin-left: 3px;
            margin-right: 3px;
            color: #4895e8;
            font-size: 12px;
          }
        }
        a {
          font-size: 13px;
          text-decoration: none;
          color: #4895e8;
        }
      }
    }
  }

  // レストラン評価
  .evaluation-detail {
    padding-top: 15px;
    padding-left: 14px;
    padding-right: 14px;

    .evaluation-detail-container {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      font-size: 1.2rem;
      font-weight: $font-normal;
    }
    .evaluation-detail-txt {
      color: #9d9d9d;
      line-height: 2rem;
    }
    // 円
    .evaluation-detail-yen {
      display: inline-block;
      margin-right: 5px;
      li {
        display: inline-block;
      }
      &.evaluation-detail-yen-01 {
        li:nth-child(n + 2) {
          color: #e6e6e6;
        }
      }
      &.evaluation-detail-yen-02 {
        li:last-child {
          color: #e6e6e6;
        }
      }
      &.evaluation-detail-yen-03 {
        li {
          color: #9d9d9d;
        }
      }
    }
    // 星
    .evaluation-detail-star {
      li {
        display: inline-block;
        color: #ff5800;
      }
      &.evaluation-detail-star-01 {
        li:nth-child(n + 2) {
          color: #e6e6e6;
        }
      }
      &.evaluation-detail-star-02 {
        li:nth-child(n + 3) {
          color: #e6e6e6;
        }
      }
      &.evaluation-detail-star-03 {
        li:nth-child(n + 4) {
          color: #e6e6e6;
        }
      }
      &.evaluation-detail-star-04 {
        li:last-child {
          color: #e6e6e6;
        }
      }
      &.evaluation-detail-star-05 {
        li:last-child {
          color: #ff5800;
        }
      }
    }
  }
  // モーダルコンテンツ
  .slide-modal {
    position: fixed;
    top: 115vh;
    // top: 0;
    z-index: 100;
    width: 100%;
    height: 100vh;
    .slide-modal-inner {
      position: relative;
      z-index: 10;
    }
    .slide-modal-close {
      position: absolute;
      top: 20px;
      right: 30px;
      width: 14px;
      height: 14px;
    }
  }
  .modal-back {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .slide-modal-contents {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    width: 96%;
    max-height: 87vh;
    margin: auto;
    padding-top: 25px;
    padding-bottom: 50px;
    padding-left: 14px;
    padding-right: 14px;
    border-radius: 18px;
    box-sizing: border-box;
    overflow: scroll;
    background-color: #ffffff;
  }
  .slide-in-modal {
    display: block;
    top: 0vh;
    animation: move-slide-in 0.3s ease;
  }
  @keyframes move-slide-in {
    0% {
      top: 115vh;
    }
    100% {
      top: 0vh;
    }
  }

  .slide-out-modal {
    top: 115vh;
    animation: move-slide-out 0.3s ease;
  }
  @keyframes move-slide-out {
    0% {
      top: 0vh;
    }
    100% {
      top: 115vh;
      display: none;
    }
  }
  //How To Use
  .how-to-use-title {
    padding-bottom: 10px;
    padding-left: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #ccc;
    background-image: url(/assets/img/how_to_qa_icon_01.svg);
    background-repeat: no-repeat;
    background-size: 22px auto;
    text-align: center;
    color: #ff5800;
    line-height: 2.6rem;
    font-size: 16px;
    font-size: 1.6rem;
  }
  .how-to-step {
    margin-top: 54px;
    li {
      text-align: center;
      color: #5c5c5c;
      font-size: 14px;
      font-size: 1.4rem;
      + li {
        padding-top: 65px;
        background-image: url(/assets/img/how_to_item_01.svg);
        background-repeat: no-repeat;
        background-size: auto 38px;
        background-position: center 20px;
      }
      p {
        margin-top: 10px;
      }
    }
    .how-to-step1 {
      img {
        width: 32px;
        height: auto;
      }
    }
    .how-to-step2 {
      img {
        width: 171px;
        height: auto;
      }
    }
    .how-to-step3 {
      img {
        width: 125px;
        height: auto;
      }
    }
  }
  .how-to-notes {
    margin-top: 20px;
    padding-left: 25px;
    box-sizing: border-box;
    background-image: url(/assets/img/bell_icon_03.svg);
    background-repeat: no-repeat;
    background-size: 21px;
    background-position: left 0;
    color: #ff5800;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 2rem;
  }
  // to eat モーダル
  .to-eat-modal-head {
    margin-bottom: 8px;
    padding-left: 45px;
    border-bottom: 1px solid #ccc;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 4rem;
    color: #ff5800;
    &.where-to-eat-head {
      background-image: url(/assets/img/where_to_modal_item_01.svg);
      background-repeat: no-repeat;
      background-size: 38px;
      background-position: left 0;
    }
    &.what-to-eat-head {
      background-image: url(/assets/img/what_to_modal_item_01.svg);
      background-repeat: no-repeat;
      background-size: 29px;
      background-position: left 0;
    }
  }
  .to-eat-modal-list {
    // label {
    //   display: flex;
    //   justify-content: space-between;
    // }
    // input[type='radio'] {
    //   appearance: radio;
    //   width: 15px;
    //   height: 15px;
    // }
    // li {
    //   padding-top: 20px;
    //   padding-bottom: 20px;
    //   padding-right: 15px;
    //   font-size: 16px;
    //   font-size: 1.6rem;
    //   color: #707070;
    //   & + li {
    //     border-top: 1px solid #e6e6e6;
    //   }
    // }
    label {
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    input[type='radio'] {
      appearance: radio;
      width: 15px;
      height: 15px;
    }
    li {
      padding-right: 15px;
      font-size: 16px;
      color: #707070;
      & + li {
        border-top: 1px solid #e6e6e6;
      }
    }
    input[type='radio'] {
      display: none;
    }
    .radio {
      box-sizing: border-box;
      -webkit-transition: background-color 0.2s linear;
      transition: background-color 0.2s linear;
      position: relative;
      padding: 20px 0 20px 0;
      vertical-align: middle;
      cursor: pointer;
    }
    .radio:after {
      -webkit-transition: border-color 0.2s linear;
      transition: border-color 0.2s linear;
      position: absolute;
      top: 50%;
      right: 0;
      display: block;
      margin-top: -10px;
      width: 18px;
      height: 18px;
      border: 1px solid #bbb;
      border-radius: 18px;
      content: '';
    }
    .radio:before {
      -webkit-transition: opacity 0.2s linear;
      transition: opacity 0.2s linear;
      position: absolute;
      top: 50%;
      right: 5px;
      display: block;
      margin-top: -5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #ff5800;
      content: '';
      opacity: 0;
    }
    input[type='radio']:checked + .radio:before {
      opacity: 1;
    }
  }

  // フッター固定
  .fixed-footer {
    position: fixed;
    bottom: 0;
    z-index: 11;
    display: block;
    width: 100%;
    height: 54px;
    margin-bottom: env(safe-area-inset-bottom);
    background-image: url(/assets/img/display_bottom_fixed.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .fixed-footer-inner {
      position: relative;
    }
    .fixed-footer-center {
      position: absolute;
      top: -33px;
      left: 0;
      right: 0;
      width: 80px;
      height: 80px;
      margin: auto;
      cursor: pointer;
    }
    .fixed-footer-left {
      position: absolute;
      top: 10px;
      left: 20%;
      width: 35px;
      height: 40px;
      cursor: pointer;
    }
    .fixed-footer-right {
      position: absolute;
      top: 10px;
      right: 20%;
      width: 35px;
      height: 40px;
      cursor: pointer;
    }
  }
  .ril__image {
    width: unset;
    height: unset;
  }

  // 　在宅レストランサービスとは　モーダル
  .top-page-selection-head {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .top-page-selection-box {
    margin-top: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 320px;
    }
    p {
      padding: 10px 0;
      line-height: 20px;
      width: 320px;
      font-family: 'Sawarabi Mincho', sans-serif;
    }
  }

  .chat-modal {
    position: relative;
  }

  .chat-modal-close-button {
    padding: 6px;
    border: none;
    position: absolute;
    top: -36px;
    right: -13px;
    background: lightgray;
  }

  .chat-modal-close-button:hover {
    cursor: pointer;
  }

  .chat-modal-close-button2 {
    padding: 6px;
    border: none;
    position: absolute;
    top: -7px;
    right: -13px;
    background: lightgray;
  }

  .chat-modal-close-button2:hover {
    cursor: pointer;
  }

  .chat-modal-top {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-top: 20px;
  }

  .chat-modal-top-text {
    color: lightgray;
    font-size: 20px;
    margin-left: 10px;
    width: 100px;
  }

  .chat-modal-icon {
    width: 100px;
    margin-top: 10px;
  }

  .chat-modal-head-text {
    font-size: 15px;
    font-family: 'Sawarabi Mincho';
    text-align: center;
    font-weight: bold;
    margin: 20px 0;
    line-height: 1.4;
  }

  .chat-modal-content {
    text-align: center;
    margin: 20px 0;
    line-height: 1.8;
    font-size: 13px;
  }

  .chat-modal-qr {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .chat-modal-qr-image {
    width: 225px;
  }

  .chat-modal-qr-text {
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    color: #fff;
    position: absolute;
    top: 20px;
    left: 62px;
    color: #fff;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.loading-spinner {
  display: flex;
  justify-content: center;
}

.loading-spinner-text {
  text-align: center;
  color: #ff5800;
  margin: 20px 0;
}

@media screen and (min-width: 769px) {
  // datepicker下線スタイル調整
  .conditions-chice li.reserve-form-date input {
    width: 150px;
  }
}

@media screen and (max-width: 768px) {
  // datepicker下線スタイル調整
  .conditions-chice li.reserve-form-date input {
    width: 100px;
  }
}
