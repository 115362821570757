.menu-reserve-form {
  // =====================================
  // PC
  // =====================================
  @media screen and (min-width: 769px) {
    .menu-reserve-store-image-box {
      display: none;
    }
    .menu-reserve-store-title {
      background-color: #ff5800;
      text-align: center;
      span {
        display: inline-block;
        font-size: 16px;
        margin: 0 auto;
        padding-left: 43px;
        background-image: url(/assets/img/menu_icon_03.svg);
        background-size: 25px auto;
        background-repeat: no-repeat;
        background-position: left center;
        font-weight: $font-normal;
        line-height: 54px;
        color: #fff;
      }
    }
    main {
      position: relative;
      width: 942px;
      margin: 50px auto 0;
      box-sizing: border-box;
    }
    .reserve-form-main-container {
      display: flex;
      justify-content: space-between;
      width: 942px;
      margin: 0 auto;
      padding-bottom: 66px;
    }
    .reserve-form-left-container {
      width: 540px;
    }
    .reserve-form-right-container {
      width: 346px;
    }

    .menu-reserve-name-container {
      margin-top: 15px;
      color: #5c5c5c;
      font-weight: $font-medium;
    }
    .menu-reserve-name-en {
      font-size: 18px;
    }
    .menu-reserve-name-ja {
      margin-top: 15px;
      font-size: 16px;
    }
    .conditions-chice {
      li {
        border: 1px solid #e6e6e6;
      }
    }
    .use-points {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 36px;
      padding: 15px;
      width: 313px;
      background-color: #f4f4f4;
    }
    .menu-reserve-form-tab {
      display: flex;
      margin-top: 57px;
      li {
        width: 110px;
        text-align: center;
        line-height: 26px;
        font-weight: $font-normal;
        color: #5c5c5c;
        font-size: 14px;
      }
      .menu-reserve-form-tab-active {
        border-radius: 20px;
        background-color: #5c5c5c;
        color: #fff;
      }
    }
    .menu-reserve-form-menu-container {
      width: 540px;
      margin-top: 30px;
      margin-bottom: 30px;
      .inactive {
        background-color: #f4f4f4;
      }
      .active {
        background-color: #fff;
      }
    }
    .menu-reserve-form-menu-box {
      padding-top: 20px;
      padding-bottom: 16px;
      padding-left: 14px;
      padding-right: 17px;
      border-radius: 8px;
      background-color: #fff;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
      & + li {
        margin-top: 6px;
      }
    }
    .menu-reserve-form-menu-name {
      font-size: 12px;
      color: #3b3b3b;
      font-weight: $font-medium;
      span {
        font-weight: $font-normal;
      }
      img {
        width: 15px;
        padding-right: 5px;
        vertical-align: middle;
      }
    }
    .menu-reserve-form-detail {
      position: relative;
    }
    .menu-reserve-form-detail-txt {
      padding-right: 74px;
    }
    .menu-reserve-form-course-name {
      margin-top: 12px;
      font-size: 14px;
      font-weight: $font-normal;
      color: #5c5c5c;
      line-height: 23px;
    }
    .menu-reserve-form-notice {
      font-size: 10px;
      color: #9d9d9d;
      line-height: 16px;
    }
    .menu-reserve-form-price {
      display: inline-block;
      margin-top: 15px;
      padding-bottom: 5px;
      font-size: 12px;
      font-weight: $font-normal;
      color: #5c5c5c;
      border-bottom: 1px solid #ccc;
      span {
        color: #9d9d9d;
      }
    }
    .reserve-form-guest-ditails {
      margin-top: 84px;
      border: 1px solid #d3d3d3;
      box-shadow: none;
    }
    .menu-reserve-form-menu-quantity {
      position: absolute;
      top: 10px;
      right: 0;
      width: 50px;
      height: 37px;
      font-size: 25px;
      text-align: center;
      line-height: 41px;
      font-weight: $font-light;

      background-image: url(/assets/img/menu_icon_02.svg);
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 20px 20px;
      padding-left: 32px;
    }
    .reserve-form-cancelpolicy {
      margin-top: 30px;
      margin-bottom: 32px;
      font-weight: $font-light;
      color: #ff7474;
      font-size: 16px;
    }
    .reserve-form-cancelpolicy-title {
      margin-bottom: 10px;
      padding-left: 30px;
      background-image: url(/assets/img/bell_icon_02.svg);
      background-size: 23px;
      background-repeat: no-repeat;
      font-weight: $font-normal;
      line-height: 24px;
      color: #ff7474;
    }
    .reserve-form-cancelpolicy-txt {
      line-height: 22px;
      font-size: 14px;
    }
    .notes-area {
      margin-bottom: 40px;
    }
  }

  // =====================================
  // SP
  // =====================================

  @media screen and (max-width: 768px) {
    overflow: hidden;
    header {
      position: relative;
      padding-top: 30px;
      background-color: #f4f4f4;
      border-radius: 0 0 3px 3px;
    }
    main {
      padding-bottom: 42px;
    }
    .menu-reserve-store-title {
      margin-left: 11px;
      margin-right: 11px;
      border-bottom: 1px solid #ff5800;
      text-align: center;
      span {
        display: inline-block;
        font-size: 18px;
        font-size: 1.8rem;
        margin: 0 auto;
        padding-bottom: 18px;
        padding-left: 43px;
        background-image: url(/assets/img/menu_icon_02.svg);
        background-size: 30px auto;
        background-repeat: no-repeat;
        background-position: left top;
        font-weight: $font-medium;
        color: #ff5800;
      }
    }
    .menu-reserve-form-title {
      line-height: 5.4rem;
      font-weight: $font-medium;
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-size: 1.6rem;
    }
    .menu-reserve-store-image-box {
      position: fixed;
      top: 0;
      z-index: 10;
      width: 100%;
      height: 66px;
    }
    .menu-reserve-store-image-box-inner {
      position: relative;
      height: 66px;
    }
    .menu-reserve-store-image {
      position: absolute;
      top: -350%;
      left: 50%;
      transform: translateX(-50%);
      width: 140%;
      height: 294px;
      border-radius: 100%;
      overflow: hidden;
      img {
        position: relative;
        top: 7%;
        display: block;
        width: 100vw;
        height: auto;
        margin: 0 auto;
      }
    }
    .menu-reserve-store-img-icon {
      position: absolute;
      top: 43px;
      left: 0;
      right: 0;
      width: 60px;
      height: 60px;
      margin: auto;
    }
    .menu-reserve-store-head {
      padding-top: 20px;
      padding-left: 14px;
      padding-right: 14px;
    }
    .menu-reserve-name-container {
      padding-top: 8px;
      padding-bottom: 20px;
      color: #5c5c5c;
      font-weight: $font-medium;
    }
    .menu-reserve-name-en {
      font-size: 16px;
      font-size: 1.6rem;
      line-height: 2.6rem;
    }
    .menu-reserve-name-ja {
      margin-top: 4px;
    }
    .menu-reserve-info-container {
      display: flex;
      padding-left: 14px;
      padding-right: 14px;
      height: 46px;
      border-top: 1px dotted #5c5c5c;
    }
    .menu-reserve-form-container {
      padding-top: 34px;
      background: linear-gradient(#fff 0%, #f4f4f4 100%);
      border-radius: 16px 16px 0 0;
    }
    .conditions-chice {
      li {
        border: 1px solid #e6e6e6;
      }
    }
    .use-points {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 15px;
      width: 100%;
      border-radius: 8px;
    }
    .menu-reserve-form-container-top {
      padding-left: 20px;
      padding-right: 20px;
    }
    .menu-reserve-form-tab {
      display: flex;
      margin-top: 30px;
      margin-bottom: 15px;
      li {
        width: 110px;
        text-align: center;
        line-height: 2.6rem;
        font-weight: $font-normal;
        color: #5c5c5c;
        font-size: 14px;
        font-size: 1.4rem;
      }
      .menu-reserve-form-tab-active {
        border-radius: 20px;
        background-color: #5c5c5c;
        color: #fff;
      }
    }
    .menu-reserve-form-menu-container {
      margin-bottom: 30px;
      margin-top: 30px;
      margin-left: 11px;
      margin-right: 11px;
      .inactive {
        background-color: #f4f4f4;
      }
      .active {
        background-color: #fff;
      }
    }
    .menu-reserve-form-menu-box {
      padding-top: 20px;
      padding-bottom: 16px;
      padding-left: 14px;
      padding-right: 17px;
      border-radius: 8px;
      background-color: #fff;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
      & + li {
        margin-top: 6px;
      }
    }
    .menu-reserve-form-menu-name {
      font-size: 15px;
      font-size: 1.5rem;
      color: #3b3b3b;
      font-weight: $font-medium;
      span {
        font-weight: $font-normal;
      }
      img {
        width: 15px;
        padding-right: 5px;
        vertical-align: middle;
      }
    }
    .menu-reserve-form-detail {
      position: relative;
    }
    .menu-reserve-form-detail-txt {
      padding-right: 74px;
    }
    .menu-reserve-form-course-name {
      margin-top: 12px;
      font-size: 14px;
      font-size: 1.4rem;
      font-weight: $font-normal;
      color: #5c5c5c;
      line-height: 2.3rem;
    }
    .menu-reserve-form-notice {
      font-size: 10px;
      font-size: 1rem;
      color: #9d9d9d;
      line-height: 1.6rem;
    }
    .menu-reserve-form-price {
      display: inline-block;
      margin-top: 15px;
      padding-bottom: 5px;
      font-size: 12px;
      font-size: 1.2rem;
      font-weight: $font-normal;
      color: #5c5c5c;
      border-bottom: 1px solid #ccc;
      span {
        color: #9d9d9d;
      }
    }
    .menu-reserve-form-detail-btn {
      position: absolute;
      top: 10px;
      right: 0;
      width: 37px;
      height: 37px;
      border-radius: 50%;
      border: 1px solid #ccc;
      font-size: 25px;
      font-size: 2.5rem;
      color: #ff5800;
      text-align: center;
      line-height: 3.9rem;
      font-weight: $font-light;
    }
    .menu-reserve-form-btn-add {
      background-color: #ff5800;
      color: #fff;
      font-size: 18px;
      font-size: 1.8rem;
    }
    .reserve-form-guest-ditails {
      margin-top: 30px;
    }
    .menu-reserve-form-menu-quantity {
      position: absolute;
      top: 10px;
      right: 0;
      width: 50px;
      height: 37px;
      font-size: 25px;
      text-align: center;
      line-height: 41px;
      font-weight: $font-light;

      background-image: url(/assets/img/menu_icon_02.svg);
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 20px 20px;
      padding-left: 32px;
    }
    .reserve-form-cancelpolicy {
      margin-top: 30px;
      margin-bottom: 35px;
      padding-left: 16px;
      padding-right: 16px;
      font-weight: $font-light;
      color: #ff7474;
    }
    .reserve-form-cancelpolicy-title {
      margin-bottom: 10px;
      padding-left: 30px;
      background-image: url(/assets/img/bell_icon_02.svg);
      background-size: 23px;
      background-repeat: no-repeat;
      font-weight: $font-normal;
      line-height: 2.4rem;
      color: #ff7474;
    }
    .reserve-form-cancelpolicy-txt {
      line-height: 2.2rem;
      font-size: 14px;
      font-size: 1.4rem;
    }
    .payment-type {
      margin-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
      box-sizing: border-box;
    }
  }
}
