.header {
  // =====================================
  // PC
  // =====================================

  @media screen and (min-width: 769px) {
    .pc-menu {
      position: relative;
    }
    .pc-menu-item {
      position: absolute !important;
      top: 35px;
    }
    .pc-menu:hover {
      background: transparent;
    }
    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      top: 0;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.93);
      border-bottom: solid 1px #e6e6e6;
      z-index: 100;
    }
    .slide-container {
      width: 70%;
      margin: auto;
    }
    .slide-title {
      line-height: 65px;
      font-size: 55px;
      font-family: 'Sawarabi Mincho', sans-serif;
      letter-spacing: 3px;
      text-align: center;
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      z-index: 1;
      p {
        font-size: 20px;
      }
    }
    .slide-sevices {
      width: 100%;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.3);
      position: absolute;
      bottom: 0;
      font-family: 'Sawarabi Mincho', sans-serif;
      z-index: 2;
      .slide-sevices-item {
        width: 320px;
        padding: 10px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        img {
          width: 320px;
        }
        span {
          position: absolute;
          color: black;
          bottom: 20px;
          text-decoration: none;
          border-radius: 100px;
          padding: 10px;
          font-size: 12px;
          text-align: center;
          width: 180px;
          img {
            width: 10px;
            height: 10px;
            position: absolute;
            right: 10px;
            top: 11px;
          }
        }
      }
    }

    .slide-video::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }

    .each-slide > div {
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
      height: 600px;
      width: 100%;
      background-position: center;
    }

    .each-slide span {
      padding: 20px;
      font-size: 20px;
      background: #efefef;
      text-align: center;
    }

    .each-fade {
      display: flex;
    }

    .each-fade .image-container {
      width: 75%;
      overflow: hidden;
    }

    .each-fade .image-container img {
      width: 100%;
    }

    .each-fade h2 {
      width: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      background: #adceed;
    }

    .top-page-logo {
      img {
        width: 100px;
        padding: 10px 25px;
      }
    }
    .top-page-header-menu {
      .top-page-header-menu-ul {
        position: relative;
        display: flex;
        padding-right: 24px;
      }
      li {
        padding: 10px 24px;
        a {
          text-decoration: none;
          color: initial;
        }
      }
      .active {
        background: #ff5800;
        border-radius: 100px;
        a {
          color: white;
        }
      }
    }
    .header-inner {
      position: relative;
      width: 1025px;
      margin: 0 auto;
    }
    .top-page-notification-title {
      padding-left: 33px;
      font-size: 16px;
      line-height: 23px;
      font-weight: $font-medium;
      background-image: url(/assets/img/bell_icon_03.svg);
      background-size: 22px;
      background-repeat: no-repeat;
      background-position: left center;
    }
    .top-page-notification-txt {
      margin-top: 15px;
      font-size: 14px;
      line-height: 18px;
      font-weight: $font-normal;
    }
    .top-page-notification-close {
      position: absolute;
      top: 40px;
      right: 0;
      width: 10px;
      height: 10px;
    }
    .top-page-top {
      position: relative;
      background-color: #f4f4f4;
    }
    .top-page-top-inner {
      position: relative;
      width: 1025px;
      height: 336px;
      margin: 0 auto;
    }
    .menu-icon {
      position: absolute;
      top: 20px;
    }
    .top-logo {
      position: absolute;
      top: 70px;
      left: 190px;
      display: block;
      width: 125px;
      height: auto;
    }
    .top-page-top-txt {
      position: absolute;
      top: 80px;
      left: 410px;
      font-size: 20px;
      line-height: 36px;
      font-weight: $font-medium;
      color: #5c5c5c;
    }
    .top-sarch-area {
      position: absolute;
      top: 182px;
      left: 413px;
      width: 355px;
      margin: 15px auto 0;
      .top-sarch-container {
        width: 100%;
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.16);
        border-radius: 30px;
        background-color: #ffffff;
        display: block;

        input {
          width: 100%;
          height: 63px;
          box-sizing: border-box;
          padding-left: 60px;
          background-image: url(/assets/img/edit_icon_03.svg);
          background-size: 13px auto;
          background-repeat: no-repeat;
          background-position: 30px center;
          border: none;
          outline: none;
          border-radius: 30px;
          font-size: 15px;
          font-weight: $font-normal;
          font-size: 16px;
          transform: scale(0.834);
          &::input-placeholder {
            color: #9d9d9d;
          }
        }
        ul.suggest-list li {
          width: 310px;
          line-height: 45px;
          border: none;
          outline: none;
          font-size: 15px;
          font-weight: $font-normal;
          a {
            width: 305px;
            padding-left: 30px;
            padding-right: 20px;
            line-height: 45px;
            display: inline-block;
            color: #9d9d9d;
            cursor: pointer;
            span.area {
              background-image: url(/assets/img/pin_icon_02.svg);
              background-size: 12px auto;
              background-repeat: no-repeat;
              background-position: left center;
              padding-left: 17px;
              opacity: 0.5;
              float: right;
              color: #5c5c5c;
              font-size: 12px;
            }
          }
          a:hover {
            background-color: #f8f8f8;
          }
          a:focus {
            background-color: #f8f8f8;
          }
        }
        .search-bottom {
          width: 355px;
          height: 45px;
          box-sizing: border-box;
          border-radius: 0 0 30px 30px;
          display: inline-block;
          cursor: pointer;
          a {
            width: 295px;
            padding-left: 60px;
            background-image: url(/assets/img/edit_icon_05.svg);
            background-size: 13px auto;
            background-repeat: no-repeat;
            background-position: 30px center;
            line-height: 45px;
            display: inline-block;
            border-radius: 0 0 30px 30px;
            color: #ff5800;
          }
          a:hover {
            background-color: #f8f8f8;
          }
          a:focus {
            background-color: #f8f8f8;
          }
        }
      }
    }
    .video {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 40px;
    }
    .top-page-selection {
      width: 1025px;
      margin: 0 auto;
    }
    .top-page-selection-head {
      display: flex;
      justify-content: space-between;
    }
    .top-page-selection-title-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px 0 0;
      h4 {
        font-size: 19px;
        margin-left: 10px;
        color: #5c5c5c;
        font-family: 'Sawarabi Mincho', sans-serif;
      }
      p {
        margin-top: 10px;
        color: #ff5800;
      }
    }
    .top-page-selection-title {
      width: 320px;
      height: 40px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      img {
        width: 90px;
      }
    }
    .top-page-selection-box {
      width: 320px;
      margin: 20px 10px 10px;
      .top-page-selection-box-img {
        height: 220px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
        }
      }

      h3 {
        padding: 20px 0 0;
        text-align: center;
        font-family: 'Sawarabi Mincho', sans-serif;
      }

      p {
        padding: 15px 0;
        line-height: 23px;
        font-family: 'Sawarabi Mincho', sans-serif;
      }
    }
    .top-page-restaurant-title-box {
      padding: 40px 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #5c5c5c;
      h3 {
        font-family: 'Sawarabi Mincho', sans-serif;
        font-weight: 1;
      }
      p {
        margin-top: 10px;
      }
    }
    .thoughts-about-the-chefle {
      line-height: 25px;
      padding: 20px 14px;
    }
    .top-page-selection-txt {
      margin-top: 13px;
      font-size: 12px;
      color: #5c5c5c;
      line-height: 20px;
      font-weight: $font-normal;
    }
    .top-page-selection-head-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 706px;
      border-bottom: 1px solid #cacaca;
    }
    .top-page-selection-head-eat-link {
      display: flex;
      justify-content: space-between;
      p {
        position: relative;
        padding-left: 30px;
        padding-right: 20px;
        font-size: 16px;
        line-height: 20px;
        color: #5c5c5c;
        font-size: 14px;
        &::after {
          content: '>';
          position: absolute;
          right: 0;
          display: inline-block;
          margin: auto;
          transform: rotate(90deg);
        }
        &.top-page-selection-head-where-eat {
          background-image: url(/assets/img/pin_icon_04.svg);
          background-size: 15px auto;
          background-repeat: no-repeat;
          background-position: left center;
        }
        &.top-page-selection-head-what-eat {
          margin-left: 20px;
          background-image: url(/assets/img/eat_icon_02.svg);
          background-size: 18px auto;
          background-repeat: no-repeat;
          background-position: left center;
        }
      }
      a {
        text-decoration: none;
        color: #5c5c5c;
      }
    }
    .top-page-stores-title {
      padding: 25px 0px 0px 0px;
      color: #5c5c5c;
      font-size: 24px;
    }
    .top-page-stores-description {
      margin-top: 13px;
      color: #5c5c5c;
    }
    .top-page-store-tab-container {
      color: #5c5c5c;
    }
    .top-page-store-tab {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      li {
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 30px;
        box-sizing: border-box;
        font-size: 14px;
        & + li {
          margin-left: 15px;
        }
        cursor: pointer;
      }
    }
    .top-page-store-active {
      background-color: #ff5800;
      color: #fff;
    }
    .top-page-restaurant {
      background-color: #f4f4f4;
    }
    .top-page-store-area {
      padding-top: 20px;
      padding-bottom: 55px;
    }
    .top-page-sort-restaurant-btn {
      color: #5c5c5c;
      display: flex;
      cursor: pointer;
      p {
        margin: 0 10px;
      }
    }
    .top-page-sort-restaurant-btn-active {
      color: #ff5800;
    }
    .top-page-store-card-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: -40px;
    }
    .top-page-store-card-box {
      width: 320px;
      position: relative;
      margin-top: 40px;
      padding-bottom: 10px;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      overflow: hidden;
      &::after {
        position: absolute;
        top: 5px;
        right: 5px;
        padding-top: 3px;
        padding-bottom: 3px;
        padding-right: 3px;
        padding-left: 3px;
        border-radius: 4px;
        color: #ffffff;
      }
      .top-page-store-card-img {
        width: 100%;
      }
    }
    .top-page-store-card-title-box {
      position: relative;
      margin-top: 10px;
      margin-left: 10px;
      margin-right: 10px;
      padding-left: 50px;
    }
    .top-page-store-card-person {
      position: absolute;
      top: 0;
      left: 0;
      width: 32px;
      height: 32px;
    }
    .top-page-store-card-title-en {
      font-size: 14px;
      font-weight: $font-medium;
    }
    .top-page-store-card-title-ja {
      margin-top: 5px;
      font-size: 13px;
      font-weight: $font-normal;
      color: #9d9d9d;
    }
    .top-page-store-card-line {
      margin-top: 0;
      margin-bottom: 0;
      border-style: dotted;
    }
    .top-page-store-card-place {
      margin-top: 10px;
      padding-left: 20px;
      background-image: url(/assets/img/pin_icon_01.svg);
      background-size: 13px auto;
      background-repeat: no-repeat;
      background-position: left center;
      font-size: 12px;
      font-weight: $font-normal;
      line-height: 20px;
    }
    .top-page-store-card-description {
      margin-top: 5px;
      font-size: 12px;
      font-weight: $font-normal;
      color: #5c5c5c;
      line-height: 18px;
    }
    .top-page-store-pager {
      display: flex;
      width: 270px;
      margin: 45px auto 0;
      a {
        width: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 12px;
        display: inline-block;
        text-decoration: none;
        color: #9d9d9d;
        &.top-page-store-pager-active {
          color: #000000;
          background-color: #e6e6e6;
          border-radius: 50%;
          pointer-events: none;
        }
      }
    }
    // フッター固定
    .top-footer {
      position: fixed;
      bottom: 0;
      z-index: 11;
      display: block;
      width: 100%;
      height: 54px;
      background-image: url(/assets/img/display_bottom_fixed.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      .top-footer-inner {
        position: relative;
      }
      .top-footer-ask {
        position: absolute;
        top: -33px;
        left: 0;
        right: 0;
        width: 67px;
        height: 67px;
        margin: auto;
      }
      .top-footer-area {
        position: absolute;
        top: 10px;
        left: 20%;
        width: 25px;
        height: 39px;
      }
      .top-footer-couse-01 {
        position: absolute;
        top: 10px;
        right: 20%;
        width: 37px;
        height: auto;
      }
    }

    &.campaign {
      .top-page-top {
        background: url('https://s3-ap-northeast-1.amazonaws.com/files.jpneazy.com/campaign/background_pc.jpg');
      }
    }

    .chat-button {
      position: absolute;
      top: 50px;
      right: 35px;
      z-index: 10;
      border: 1px solid #ff5800;
      border-radius: 55px;
      width: 60px;
      height: 60px;
      padding: 10px;
      background-color: #ff5800;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
    }

    .chat-button-2 {
      position: absolute;
      top: 50px;
      right: 145px;
      z-index: 10;
      border: 1px solid #ff5800;
      border-radius: 55px;
      width: 60px;
      height: 60px;
      padding: 10px;
      background-color: #ff5800;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
    }

    .chat-button:hover {
      cursor: pointer;
    }

    .chat-button-2:hover {
      cursor: pointer;
    }

    .chat-content {
      position: relative;
    }

    .chat-icon {
      position: absolute;
      top: 9px;
      left: 24px;
      width: 30px;
    }

    .chat-text {
      color: #fff;
      font-size: 11px;
      font-family: 'Sawarabi Mincho';
      position: absolute;
      top: 43px;
      left: 1px;
      text-align: center;
      line-height: 1.4;
    }

    .chat-text-2 {
      color: #fff;
      font-size: 16px;
      font-family: 'Sawarabi Mincho';
      position: absolute;
      top: 43px;
      left: 7px;
      text-align: center;
      line-height: 1.4;
    }

    .chat-ribbon {
      color: #fff;
      font-size: 25px;
      font-family: 'Sawarabi Mincho';
      position: absolute;
      top: 12px;
      left: 27px;
      text-align: center;
      line-height: 1.4;
    }
  }

  // =====================================
  // SP
  // =====================================
  @media screen and (max-width: 768px) {
    header {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .top-page-header {
      position: relative;
    }

    .sp-menu {
      display: flex;
      align-items: center;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 100;
    }

    .chat-icon-baloon {
      position: absolute;
      width: 25px;
      height: auto;
      top: 5px;
      left: 13px;
    }

    .chat-icon-baloon2 {
      position: absolute;
      width: 27px;
      height: auto;
      top: 9px;
      left: 11px;
    }

    .chat-icon-button {
      position: absolute;
      width: 30px;
      height: 30px;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.664);
      top: 0;
      right: 51px;
    }

    .chat-icon-button2 {
      position: absolute;
      width: 30px;
      height: 30px;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.664);
      top: 0;
      right: 102px;
    }

    .chat-icon-button3 {
      position: absolute;
      width: 30px;
      height: 30px;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.664);
      top: 0;
      right: 173px;
    }

    .menu-icon-button {
      position: absolute;
      width: 30px;
      height: 30px;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.664);
      top: 0;
      right: 0;
    }

    .close-icon-button {
      width: 30px;
      height: 30px;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.664);
    }

    .chat-icon-text {
      position: absolute;
      height: auto;
      top: 33px;
      left: 6px;
      color: #ff5800;
      font-size: 10px;
      font-weight: bold;
    }

    .top-page-header-menu {
      display: flex;
      align-items: center;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 100;
      img {
        width: 30px;
        height: 30px;
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.664);
      }
      .menu-top-block {
        img {
          width: 100%;
          height: auto;
          padding: 0;
        }
      }
    }

    .slide-container {
      width: 70%;
      margin: auto;
    }
    .slide-title {
      width: 100%;
      line-height: 50px;
      font-size: 30px;
      font-family: 'Sawarabi Mincho', sans-serif;
      text-align: center;
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      z-index: 1;
      p {
        font-size: 12px;
      }
    }

    .slide-sevices {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      bottom: 0;
      font-family: 'Sawarabi Mincho', sans-serif;
      z-index: 2;
      .slide-sevices-item {
        width: 320px;
        padding: 10px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        img {
          width: 320px;
        }
        span {
          position: absolute;
          color: black;
          bottom: 20px;
          text-decoration: none;
          border-radius: 100px;
          padding: 10px;
          font-size: 12px;
          text-align: center;
          width: 180px;
          img {
            width: 10px;
            height: 10px;
            position: absolute;
            right: 10px;
            top: 11px;
          }
        }
      }

      .about-chefle {
        margin-top: 50px;
        width: 320px;
      }
    }

    .slide-video::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }

    .each-slide > div {
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
      height: 40vh;
      width: 100%;
      background-position: center;
    }

    .each-slide span {
      padding: 20px;
      font-size: 20px;
      background: #efefef;
      text-align: center;
    }

    .each-fade {
      display: flex;
    }

    .each-fade .image-container {
      width: 75%;
      overflow: hidden;
    }

    .each-fade .image-container img {
      width: 100%;
    }

    .each-fade h2 {
      width: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      background: #adceed;
    }
    .top-page-notification-title {
      padding-left: 33px;
      font-size: 16px;
      font-size: 1.6rem;
      line-height: 2.3rem;
      font-weight: $font-medium;
      background-image: url(/assets/img/bell_icon_03.svg);
      background-size: 22px;
      background-repeat: no-repeat;
      background-position: left center;
    }
    .top-page-notification-txt {
      margin-top: 15px;
      font-size: 14px;
      font-size: 1.4rem;
      line-height: 1.8rem;
      font-weight: $font-normal;
    }
    .top-page-notification-close {
      position: absolute;
      top: 75px;
      right: 16px;
      width: 10px;
      height: 10px;
    }
    .top-page-top {
      position: relative;
      padding-top: 0;
    }
    .top-page-logo {
      padding: 6px;
      position: absolute;
      top: 0;
      img {
        width: 100px;
      }
    }
    .video {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 20px;
    }
    .top-page-selection {
      background-color: #f4f4f4;
    }
    .top-page-selection-box {
      margin-top: 10px;
      padding: 0 14px;
      p {
        padding: 10px 0;
        line-height: 20px;
        font-family: 'Sawarabi Mincho', sans-serif;
      }
    }
    .top-page-top-txt {
      margin-top: 23px;
      text-align: center;
      font-size: 14px;
      font-size: 1.4rem;
      line-height: 2.4rem;
      font-weight: $font-normal;
      color: #5c5c5c;
    }
    .top-sarch-area {
      width: 100%;
      margin: 15px auto 0;
      position: absolute;
      z-index: 10;

      .top-sarch-container {
        width: 290px;
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.16);
        border-radius: 30px;
        background-color: #ffffff;
        margin: 0 auto;
        display: block;
        input {
          width: 100%;
          height: 49px;
          box-sizing: border-box;
          padding-left: 60px;
          background-image: url(/assets/img/edit_icon_03.svg);
          background-size: 13px auto;
          background-repeat: no-repeat;
          background-position: 30px center;
          border: none;
          outline: none;
          border-radius: 30px;
          font-weight: $font-normal;
          &::input-placeholder {
            color: #9d9d9d;
          }
        }
        ul.suggest-list li {
          width: 290px;
          line-height: 40px;
          border: none;
          outline: none;
          font-size: 15px;
          font-weight: $font-normal;
          a {
            width: 240px;
            padding-left: 30px;
            padding-right: 20px;
            line-height: 40px;
            display: inline-block;
            color: #9d9d9d;
            cursor: pointer;
            span.area {
              background-image: url(/assets/img/pin_icon_02.svg);
              background-size: 12px auto;
              background-repeat: no-repeat;
              background-position: left center;
              padding-left: 17px;
              opacity: 0.5;
              float: right;
              color: #5c5c5c;
              font-size: 12px;
            }
          }
          a:hover {
            background-color: #f8f8f8;
          }
          a:focus {
            background-color: #f8f8f8;
          }
        }
        .search-bottom {
          width: 290px;
          height: 40px;
          box-sizing: border-box;
          border-radius: 0 0 30px 30px;
          display: inline-block;
          cursor: pointer;
          a {
            width: 230px;
            padding-left: 60px;
            background-image: url(/assets/img/edit_icon_05.svg);
            background-size: 13px auto;
            background-repeat: no-repeat;
            background-position: 30px center;
            line-height: 40px;
            display: inline-block;
            border-radius: 0 0 30px 30px;
            color: #ff5800;
          }
          a:hover {
            background-color: #f8f8f8;
          }
          a:focus {
            background-color: #f8f8f8;
          }
        }
      }
    }
    .top-page-or-line {
      position: relative;
      display: block;
      width: 30px;
      margin: 20px auto 0;
      color: #9d9d9d;
      text-align: center;
      font-size: 14px;
      font-size: 1.4rem;
      font-weight: $font-normal;
      margin-top: 84px;
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        display: inline-block;
        width: 128px;
        height: 1px;
        margin: auto;
        border-top: 1px solid #e6e6e6;
      }
      &::before {
        left: -130px;
      }
      &::after {
        right: -130px;
      }
    }

    .top-page-restaurant {
      background-color: #f4f4f4;
    }
    .top-page-selection-title-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 0;
      h4 {
        font-size: 19px;
        margin-left: 10px;
        color: #5c5c5c;
        font-family: 'Sawarabi Mincho', sans-serif;
      }
      p {
        margin-top: 10px;
        color: #ff5800;
      }
    }
    .top-page-selection-title {
      width: 320px;
      height: 40px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      img {
        width: 90px;
      }
    }
    .top-page-restaurant-title-box {
      padding: 40px 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #5c5c5c;
      h3 {
        font-family: 'Sawarabi Mincho', sans-serif;
        font-weight: 1;
      }

      p {
        margin-top: 10px;
      }
    }
    .top-page-selection-txt {
      margin-top: 20px;
      font-size: 12px;
      font-size: 1.2rem;
      color: #5c5c5c;
      text-align: center;
      line-height: 2rem;
      font-weight: $font-normal;
    }
    .top-page-selection-head-eat-link {
      display: flex;
      justify-content: center;
      padding: 30px 0;
      p {
        position: relative;
        padding-left: 30px;
        padding-right: 20px;
        font-size: 16px;
        line-height: 20px;
        color: #5c5c5c;
        font-size: 14px;
        &::after {
          content: '>';
          position: absolute;
          right: 0;
          display: inline-block;
          margin: auto;
          transform: rotate(90deg);
        }
        &.top-page-selection-head-where-eat {
          background-image: url(/assets/img/pin_icon_04.svg);
          background-size: 15px auto;
          background-repeat: no-repeat;
          background-position: left center;
        }
        &.top-page-selection-head-what-eat {
          margin-left: 20px;
          background-image: url(/assets/img/eat_icon_02.svg);
          background-size: 18px auto;
          background-repeat: no-repeat;
          background-position: left center;
        }
      }
      a {
        text-decoration: none;
        color: #5c5c5c;
      }
    }
    .top-page-store-area {
      margin-top: 10px;
      padding-bottom: 27px;
    }
    .top-page-sort-restaurant-btn {
      color: #5c5c5c;
      display: flex;
      justify-content: center;
      p {
        margin: 15px 20px;
      }
    }
    .top-page-sort-restaurant-btn-active {
      color: #ff5800;
    }
    .top-page-stores-title {
      padding: 30px 14px 0px 14px;
      color: #5c5c5c;
      font-size: 22px;
    }
    .top-page-stores-description {
      padding: 20px 14px 0px;
      color: #5c5c5c;
      line-height: 2rem;
    }
    .top-page-store-tab-container {
      background-color: #e6e6ee;
      color: #5c5c5c;
    }
    .top-page-store-tab {
      display: flex;
      justify-content: center;
      li {
        margin-top: 14px;
        margin-bottom: 14px;
        padding: 8px 15px;
        border-radius: 30px;
        box-sizing: border-box;
        & + li {
          margin-left: 15px;
        }
        cursor: pointer;
      }
    }
    .top-page-store-active {
      background-color: #ff5800;
      color: #fff;
    }
    .top-page-store-card-container {
      margin-top: 47px;
      margin-left: 14px;
      margin-right: 14px;
      box-sizing: border-box;
    }
    .top-page-store-card-box {
      position: relative;
      padding-bottom: 10px;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      &::after {
        position: absolute;
        top: 5px;
        right: 5px;
        padding: 3px;
        border-radius: 4px;
        color: #ffffff;
      }
      & + li {
        margin-top: 37px;
      }
    }
    .top-page-store-card-menu {
      &::after {
        content: 'MENU';
        position: absolute;
        top: 5px;
        right: 5px;
        padding: 3px;
        background-color: #ff5800;
        border-radius: 4px;
        color: #ffffff;
      }
    }
    .top-page-store-card-seat {
      &::after {
        content: 'SEAT';
        background-color: rgba(244, 244, 244, 0.72);
        color: #000;
      }
    }
    .top-page-store-card-title-box {
      position: relative;
      margin-top: 10px;
      margin-left: 10px;
      margin-right: 10px;
      padding-left: 50px;
    }
    .top-page-store-card-person {
      position: absolute;
      top: 0;
      left: 0;
      width: 32px;
      height: 32px;
    }
    .top-page-store-card-title-en {
      font-size: 14px;
      font-size: 1.4rem;
      font-weight: $font-medium;
    }
    .top-page-store-card-title-ja {
      margin-top: 5px;
      font-size: 13px;
      font-size: 1.3rem;
      font-weight: $font-normal;
      color: #9d9d9d;
    }
    .top-page-store-card-line {
      margin-top: 0;
      border-style: dotted;
    }
    .top-page-store-card-place {
      padding-left: 20px;
      background-image: url(/assets/img/pin_icon_01.svg);
      background-size: 13px auto;
      background-repeat: no-repeat;
      background-position: left center;
      font-size: 12px;
      font-size: 1.2rem;
      font-weight: $font-normal;
      line-height: 2rem;
    }
    .top-page-store-card-description {
      margin-top: 5px;
      font-size: 12px;
      font-size: 1.2rem;
      font-weight: $font-normal;
      color: #5c5c5c;
      line-height: 1.8rem;
    }
    .top-page-store-pager {
      display: flex;
      width: 290px;
      margin: 45px auto 0;
      a {
        width: 30px;
        line-height: 3rem;
        text-align: center;
        font-size: 12px;
        font-size: 1.2rem;
        display: inline-block;
        text-decoration: none;
        color: #9d9d9d;
        &.top-page-store-pager-active {
          color: #000000;
          background-color: #e6e6e6;
          border-radius: 50%;
        }
      }
    }
    &.campaign {
      main {
        background: url('https://s3-ap-northeast-1.amazonaws.com/files.jpneazy.com/campaign/background_sp.jpg');
      }
    }

    .chat-button {
      position: absolute;
      top: 50px;
      right: 35px;
      z-index: 10;
      border: 1px solid #ff5800;
      border-radius: 55px;
      width: 60px;
      height: 60px;
      padding: 10px;
      background-color: #ff5800;
    }

    .chat-button:hover {
      cursor: pointer;
    }

    .chat-content {
      position: relative;
    }

    .chat-icon {
      position: absolute;
      top: 9px;
      left: 24px;
      width: 30px;
    }

    .chat-text {
      color: #fff;
      font-size: 11px;
      font-family: 'Sawarabi Mincho';
      position: absolute;
      top: 43px;
      left: 1px;
      text-align: center;
      line-height: 1.4;
    }
    .chat-ribbon {
      color: #ff5800;
      font-size: 24px;
      font-family: 'Sawarabi Mincho';
      position: absolute;
      top: 5px;
      left: 15px;
      text-align: center;
      line-height: 1.4;
    }

    .chat-community {
      color: #ff5800;
      font-size: 24px;
      font-family: 'Sawarabi Mincho';
      position: absolute;
      top: 5px;
      left: 22px;
      text-align: center;
      line-height: 1.4;
    }
  }
}
