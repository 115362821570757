// =====================================
// PC
// =====================================

.chefle-reservation {
  font-family: Poppins;
  @at-root {
    table {
      width: 95%;
      margin: 10px;
      line-height: 1.4;
      font-size: 15px;
    }
    #{&}__head {
      border-bottom: 2px solid lightgray;
      color: gray;
    }
    #{&}__th {
      padding-bottom: 10px;
    }
    #{&}__link {
      color: #333;
      &:hover {
        opacity: 0.5;
      }
    }
    #{&}__warning {
      color: orange;
      font-size: 18px;
    }
    #{&}__past {
      margin: 50px 0 !important;
    }

    #{&}__detail {
      padding: 0 30px;
    }

    #{&}__detail-head {
      display: flex;
      background-color: lightgray;
      padding: 5px 10px;
      margin-bottom: 20px;
    }

    #{&}__detail-number {
      margin-right: 50px;
      display: flex;
      align-items: flex-end;
    }

    #{&}__detail-status {
      display: flex;
      align-items: flex-end;
    }

    #{&}__detail-restaurant-name {
      font-size: 21px;
      color: darkslategray;
      font-weight: bold;
      margin-bottom: 10px;
      margin-top: 30px;
    }

    #{&}__detail-restaurant-tel {
      display: block;
      text-align: right;
      color: #333;
      font-size: 17px;
      margin-bottom: 5px;
    }

    #{&}__detail-restaurant-time {
      font-weight: bold;
      margin-bottom: 10px;
    }

    #{&}__detail-order {
      display: flex;
      margin-top: 50px;
      border-bottom: 1px solid lightgray;
      padding-bottom: 10px;
    }

    #{&}__detail-order-left {
      word-break: keep-all;
    }

    #{&}__detail-order-right {
      margin-left: 70px;
    }

    #{&}__detail-price {
      display: flex;
      margin-top: 20px;
      border-bottom: 1px solid lightgray;
      padding-bottom: 10px;
    }

    #{&}__detail-price-left {
      word-break: keep-all;
    }

    #{&}__detail-price-right {
      margin-left: 100px;
    }

    #{&}__detail-guest {
      display: flex;
      margin-top: 20px;
      border-bottom: 1px solid lightgray;
      padding-bottom: 10px;
    }

    #{&}__detail-guest-left {
      word-break: keep-all;
    }

    #{&}__detail-guest-right {
      margin-left: 65px;
    }

    #{&}__detail-family {
      font-weight: bold;
    }

    #{&}__back {
      color: #333;
      display: block;
      text-align: right;
      margin-top: 25px;
    }

    #{&}__icon-phone {
      color: #333;
      margin-right: 5px;
    }

    #{&}__icon-calendar {
      color: #333;
      margin-right: 15px;
    }
    #{&}__icon-person {
      color: #333;
      margin-right: 15px;
      font-size: 22px;
    }

    #{&}__icon-times {
      position: absolute;
      top: 10px;
      right: 14px;
      font-size: 34px;
      color: gray;
    }

    #{&}__modal-head {
      text-align: center;
      padding-top: 15px;
      font-size: 24px;
      margin-bottom: 40px;
    }

    #{&}__modal-content {
      display: flex;
      margin-top: 15px;
      border-bottom: 1px solid lightgray;
      padding-bottom: 5px;
      line-height: 1.2;
    }

    #{&}__modal-content-left {
      word-break: keep-all;
    }

    #{&}__image {
      width: 200px;
      height: 150px;
      object-fit: cover;
      margin-right: 30px;
    }

    #{&}__information {
      display: flex;
    }
  }
}

// =====================================
// SP
// =====================================
@media screen and (max-width: 480px) {
  .chefle-reservation {
    @at-root {
      #{&}__past {
        margin: 30px 10px !important;
      }

      table {
        font-size: 13px;
      }

      #{&}__details {
        margin-bottom: 30px !important;
      }

      #{&}__detail-head {
        margin-top: 20px;
      }

      #{&}__detail-restaurant-tel {
        font-size: 14px;
      }

      #{&}__detail-restaurant-name {
        font-size: 18px;
      }

      #{&}__detail-price-right {
        margin-left: 96px;
      }

      #{&}__icon-times {
        font-size: 28px;
      }

      #{&}__image {
        width: 250px;
        margin-bottom: 10px;
        margin-right: 0;
      }

      #{&}__information {
        display: block;
        text-align: center;
      }

      #{&}__detail-restaurant-time {
        text-align: left;
      }

      #{&}__detail-family {
        text-align: left;
      }

      #{&}__detail-number {
        display: block;
      }

      #{&}__detail-status {
        display: block;
      }
    }
  }
}
