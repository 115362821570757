// =====================================
// PC
// =====================================

@media screen and (min-width: 769px) {
  // ヘルプアイコン
  .help-icon {
    position: fixed;
    top: 70px;
    right: 0;
    z-index: 10;
  }
  .alternative-date {
    height: 30px;
    padding: 14px 0 0;
  }
  .party-size-title {
    padding: 20px 0 0;
    color: #5c5c5c;
    font-weight: 500;
  }
  .use-point-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .use-point-title {
      width: 80%;
    }
  }
  // フォームボタン
  .conditions-chice {
    display: flex;
    width: 540px;
    margin-top: 20px;
    justify-content: space-between;
    box-sizing: border-box;
    li {
      display: block;
      width: 100%;
      padding-top: 30px;
      padding-bottom: 4px;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: center 5px;
      border-radius: 3px;
      text-align: center;
      color: #5c5c5c;
      font-size: 14px;
      & + li {
        margin-left: 4px;
      }
      &.reserve-form-date {
        background-image: url(/assets/img/calendar_icon_02.svg);
        background-size: 16px auto;
        input {
          text-align: center;
        }
      }
      &.reserve-form-time {
        background-image: url(/assets/img/time_icon_02.svg);
        background-size: 17px auto;
      }
      &.reserve-form-side {
        background-image: url(/assets/img/people_icon_02.svg);
        background-size: 19px auto;
        background-position: center 7px;
      }
      &.reserve-form-adult {
        background-image: url(/assets/img/adult.svg);
        background-size: 19px auto;
        background-position: center 7px;
      }
      &.reserve-form-child {
        background-image: url(/assets/img/child.svg);
        background-size: 19px auto;
        background-position: center 7px;
      }
      &.reserve-form-baby {
        background-image: url(/assets/img/baby.svg);
        background-size: 19px auto;
        background-position: center 7px;
      }
    }
  }

  // 予約フォームボックス２
  .reserve-form-guest-ditails {
    width: 540px;
    padding-top: 24px;
    padding-bottom: 40px;
    margin-top: 18px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12);
    input,
    select {
      font-size: 12px;
      &::placeholder {
        color: #9d9d9d;
      }
      &:focus {
        outline: 0;
        border-bottom: #ff5800 2px solid;
      }
    }
    & + .reserve-form-guest-ditails {
      margin-top: 18px;
    }
    .block-title {
      position: relative;
      padding-left: 20px;
      border-left: #ff5800 2px solid;
      font-weight: $font-medium;
      line-height: 19px;
      color: #5c5c5c;
      font-size: 16px;
      margin-bottom: 50px;
    }
    .register-merit {
      position: absolute;
      left: 145px;
      top: 3px;
      font-weight: 500;
      line-height: 1.4;
      color: #5c5c5c;
      font-size: 12px;
      width: 375px;
    }
    .reserve-form-input-area {
      margin-top: 14px;
      margin-left: 22px;
      margin-right: 22px;
      li {
        &:nth-child(2) {
          margin-top: 12px;
        }
        &:nth-child(n + 3) {
          margin-top: 30px;
        }
      }
    }
    .reserve-form-txt {
      margin-bottom: 5px;
      color: #9d9d9d;
      font-size: 9px;
    }
    .reserve-form-txt-first {
      color: #ff5800;
    }
    .reserve-input-txt {
      width: 100%;
      border: none;
      border-bottom: #ccc 1px solid;
    }
    .reserve-input-required {
      background-image: url(/assets/img/req_icon_01.svg);
      background-size: 24px auto;
      background-repeat: no-repeat;
      background-position: left center;
      padding-left: 28px;
      box-sizing: border-box;
    }
    .reserve-form-input-two-block {
      display: flex;
      justify-content: space-between;
    }
    .reserve-form-input-country {
      width: 24%;
      background-color: #fff;
      border-radius: 0;
      color: #9d9d9d;
      background-image: url(/assets/img/arrow_icon_04.svg);
      background-size: 8px auto;
      background-repeat: no-repeat;
      background-position: right center;
    }
    .reserve-form-input-phone {
      width: 73%;
    }
    .reserve-form-rest-info {
      margin-top: 13px;
    }
  }

  .policy-link {
    color: #9d9d9d;
    text-decoration: underline;
    margin: 0 auto;
    cursor: pointer;
  }
  .policy-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    padding-right: 13px;
    padding-left: 13px;
    .read-box {
      width: 37%;
      border: 1px solid #9d9d9d;
      border-radius: 3px;
      line-height: 37px;
      text-align: center;
      font-size: 14px;
      color: #9d9d9d;
      font-weight: 400;
    }
  }

  .reserve-form-submit-container {
    padding-top: 30px;
    padding-bottom: 25px;
    background-color: #ffffff;
    border-radius: 16px;
    .payment-type {
      display: block;
      margin: 26px auto 0;
      width: 320px;
      box-sizing: border-box;
    }
  }

  // 送信ボタン
  .reserve-confirm-btn {
    width: 346px;
    input {
      width: 100%;
      height: 54px;
      box-sizing: border-box;
      background-color: #71d57d;
      border: none;
      border-radius: 8px;
      box-shadow: 0px 3px 10px rgba(113, 213, 125, 0.48);
      color: #fff;
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;
    }
    input[type='submit'][disabled] {
      background-color: #bcbcbc;
    }
  }
  .form-footer {
    display: none;
  }

  // 送信ボタン
  .reserve-submit-btn {
    width: '298px';
    margin-top: '30px';
    input {
      width: 100%;
      height: 54px;
      box-sizing: border-box;
      background-color: #71d57d;
      border: none;
      border-radius: 8px;
      box-shadow: 0px 3px 10px rgba(113, 213, 125, 0.48);
      color: #fff;
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .form-footer {
    display: none;
  }

  .reserve-form-notification {
    margin-bottom: 32px;
    padding-left: 14px;
    padding-right: 14px;
    font-weight: $font-light;
    color: #ff7474;
    font-size: 16px;
  }
  .reserve-form-notification-title {
    margin-bottom: 10px;
    padding-left: 30px;
    background-image: url(/assets/img/bell_icon_02.svg);
    background-size: 23px;
    background-repeat: no-repeat;
    font-weight: $font-normal;
    line-height: 24px;
    color: #ff7474;
  }
  .reserve-form-notification-txt {
    line-height: 22px;
    font-size: 14px;
  }

  // キャンセルポリシー
  .reserve-form-cancelpolicy {
    margin-bottom: 32px;
    padding-left: 14px;
    padding-right: 14px;
    font-weight: $font-light;
    color: #ff7474;
    font-size: 16px;
  }
  .reserve-form-cancelpolicy-title {
    margin-bottom: 10px;
    padding-left: 30px;
    background-image: url(/assets/img/bell_icon_02.svg);
    background-size: 23px;
    background-repeat: no-repeat;
    font-weight: $font-normal;
    line-height: 24px;
    color: #ff7474;
  }
  .reserve-form-cancelpolicy-txt {
    line-height: 22px;
    font-size: 14px;
  }

  // ユーザー情報
  .reserve-gest-details {
    margin-top: 26px;
    padding-top: 15px;
    padding-bottom: 19px;
    background-color: #fff;
    border-radius: 8px;
    color: #9d9d9d;
    .reserve-gest-details-title {
      margin-top: 7px;
      margin-left: 15px;
      font-size: 16px;
      font-weight: $font-medium;
    }
    .reserve-gest-details-list {
      padding-top: 15px;
      li {
        margin-top: 13px;
        margin-left: 15px;
        margin-right: 15px;
        border-bottom: 1px dotted #000;
      }
    }
    .reserve-gest-details-head {
      font-size: 9px;
    }
    .reserve-gest-details-txt {
      margin-top: 9px;
      margin-bottom: 3px;
      color: #000;
      font-size: 12px;
    }
  }

  // 席予約reservation-fee
  .reservation-fee-01 {
    position: relative;
    padding-top: 10px;
    padding-right: 14px;
    padding-left: 14px;
    padding-bottom: 0;
    box-shadow: none;
    background-color: #f4f4f4;
    border-radius: 8px;
    .reservation-fee-title {
      margin-bottom: 18px;
      font-size: 18px;
      font-weight: $font-medium;
      line-height: 33px;
      color: #ff5800;
    }
    .reservation-fee-num {
      position: absolute;
      top: 17px;
      right: 30px;
      padding-left: 33px;
      background-image: url(/assets/img/people_icon_02.svg);
      background-size: 22px auto;
      background-repeat: no-repeat;
      background-position: left top;
      font-size: 18px;
      font-weight: $font-medium;
      color: #ff5800;
    }
    .reservation-fee-menu {
      position: absolute;
      top: 30px;
      right: 20px;
      width: 30px;
      height: auto;
    }
    .reservation-fee-txt {
      font-size: 12px;
      line-height: 16px;
      border-bottom: 1px solid #ccc;
      text-align: center;
      padding: 0 0 10px 0;
      font-weight: $font-normal;
      line-height: 20px;
      span {
        color: #9d9d9d;
      }
    }
    .reservation-fee-notes {
      margin-top: 10px;
      margin-bottom: 30px;
      padding-left: 26px;
      background-image: url(/assets/img/bell_icon_02.svg);
      background-size: 22px auto;
      background-repeat: no-repeat;
      background-position: left 5px;
      font-size: 12px;
      line-height: 26px;
      color: #ff5800;
      span {
        color: #9d9d9d;
      }
    }
    .reservation-fee-line-box {
      position: relative;
      height: 20px;
      margin: 0 -13px;
      padding: 0 13px;
      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: -10px;
        z-index: 1;
        width: 20px;
        height: 20px;
        transform: rotate(45deg);
        background-color: #fff;
      }
      &::before {
        left: -13px;
      }
      &::after {
        right: -13px;
      }
    }
    .reservation-fee-line {
      margin: 0;
      border: none;
      border-top: 1px dotted #5c5c5c;
    }
    .reservation-fee-type {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 40px;
      background-image: url(/assets/img/barcode_01.svg);
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: 0 bottom;
    }
    .reservation-fee-conditions {
      display: block;
      width: 49%;
      font-size: 16px;
      color: #5c5c5c;
      line-height: 28px;
      font-weight: $font-medium;
      span {
        display: inline-block;
        font-size: 14px;
        font-weight: $font-normal;
        color: #9d9d9d;
      }
    }
    .reservation-fee-price {
      width: 49%;
      padding-right: 10px;
      box-sizing: border-box;
      background-color: #fff;
      background-image: url(/assets/img/card_icon_01.svg);
      background-size: 24px auto;
      background-repeat: no-repeat;
      background-position: 15px center;
      border-radius: 5px;
      color: #5c5c5c;
      line-height: 44px;
      text-align: right;
      font-size: 12px;
      span {
        font-size: 14px;
      }
    }
    .reservation-fee-box_in {
      border: 1px solid #ccc;
      border-radius: 10px;
      padding: 10px 20px 0 20px;
    }
    .reservation-fee-box_in_in {
      display: table;
      text-align: center;
      width: 100%;
    }
    .reservation-fee-box_in_in_l {
      display: table-cell;
      padding: 30px 2% 10px 0;
      font-size: 13px;
      width: 48%;
      line-height: 1.6;
      border-right: 1px dotted #ccc;
      background: url(../img/person_ico1.svg) center 10px no-repeat;
      background-size: 20px 16px;
      span {
        display: block;
        font-size: 14px;
      }
    }
    .reservation-fee-box_in_in_r {
      display: table-cell;
      padding: 30px 0 10px 2%;
      font-size: 13px;
      width: 48%;
      line-height: 1.6;
      background: url(../img/person_ico2.svg) center 10px no-repeat;
      background-size: 40px 16px;
      span {
        display: block;
        font-size: 14px;
      }
    }
  }

  // メニュー予約reservation-fee
  .reservation-fee-02 {
    position: relative;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 50px;
    background-image: url(/assets/img/barcode_01.svg);
    background-size: calc(100% - 40px) auto;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-color: #f4f4f4;
    box-shadow: none;
    border-radius: 8px;
    .reservation-fee-title {
      font-size: 18px;
      font-weight: $font-medium;
      line-height: 3.3rem;
      color: #ff5800;
    }
    .reservation-fee-menu {
      position: absolute;
      top: 17px;
      right: 20px;
      width: 30px;
      height: auto;
    }
    .reserve-form-contents-menu {
      padding-top: 14px;
      padding-bottom: 60px;
      li {
        position: relative;
        color: #5c5c5c;
        + li {
          margin-top: 26px;
        }
      }
    }
    .reservation-fee-line-box {
      position: relative;
      height: 20px;
      margin: 0 -13px;
      padding: 0 13px;
      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: -10px;
        z-index: 1;
        width: 20px;
        height: 20px;
        transform: rotate(45deg);
        background-color: #fff;
      }
      &::before {
        left: -13px;
      }
      &::after {
        right: -13px;
      }
    }
    .reservation-fee-line {
      margin: 0;
      border: none;
      border-top: 1px dotted #5c5c5c;
    }
    .reserve-form-contents-fee {
      margin-top: 8px;
      color: #9d9d9d;
      font-size: 10px;
      font-weight: $font-normal;
    }
    .reserve-form-contents-menu-number {
      position: absolute;
      top: 0;
      width: 16px;
      height: 16px;
      margin: auto;
      background-color: #b5b5b5;
      border-radius: 50%;
      font-size: 10px;
      font-weight: $font-medium;
      text-align: center;
      line-height: 19px;
      color: #ffffff;
    }
    .reserve-form-contents-box {
      position: relative;
      padding-left: 15px;
      font-size: 14px;
      line-height: 18px;
      &::before {
        content: '・';
        position: absolute;
        left: 0;
        color: #ff5800;
      }
    }
    .reserve-form-contents-box-menu {
      font-weight: $font-medium;
    }
    .reserve-form-contents-box-course {
      margin-top: 6px;
      font-weight: $font-normal;
    }
    .reserve-form-contents-box-price {
      display: inline-block;
      margin-top: 15px;
      font-size: 12px;
      font-weight: $font-normal;
      border-bottom: 1px solid #ccc;
    }
    .reserve-form-contents-box-price-txt {
      color: #9d9d9d;
    }
    .reserve-form-contents-last-box {
      text-align: right;
    }
    .reserve-form-contents-point {
      display: inline-block;
      margin-left: auto;
      background-size: 24px;
      background-repeat: no-repeat;
      background-position: 15px center;
      background-color: #f4f4f4;
      border-radius: 8px;
      line-height: 20px;
      font-size: 12px;
      color: #5c5c5c;
      font-weight: $font-normal;
      display: flex;
      flex-direction: column;
      span {
        font-size: 14px;
        width: 50px;
        justify-content: flex-end;

        font-weight: $font-medium;
      }
    }
    .reserve-form-contents-last {
      display: inline-block;
      margin-left: auto;
      padding-left: 47px;
      background-image: url(/assets/img/card_icon_01.svg);
      background-size: 24px;
      background-repeat: no-repeat;
      background-position: 15px center;
      background-color: #f4f4f4;
      border-radius: 8px;
      line-height: 20px;
      font-size: 12px;
      color: #5c5c5c;
      font-weight: $font-normal;
      span {
        font-size: 14px;
        font-weight: $font-medium;
      }
    }
  }
}

// =====================================
// SP
// =====================================

@media screen and (max-width: 768px) {
  // 送信ボタン
  .reserve-submit-btn {
    margin-top: 18px;
    margin-right: 23px;
    margin-left: 23px;

    input {
      width: 100%;
      box-sizing: border-box;
      background-color: #71d57d;
      border: none;
      border-radius: 8px;
      box-shadow: 0px 3px 10px rgba(113, 213, 125, 0.48);
      line-height: 7rem;
      color: #fff;
      font-weight: 500;
      font-size: 16px;
      font-size: 1.6rem;
      cursor: pointer;
    }
    input[type='submit'][disabled] {
      background-color: #bcbcbc;
    }
  }
  // 送信ボタン
  .reserve-confirm-btn {
    margin-top: 18px;
    margin-right: 23px;
    margin-left: 23px;

    input {
      width: 100%;
      box-sizing: border-box;
      background-color: #71d57d;
      border: none;
      border-radius: 8px;
      box-shadow: 0px 3px 10px rgba(113, 213, 125, 0.48);
      line-height: 7rem;
      color: #fff;
      font-weight: 500;
      font-size: 16px;
      font-size: 1.6rem;
      cursor: pointer;
    }
    input[type='submit'][disabled] {
      background-color: #bcbcbc;
    }
  }

  // 確認事項エリア
  .policy-link {
    color: #9d9d9d;
    text-decoration: underline;
    margin: 0 auto;
    cursor: pointer;
  }
  .policy-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    padding-right: 13px;
    padding-left: 13px;
    .read-box {
      width: 37%;
      border: 1px solid #9d9d9d;
      border-radius: 3px;
      line-height: 37px;
      text-align: center;
      font-size: 14px;
      font-size: 1.4rem;
      color: #9d9d9d;
      font-weight: 400;
    }
  }
  .alternative-date {
    height: 30px;
    padding: 14px 14px 0;
  }
  .party-size-title {
    padding: 20px 14px 0;
    color: #5c5c5c;
    font-weight: 500;
  }
  .use-point-content {
    display: flex;
    .use-point-title {
      margin-right: 10px;
    }
  }
  // フォームボタン
  .conditions-chice {
    display: flex;
    margin-top: 12px;
    margin-right: 11px;
    margin-left: 11px;
    justify-content: space-between;
    box-sizing: border-box;
    li {
      display: block;
      width: 100%;
      padding-top: 30px;
      padding-bottom: 4px;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: center 5px;
      border-radius: 3px;
      text-align: center;
      color: #5c5c5c;
      font-size: 14px;
      font-size: 1.4rem;
      & + li {
        margin-left: 4px;
      }
      &.reserve-form-date {
        background-image: url(/assets/img/calendar_icon_02.svg);
        background-size: 16px auto;
        input {
          text-align: center;
          transform: scale(0.834);
        }
      }
      &.reserve-form-time {
        background-image: url(/assets/img/time_icon_02.svg);
        background-size: 17px auto;
      }
      &.reserve-form-side {
        background-image: url(/assets/img/people_icon_02.svg);
        background-size: 19px auto;
        background-position: center 7px;
      }
      &.reserve-form-adult {
        background-image: url(/assets/img/adult.svg);
        background-size: 19px auto;
        background-position: center 7px;
      }
      &.reserve-form-child {
        background-image: url(/assets/img/child.svg);
        background-size: 19px auto;
        background-position: center 7px;
      }
      &.reserve-form-baby {
        background-image: url(/assets/img/baby.svg);
        background-size: 19px auto;
        background-position: center 7px;
      }
    }
  }

  //予約内容
  .reserve-confirmation-matter {
    margin-left: 11px;
    margin-right: 11px;
    padding-top: 30px;
    li {
      padding-left: 35px;
      background-repeat: no-repeat;
      background-position: left center;
      border-bottom: 1px dotted #5c5c5c;
      line-height: 3rem;
      color: #5c5c5c;
      font-size: 16px;
      font-size: 1.6rem;
      font-weight: $font-normal;
      &.reserve-confirmation-date {
        background-image: url(/assets/img/calendar_icon_01.svg);
        background-size: 16px auto;
      }
      &.reserve-confirmation-time {
        background-image: url(/assets/img/time_icon_01.svg);
        background-size: 16px auto;
      }
      &.reserve-confirmation-people {
        background-image: url(/assets/img/people_icon_01.svg);
        background-size: 19px auto;
      }
      &.reserve-confirmation-store {
        background-image: url(/assets/img/store_icon_01.svg);
        background-size: 18px auto;
      }
      & + li {
        margin-top: 15px;
      }
    }
  }

  // ユーザー情報
  .reserve-gest-details {
    margin-top: 26px;
    margin-left: 11px;
    margin-right: 11px;
    padding-top: 15px;
    padding-bottom: 19px;
    background-color: #fff;
    border-radius: 8px;
    color: #9d9d9d;
    .reserve-gest-details-title {
      margin-top: 7px;
      margin-left: 15px;
      font-size: 16px;
      font-size: 1.6rem;
      font-weight: $font-medium;
    }
    .reserve-gest-details-list {
      li {
        margin-top: 13px;
        margin-left: 15px;
        margin-right: 15px;
        border-bottom: 1px dotted #000;
      }
    }
    .reserve-gest-details-head {
      font-size: 9px;
      font-size: 0.9rem;
    }
    .reserve-gest-details-txt {
      margin-top: 9px;
      margin-bottom: 3px;
      color: #000;
      font-size: 12px;
      font-size: 1.2rem;
    }
  }

  .reserve-form-notification {
    margin-top: 30px;
    margin-bottom: 35px;
    padding-left: 16px;
    padding-right: 16px;
    font-weight: $font-light;
    color: #ff7474;
  }
  .reserve-form-notification-title {
    margin-bottom: 10px;
    padding-left: 30px;
    background-image: url(/assets/img/bell_icon_02.svg);
    background-size: 23px;
    background-repeat: no-repeat;
    font-weight: $font-normal;
    line-height: 2.4rem;
    color: #ff7474;
  }
  .reserve-form-notification-txt {
    line-height: 2.2rem;
    font-size: 14px;
    font-size: 1.4rem;
  }
  // キャンセルポリシー
  .reserve-form-cancelpolicy {
    margin-top: 30px;
    margin-bottom: 35px;
    padding-left: 16px;
    padding-right: 16px;
    font-weight: $font-light;
    color: #ff7474;
  }
  .reserve-form-cancelpolicy-title {
    margin-bottom: 10px;
    padding-left: 30px;
    background-image: url(/assets/img/bell_icon_02.svg);
    background-size: 23px;
    background-repeat: no-repeat;
    font-weight: $font-normal;
    line-height: 2.4rem;
    color: #ff7474;
  }
  .reserve-form-cancelpolicy-txt {
    line-height: 2.2rem;
    font-size: 14px;
    font-size: 1.4rem;
  }

  // 予約フォームボックス１
  .reserve-form-contents {
    position: relative;
    margin-left: 14px;
    margin-right: 14px;
    padding-top: 30px;
    padding-bottom: 50px;
    border-radius: 8px;
    background-color: #fff;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      width: calc(100% - 40px);
      height: 20px;
      margin: auto;
      box-sizing: border-box;
      background-image: url(/assets/img/barcode_01.svg);
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: 0 bottom;
    }
    .reserve-form-contents-name-box {
      padding-left: 22px;
      padding-right: 22px;
      border-left: 2px solid #ff5800;
    }
    .reserve-form-contents-name-en {
      margin-bottom: 7px;
      font-size: 16px;
      font-size: 1.6rem;
      color: #5c5c5c;
      line-height: 1.6rem;
    }
    .reserve-form-contents-name-ja {
      font-size: 12px;
      font-size: 1.2rem;
      color: #5c5c5c;
      line-height: 1.2rem;
    }
    .conditions-chice-confirmation {
      margin-top: 34px;
      margin-left: 17px;
      margin-right: 17px;
      li {
        display: inline-block;
        padding-left: 24px;
        background-repeat: no-repeat;
        background-position: left center;
        line-height: 1.8rem;
        & + li {
          margin-left: 25px;
        }
      }
      .conditions-chice-confirmation-date {
        background-image: url(/assets/img/calendar_icon_01.svg);
        background-size: 16px auto;
      }
      .conditions-chice-confirmation-time {
        background-image: url(/assets/img/time_icon_01.svg);
        background-size: 17px auto;
      }
      .conditions-chice-confirmation-person {
        background-image: url(/assets/img/people_icon_01.svg);
        background-size: 19px auto;
      }
    }
    .reserve-form-contents-menu {
      margin-left: 12px;
      margin-right: 12px;
      padding-top: 28px;
      padding-bottom: 14px;
      li {
        position: relative;
        color: #5c5c5c;
        + li {
          margin-top: 26px;
        }
      }
    }
    .reservation-content-line-box {
      position: relative;
      height: 20px;
      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: -10px;
        z-index: 1;
        width: 20px;
        height: 20px;
        transform: rotate(45deg);
        background-color: #f4f4f4;
      }
      &::before {
        left: -13px;
      }
      &::after {
        right: -13px;
      }
    }
    .reservation-content-line {
      margin: 0 21px;
      border: none;
      border-top: 1px dotted #5c5c5c;
    }
    .reserve-form-contents-fee {
      margin-top: 5px;
      padding-left: 22px;
      padding-right: 22px;
      color: #5c5c5c;
      font-size: 14px;
      font-size: 1.4rem;
      font-weight: $font-normal;
    }
    .reserve-form-contents-menu-number {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 20px;
      height: 20px;
      margin: auto;
      background-color: #e6e6e6;
      border-radius: 50%;
      font-size: 12px;
      font-size: 1.2rem;
      font-weight: $font-medium;
      text-align: center;
      line-height: 2.1rem;
    }
    .reserve-form-contents-box {
      padding-left: 35px;
      font-size: 14px;
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
    .reserve-form-contents-box-menu {
      font-weight: $font-medium;
    }
    .reserve-form-contents-box-course {
      margin-top: 6px;
      font-weight: $font-normal;
    }
    .reserve-form-contents-last {
      display: inline-block;
      margin-top: 20px;
      margin-left: 22px;
      padding-left: 47px;
      padding-right: 26px;
      background-image: url(/assets/img/card_icon_01.svg);
      background-size: 24px;
      background-repeat: no-repeat;
      background-position: 15px center;
      background-color: #f4f4f4;
      border-radius: 8px;
      line-height: 3.6rem;
      font-size: 12px;
      font-size: 1.2rem;
      color: #5c5c5c;
      font-weight: $font-normal;
      span {
        font-size: 14px;
        font-size: 1.4rem;
        font-weight: $font-medium;
      }
    }
  }

  // 予約フォームボックス２
  .reserve-form-container {
    padding-bottom: 35px;
    padding-top: 11px;
    margin-top: 11px;
    margin-right: 11px;
    margin-left: 11px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12);
    input,
    select {
      font-size: 1.2rem;
      &::placeholder {
        color: #9d9d9d;
      }
      &:focus {
        outline: 0;
        border-bottom: #ff5800 2px solid;
      }
    }
    & + .reserve-form-container {
      margin-top: 18px;
    }
    .block-title {
      padding-left: 20px;
      border-left: #ff5800 2px solid;
      font-weight: $font-medium;
      line-height: 1.9rem;
      color: #5c5c5c;
    }
    .register-merit {
      font-weight: 500;
      line-height: 1.4;
      color: #5c5c5c;
      font-size: 12px;
      padding: 20px 22px 0px 0px;
    }
    .reserve-form-input-area {
      margin-top: 14px;
      margin-left: 22px;
      margin-right: 22px;
      li {
        &:nth-child(2) {
          margin-top: 12px;
        }
        &:nth-child(n + 3) {
          margin-top: 18px;
        }
      }
    }
    .reserve-form-txt {
      margin-bottom: 5px;
      color: #9d9d9d;
      font-size: 0.9rem;
    }
    .reserve-form-txt-first {
      color: #ff5800;
    }
    .reserve-input-txt {
      width: 100%;
      border: none;
      border-bottom: #ccc 1px solid;
    }
    .reserve-input-required {
      background-image: url(/assets/img/req_icon_01.svg);
      background-size: 24px auto;
      background-repeat: no-repeat;
      background-position: left center;
      padding-left: 28px;
      box-sizing: border-box;
    }
    .reserve-form-input-two-block {
      display: flex;
      justify-content: space-between;
    }
    .reserve-form-input-country {
      width: 24%;
      background-color: #fff;
      border-radius: 0;
      color: #9d9d9d;
    }
    .reserve-form-input-phone {
      width: 73%;
    }
    .reserve-form-rest-info {
      margin-top: 13px;
    }
  }

  // フッター固定
  .form-footer {
    position: fixed;
    bottom: 0;
    z-index: 11;
    display: block;
    width: 100%;
    height: 54px;
    background-image: url(/assets/img/display_bottom_fixed.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .form-footer-inner {
      position: relative;
    }
    .form-footer-ask {
      position: absolute;
      top: -33px;
      left: 0;
      right: 0;
      width: 67px;
      height: 67px;
      margin: auto;
    }
    .form-footer-back {
      position: absolute;
      top: 10px;
      left: 20%;
      width: 28px;
      height: 36px;
    }
    .form-footer-help-01 {
      position: absolute;
      top: 10px;
      right: 20%;
      width: 22px;
      height: 35px;
    }
    .form-footer-help-02 {
      position: absolute;
      top: 13px;
      right: 20%;
      width: 23px;
      height: auto;
    }
  }

  // 席予約reservation-fee
  .reservation-fee-01 {
    position: relative;
    padding-right: 14px;
    padding-left: 14px;
    padding-bottom: 0;
    box-shadow: none;
    .reservation-fee-title {
      margin-top: 10px;
      margin-bottom: 18px;
      font-size: 18px;
      font-size: 1.8rem;
      font-weight: $font-medium;
      line-height: 3.3rem;
      color: #ff5800;
    }
    .reservation-fee-num {
      position: absolute;
      top: 30px;
      right: 40px;
      padding-left: 33px;
      background-image: url(/assets/img/people_icon_02.svg);
      background-size: 22px auto;
      background-repeat: no-repeat;
      background-position: left top;
      font-size: 18px;
      font-size: 1.8rem;
      font-weight: $font-medium;
      color: #ff5800;
    }
    .reservation-fee-menu {
      position: absolute;
      top: 30px;
      right: 20px;
      width: 30px;
      height: auto;
    }
    .reservation-fee-txt {
      font-size: 12px;
      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: $font-normal;
      line-height: 2rem;
      border-bottom: 1px solid #ccc;
      text-align: center;
      padding: 0 0 10px 0;
      span {
        color: #9d9d9d;
      }
    }
    .reservation-fee-notes {
      margin-top: 10px;
      margin-bottom: 30px;
      padding-left: 26px;
      background-image: url(/assets/img/bell_icon_02.svg);
      background-size: 22px auto;
      background-repeat: no-repeat;
      background-position: left 5px;
      font-size: 12px;
      font-size: 1.2rem;
      line-height: 2.6rem;
      color: #ff5800;
      span {
        color: #9d9d9d;
      }
    }
    .reservation-fee-line-box {
      position: relative;
      height: 20px;
      margin: 0 -13px;
      padding: 0 13px;
      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: -10px;
        z-index: 1;
        width: 20px;
        height: 20px;
        transform: rotate(45deg);
        background-color: #f4f4f4;
      }
      &::before {
        left: -13px;
      }
      &::after {
        right: -13px;
      }
    }
    .reservation-fee-line {
      margin: 0;
      border: none;
      border-top: 1px dotted #5c5c5c;
    }
    .reservation-fee-type {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 40px;
      background-image: url(/assets/img/barcode_01.svg);
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: 0 bottom;
    }
    .reservation-fee-conditions {
      display: block;
      width: 49%;
      font-size: 16px;
      font-size: 1.6rem;
      color: #5c5c5c;
      line-height: 2.8rem;
      font-weight: $font-medium;
      span {
        display: inline-block;
        font-size: 14px;
        font-size: 1.4rem;
        font-weight: $font-normal;
        color: #9d9d9d;
      }
    }
    .reservation-fee-price {
      width: 70%;
      padding-right: 10px;
      box-sizing: border-box;
      background-color: #f4f4f4;
      background-image: url(/assets/img/card_icon_01.svg);
      background-size: 24px auto;
      background-repeat: no-repeat;
      background-position: 15px center;
      border-radius: 5px;
      color: #5c5c5c;
      line-height: 4.4rem;
      text-align: right;
      font-size: 12px;
      font-size: 1.2rem;
      span {
        font-size: 14px;
        font-size: 1.4rem;
      }
    }
    .reservation-fee-box_in {
      border: 1px solid #ccc;
      border-radius: 10px;
      padding: 10px 20px 0 20px;
    }
    .reservation-fee-box_in_in {
      display: table;
      text-align: center;
      width: 100%;
    }
    .reservation-fee-box_in_in_l {
      display: table-cell;
      padding: 30px 2% 10px 0;
      font-size: 13px;
      width: 48%;
      line-height: 1.6;
      border-right: 1px dotted #ccc;
      background: url(../img/person_ico1.svg) center 10px no-repeat;
      background-size: 20px 16px;
      span {
        display: block;
        font-size: 14px;
      }
    }
    .reservation-fee-box_in_in_r {
      display: table-cell;
      padding: 30px 0 10px 2%;
      font-size: 13px;
      width: 48%;
      line-height: 1.6;
      background: url(../img/person_ico2.svg) center 10px no-repeat;
      background-size: 40px 16px;
      span {
        display: block;
        font-size: 14px;
      }
    }
  }
  // メニュー予約reservation-fee
  .reservation-fee-02 {
    position: relative;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 50px;
    background-image: url(/assets/img/barcode_01.svg);
    background-size: calc(100% - 40px) auto;
    background-repeat: no-repeat;
    background-position: center bottom;
    box-shadow: none;
    .reservation-fee-title {
      margin-top: 10px;
      margin-bottom: 18px;
      font-size: 18px;
      font-size: 1.8rem;
      font-weight: $font-medium;
      line-height: 3.3rem;
      color: #ff5800;
    }
    .reservation-fee-menu {
      position: absolute;
      top: 30px;
      right: 20px;
      width: 30px;
      height: auto;
    }
    .reserve-form-contents-menu {
      padding-top: 14px;
      padding-bottom: 14px;
      li {
        position: relative;
        color: #5c5c5c;
        + li {
          margin-top: 26px;
        }
      }
    }
    .reservation-fee-line-box {
      position: relative;
      height: 20px;
      margin: 0 -13px;
      padding: 0 13px;
      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: -10px;
        z-index: 1;
        width: 20px;
        height: 20px;
        transform: rotate(45deg);
        background-color: #f4f4f4;
      }
      &::before {
        left: -13px;
      }
      &::after {
        right: -13px;
      }
    }
    .reservation-fee-line {
      margin: 0;
      border: none;
      border-top: 1px dotted #5c5c5c;
    }
    .reserve-form-contents-fee {
      margin-top: 8px;
      color: #9d9d9d;
      font-size: 10px;
      font-size: 1rem;
      font-weight: $font-normal;
    }
    .reserve-form-contents-menu-number {
      position: absolute;
      top: 0;
      width: 16px;
      height: 16px;
      margin: auto;
      background-color: #b5b5b5;
      border-radius: 50%;
      font-size: 10px;
      font-size: 1rem;
      font-weight: $font-medium;
      text-align: center;
      line-height: 1.9rem;
      color: #ffffff;
    }
    .reserve-form-contents-box {
      position: relative;
      padding-left: 15px;
      font-size: 14px;
      font-size: 1.4rem;
      line-height: 1.8rem;
      &::before {
        content: '・';
        position: absolute;
        left: 0;
        color: #ff5800;
      }
    }
    .reserve-form-contents-box-menu {
      font-weight: $font-medium;
    }
    .reserve-form-contents-box-course {
      margin-top: 6px;
      font-weight: $font-normal;
    }
    .reserve-form-contents-box-price {
      margin-top: 15px;
      font-size: 12px;
      font-size: 1.2rem;
      font-weight: $font-normal;
    }
    .reserve-form-contents-last-box {
      text-align: right;
      margin-top: 5px;
    }
    .reserve-form-contents-last {
      display: inline-block;
      margin-left: auto;
      padding-left: 47px;
      padding-right: 26px;
      background-image: url(/assets/img/card_icon_01.svg);
      background-size: 24px;
      background-repeat: no-repeat;
      background-position: 15px center;
      background-color: #f4f4f4;
      border-radius: 8px;
      line-height: 3.6rem;
      font-size: 12px;
      font-size: 1.2rem;
      color: #5c5c5c;
      font-weight: $font-normal;
      span {
        font-size: 14px;
        font-size: 1.4rem;
        font-weight: $font-medium;
      }
    }
  }
}
