// =====================================
// PC
// =====================================
.community {
  @at-root {
    #{&}__head {
      margin-top: 20px;
      margin-bottom: 40px;
      font-size: 30px;
      text-align: center;
    }

    #{&}__text {
      margin-bottom: 40px;
      font-size: 22px;
      text-align: center;
      line-height: 1.4;
    }
    #{&}__sns {
      display: flex;
      justify-content: center;
      margin-bottom: 50px;
    }

    #{&}__icon {
      width: 70px;
      height: auto;
    }
  }
}

// =====================================
// SP
// =====================================
@media screen and (max-width: 480px) {
  .community {
    @at-root {
      #{&}__head {
        margin-top: 0;
        font-size: 22px;
      }

      #{&}__text {
        font-size: 16px;
      }

      #{&}__icon {
        width: 50px;
        height: auto;
      }
    }
  }
}
