.reserve-confirm {
  overflow: hidden;
  background-color: #fff;
  header {
    padding-top: 16px;
    padding-bottom: 25px;
    background-color: #fff;
  }
  .reserve-confirm-logo {
    display: block;
    width: 47px;
    height: auto;
    margin: 0 auto;
  }
  .reserve-confirm-line {
    margin-top: 16px;
    margin-bottom: 20px;
    margin-left: 37px;
    margin-right: 37px;
    border-color: #e6e6e6;
  }
  .reserve-confirm-top-txt {
    text-align: center;
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: $font-medium;
    color: #5c5c5c;
  }
  .reserve-confirm-top-block {
    padding-top: 15px;
    padding-bottom: 12px;
    border-radius: 16px;
    background-color: #f4f4f4;
  }
  .reserve-confirm-details-head {
    margin-left: 11px;
    padding-left: 3px;
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: $font-medium;
    color: #9d9d9d;
  }
  .reserve-confirm-bottom-block {
    background-color: #fff;
  }
  .reserve-form-contents {
    margin-right: 16px;
    margin-left: 16px;
  }
  .reserve-form-contents-line {
    margin-top: 17px;
    margin-bottom: 0;
    margin-left: 13px;
    margin-right: 13px;
    border: none;
    border-top: 1px dotted #5c5c5c;
  }
  .reserve-confirm-cancelpolicy {
    margin-top: 33px;
    margin-left: 19px;
    margin-right: 19px;
  }
  .reserve-confirm-cancelpolicy-head {
    margin-bottom: 10px;
    padding-left: 30px;
    background-image: url(/assets/img/bell_icon_02.svg);
    background-size: 23px;
    background-repeat: no-repeat;
    line-height: 2.7rem;
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: $font-normal;
    color: #ff7474;
  }
  .reserve-confirm-cancelpolicy-txt {
    font-size: 12px;
    font-size: 1.2rem;
    font-weight: $font-medium;
    line-height: 2rem;
  }
}
