// =====================================
// PC
// =====================================
@media screen and (min-width: 769px) {
  .store-card-container {
    display: flex;
    flex-wrap: wrap;
    .store-card-box {
      width: 320px;
      position: relative;
      margin: 40px 10px 10px;
      padding-bottom: 10px;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      overflow: hidden;
      cursor: pointer;
      background-color: white;
      &::after {
        position: absolute;
        top: 5px;
        right: 5px;
        padding-top: 3px;
        padding-bottom: 3px;
        padding-right: 3px;
        padding-left: 3px;
        border-radius: 4px;
        color: #ffffff;
      }
      .store-card-img {
        width: 320px;
        height: 220px;
        object-fit: cover;
      }
      .card-video-icon {
        width: 30px;
        height: 30px;
        position: absolute;
        right: 0;
        top: 189px;
      }
    }
    .store-card-title-box {
      position: relative;
      margin-top: 10px;
      margin-left: 10px;
      margin-right: 10px;
      padding-left: 58px;
      height: 38px;
    }
    .store-card-person {
      position: absolute;
      top: 0;
      left: 0;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      object-fit: cover;
    }
    .store-card-title-en {
      font-size: 14px;
      font-weight: $font-medium;
    }
    .store-card-title-ja {
      margin-top: 5px;
      font-size: 13px;
      font-weight: $font-normal;
      color: #9d9d9d;
    }
    .store-card-line {
      margin-top: 0;
      margin-bottom: 0;
      border-style: dotted;
    }
    .store-card-place {
      margin-top: 10px;
      padding-left: 20px;
      background-image: url(/assets/img/pin_icon_01.svg);
      background-size: 13px auto;
      background-repeat: no-repeat;
      background-position: left center;
      font-size: 12px;
      font-weight: $font-normal;
      line-height: 20px;
    }
    .store-card-description {
      margin-top: 5px;
      font-size: 12px;
      font-weight: $font-normal;
      color: #5c5c5c;
      line-height: 18px;
    }
  }
}

// =====================================
// SP
// =====================================
@media screen and (max-width: 768px) {
  .store-card-container {
    margin-top: 20px;
    margin-left: 14px;
    margin-right: 14px;
    box-sizing: border-box;
    .store-card-box {
      position: relative;
      padding-bottom: 10px;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      background-color: #ffffff;
      &::after {
        position: absolute;
        top: 5px;
        right: 5px;
        padding: 3px;
        border-radius: 4px;
        color: #ffffff;
      }
      & + li {
        margin-top: 37px;
      }
      .store-card-img {
        height: 163px;
        border-radius: 4px 4px 0 0;
        object-fit: cover;
      }
      .card-video-icon {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 131px;
        right: 0;
      }
    }
    .store-card-menu {
      &::after {
        content: 'MENU';
        position: absolute;
        top: 5px;
        right: 5px;
        padding: 3px;
        background-color: #ff5800;
        border-radius: 4px;
        color: #ffffff;
      }
    }
    .store-card-title-box {
      position: relative;
      margin-top: 10px;
      margin-left: 10px;
      margin-right: 10px;
      padding-left: 58px;
      height: 38px;
    }
    .store-card-person {
      position: absolute;
      top: 0;
      left: 0;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      object-fit: cover;
    }
    .store-card-title-en {
      font-size: 14px;
      font-size: 1.4rem;
      font-weight: $font-medium;
    }
    .store-card-title-ja {
      margin-top: 5px;
      font-size: 13px;
      font-size: 1.3rem;
      font-weight: $font-normal;
      color: #9d9d9d;
    }
    .store-card-line {
      margin-top: 0;
      margin-bottom: px;
      border-style: dotted;
    }
    .store-card-place {
      padding-left: 20px;
      background-image: url(/assets/img/pin_icon_01.svg);
      background-size: 13px auto;
      background-repeat: no-repeat;
      background-position: left center;
      font-size: 12px;
      font-size: 1.2rem;
      font-weight: $font-normal;
      line-height: 2rem;
    }
    .store-card-description {
      margin-top: 5px;
      font-size: 12px;
      font-size: 1.2rem;
      font-weight: $font-normal;
      color: #5c5c5c;
      line-height: 1.8rem;
    }
  }
}
