.modal {
  .modal-title {
    margin: 20px;
    color: #ff5800;
    font-size: 1.6rem;
    text-align: center;
  }
  .modal-content {
    margin: 50px;
    padding: 50px;
    background: #f4f4f4;
    line-height: 25px;
  }
}

@media screen and (max-width: 768px) {
  .modal {
    .modal-content {
      margin: 0;
      padding: 5px;
      font-size: 11px;
    }
  }
}
