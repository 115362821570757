.notfound {
  header {
    width: 1025px;
    margin: 0 auto;
  }
  main {
    margin-bottom: 90px;
  }
  .main-inner {
    max-width: 1025px;
    width: 100%;
    margin: 0 auto;
  }
  .menu-icon {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .notfound-txt {
    font-size: 14px;
    font-size: 1.4rem;
    color: #ff5600;
    text-align: center;
    line-height: 3.5rem;
  }
  .notfound-return-btn {
    display: block;
    width: 280px;
    margin: 85px auto 0;
    line-height: 5rem;
    border: 1px solid #ff5600;
    border-radius: 10px;
    text-align: center;
    text-decoration: none;
    color: #ff5600;
    font-size: 15px;
    font-size: 1.5rem;
  }
  .notfound-img {
    max-width: 410px;
    width: 80%;
    margin: 105px auto 30px;
    text-align: center;
    img {
      width: 100%;
    }
  }
}
