// =====================================
// PC
// =====================================

.sns {
  @at-root {
    #{&}__wrapper {
      position: absolute;
      left: 713px;
      top: 0px;
      display: flex;
      align-items: center;
    }

    #{&}__twitter-logo {
      display: block;
      width: 110px;
      height: auto;
    }

    #{&}__facebook-logo {
      display: block;
      width: 115px;
      height: auto;
    }

    #{&}__twitter-button {
      margin-right: 30px;
    }

    #{&}__facebook-button {
    }
  }
}

// =====================================
// SP
// =====================================
@media screen and (max-width: 480px) {
  .sns {
    @at-root {
      #{&}__wrapper {
        position: absolute;
        left: 165px;
        top: 27px;
        display: flex;
        align-items: center;
      }

      #{&}__twitter-logo {
        display: block;
        width: 80px;
        height: auto;
      }

      #{&}__facebook-logo {
        display: block;
        width: 85px;
        height: auto;
      }

      #{&}__twitter-button {
        margin-right: 20px;
      }

      #{&}__facebook-button {
      }
    }
  }
}

// =====================================
// Tablet
// =====================================
@media screen and (max-width: 1024px) and (min-width: 481px) {
  .sns {
    @at-root {
      #{&}__wrapper {
        position: absolute;
        left: 487px;
        top: 20px;
        display: flex;
        align-items: center;
      }
    }
  }
}
